<template>
  <div
    :class="{
      portrait: isPortrait,
      landscape: !isPortrait,
      mobile: isPortrait && isSmallScreen,
      'add-overlay': isPortrait && isSmallScreen,
      'not-mobile': isPortrait && !isSmallScreen,
    }"
  >
    <HeaderBar
      @save="saveConfiguration"
      @share="shareConfiguration"
    ></HeaderBar>
    <main>
      <h1>Test</h1>

      <preview3D v-if="threeDPreviewActive" ref="threeDPreviewObj"></preview3D>

      <Sidebar @order="onOrder"></Sidebar>

      <Dialog
        :showModal="showSaveModal"
        :modalClass="modalClass"
        :configCode="configCode"
        @closeModal="closeModal"
        @onSavedConfig="onSavedConfig"
      ></Dialog>

      <Alert ref="compAlert"></Alert>

      <div
        v-if="showRotateDeviceModal"
        class="rotateDeviceModal text-center p-3"
      >
        De configurator biedt geen optimale ondersteuning in de huidige
        oriëntatie.
        <br />Gelieve jouw scherm 90graden te draaien.

        <div class="icon-rotation my-2">
          <img
            width="40"
            height="40"
            src="/build/img/svg/icon-rotation.svg"
            alt=""
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { watch, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useConfigurationStore } from "./stores/configuration";
import { useSettingsStore } from "./stores/settings";
import { usePricingStore } from "./stores/pricing";
import AjaxRequests from "./helpers/AjaxRequests";
import HeaderBar from "./components/HeaderBar.vue";
import Preview3D from "./components/Preview3D.vue";
import Preview2D from "./components/Preview2D.vue";
import Sidebar from "./components/Sidebar.vue";
import Dialog from "./components/Dialog.vue";
import Alert from "./components/Alert.vue";

const settings = useSettingsStore();
const pricing = usePricingStore();
const threeDPreviewActive = ref(true);
const threeDPreviewObj = ref(null);
const showRotateDeviceModal = ref(false);

const compAlert = ref();

const togglePreview = function () {
  this.threeDPreviewActive = !this.threeDPreviewActive;
};

const configurationStore = useConfigurationStore();

const { changesWereMade, paths } = storeToRefs(settings);
const requests = new AjaxRequests(paths.value);
const showSaveModal = ref(false);
const modalClass = ref("");
const configCode = ref("");

const saveConfiguration = () => {
  threeDPreviewObj.value.takeScreenShot((data) => {
    let stateCopy = { ...configurationStore.$state };
    stateCopy.data = data;

    requests.save(stateCopy, (data) => {
      configCode.value = data.code;
      showSaveModal.value = true;
      setTimeout(() => {
        modalClass.value = "show";
      }, 10);
    });
  });
};

const shareConfiguration = () => {
  threeDPreviewObj.value.takeScreenShot((data) => {
    let stateCopy = { ...configurationStore.$state };
    stateCopy.data = data;

    requests.save(stateCopy, (data) => {
      let newPath = paths.value.config.replace("[configCode]", data.code);
      let url = window.location.origin + newPath;

      navigator.clipboard.writeText(url);
      compAlert.value.showAlert("alert.share");
    });
  });
};

const onSavedConfig = () => {
  history.pushState(
    false,
    false,
    paths.value.config.replace("[configCode]", configCode.value)
  );
  settings.changesWereMade = false;
};

const closeModal = () => {
  modalClass.value = "";
  setTimeout(() => {
    showSaveModal.value = false;
  }, 160);
};

const onOrder = () => {
  threeDPreviewObj.value.takeScreenShot((data) => {
    let stateCopy = { ...configurationStore.$state };
    stateCopy.data = data;

    requests.gotoCheckout(stateCopy, (data) => {
      if ("url" in data) {
        settings.changesWereMade = false;
        removeEventListener("beforeunload", beforeUnloadListener, {
          capture: true,
        });

        window.location.href = data.url;
      }
    });
  });
};

//Trigger once manually, before the subscribes!
configurationStore.onMeasurementsAdjusted();
requests.getPrice(configurationStore.$state, (data) => {
  pricing.totalPrice = data.totalPriceVat;
  pricing.userLoggedIn = data.userLoggedIn;

  pricing.discount = data.discount;
  pricing.discountPercentage = data.discountPercentage;

  pricing.breakDown = data;
});

//Subscribes on the main state!
configurationStore.$subscribe(
  debounce((mutation, state) => {
    requests.getPrice(state, (data) => {
      pricing.totalPrice = data.totalPriceVat;
      pricing.userLoggedIn = data.userLoggedIn;
      pricing.breakDown = data;

      pricing.discount = data.discount;
      pricing.discountPercentage = data.discountPercentage;
    });
  }, 500)
);

let firstLoad = true;
let beforeunloadListenerBound = false;
configurationStore.$subscribe((mutation, state) => {
  if (!firstLoad) {
    settings.changesWereMade = true;
  }
});

const beforeUnloadListener = (e) => {
  e.preventDefault();

  //Call the save popup!
  saveConfiguration();

  return (e.returnValue =
    "Are you sure you want to exit? The changes made will be lost.");
};
settings.$subscribe((mutation, state) => {
  // if (mutation && mutation?.events && mutation?.events?.key) {
  //   if (mutation.events.key === "changesWereMade") {
  if (settings.changesWereMade && !beforeunloadListenerBound) {
    addEventListener("beforeunload", beforeUnloadListener, {
      capture: true,
    });
    beforeunloadListenerBound = true;
  } else {
    removeEventListener("beforeunload", beforeUnloadListener, {
      capture: true,
    });
    beforeunloadListenerBound = false;
  }
  // }
  // }
});

const isMobile = ref(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
const body = document.body;
const isPortrait = ref(false);
const isSmallScreen = ref(false);

const checkOrientation = () => {
  isPortrait.value = window.innerHeight > window.innerWidth;
  isSmallScreen.value = window.innerWidth < 500;

  showRotateDeviceModal.value = isPortrait.value;
};

if (isMobile.value) {
  checkOrientation();
  window.addEventListener("resize", checkOrientation);
}

onMounted(() => {
  firstLoad = false;
});
</script>

<style lang="scss">
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "styles/elements/elements.main";

// Import components
@import "styles/components/components.card";
@import "styles/components/components.button";
@import "styles/components/components.icons";
@import "styles/components/components.form";
@import "styles/components/components.corpus";
@import "styles/components/components.itembox";
@import "styles/components/components.layout";
@import "styles/components/components.preview";
@import "styles/components/components.preview3d";
@import "styles/components/components.modal";
@import "styles/components/components.rotatemodal";
@import "styles/components/components.overlay";
</style>
