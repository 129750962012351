import { BufferGeometry, Float32BufferAttribute, Vector3 } from 'three';

type axis = 'x' | 'y' | 'z';

class TrapezoidGeometry extends BufferGeometry {
	parameters: {
		baseWidth: number,
		topWidth: number,
		topOffsetToBaseInCenter: number, 
		height: number,
		depth: number,
		widthSegments: number,
		heightSegments: number
		depthSegments: number
	}

	// buffers
	indices:Array<number> = [];
	vertices:Array<number> = [];
	normals:Array<number> = [];
	uvs:Array<number> = [];

	//Helper variables
	numberOfVertices:number = 0;
	groupStart:number = 0;

	constructor( baseWidth:number = 1, topWidth:number = .75, topOffsetToBaseInCenter:number = 0, height:number = 1, depth:number = 1, widthSegments:number = 1, heightSegments:number = 1, depthSegments:number = 1 ) {
		super();

		this.type = 'TrapezoidGeomtry';

		this.parameters = {
			baseWidth: baseWidth,
			topWidth: topWidth,
			topOffsetToBaseInCenter: topOffsetToBaseInCenter,
			height: height,
			depth: depth,
			widthSegments: widthSegments,
			heightSegments: heightSegments,
			depthSegments: depthSegments
		};

		widthSegments = Math.floor( widthSegments );
		heightSegments = Math.floor( heightSegments );
		depthSegments = Math.floor( depthSegments );

		let setOffset = topOffsetToBaseInCenter;
		let calculatedOffset = (Math.round( ( (baseWidth - topWidth) / 2 ) * 1000000) / 1000000); //Go to max 6digits , otherwise we got some weird results that ended in 3.14929392e-17 which causes three.js to go nuts

		// build each side of the box geometry     ______
		// All trapezoids wil be build like this: /______\ 
		//Third axis in buildplane is a constant, so if z,y,x is passed, the plane will be drawn from z1,y1,x z2,y2,x z3,y3,x z4,y4,x > so the x-coord stays constant
		this.buildPlane( 'z', 'y', 'x', - 1, - 1, depth, height, baseWidth, 'x', calculatedOffset, topOffsetToBaseInCenter, depthSegments, heightSegments, 0 ); // px // Cube right side
		this.buildPlane( 'z', 'y', 'x', 1, - 1, depth, height, - baseWidth, 'x', -calculatedOffset, topOffsetToBaseInCenter, depthSegments, heightSegments, 1 ); // nx // Cube left side
		this.buildPlane( 'x', 'z', 'y', 1, 1, topWidth, depth, height, 'x', 0, setOffset, widthSegments, depthSegments, 2 ); // py // Cube top
		this.buildPlane( 'x', 'z', 'y', 1, - 1, baseWidth, depth, - height, 'x', 0, 0, widthSegments, depthSegments, 3 ); // ny // Cube bottom
		this.buildPlane( 'x', 'y', 'z', 1, - 1, baseWidth, height, depth, 'x', calculatedOffset, setOffset, widthSegments, heightSegments, 4 ); // pz // Cube front
		this.buildPlane( 'x', 'y', 'z', - 1, - 1, baseWidth, height, - depth, 'x', calculatedOffset, -setOffset, widthSegments, heightSegments, 5 ); // nz // Cube back

		// build geometry
		this.setIndex( this.indices );
		this.setAttribute( 'position', new Float32BufferAttribute( this.vertices, 3 ) );
		this.setAttribute( 'normal', new Float32BufferAttribute( this.normals, 3 ) );
		this.setAttribute( 'uv', new Float32BufferAttribute( this.uvs, 2 ) );
	}

	buildPlane( u:axis, v:axis, w:axis, udir:number, vdir:number, width:number, height:number, depth:number, offsetAxis: axis, calculatedOffset:number, userOffset:number, gridX:number, gridY:number, materialIndex:number ) {
		//Calculate dimensions of segments
		let segmentWidth = width / gridX;
		let segmentHeight = height / gridY;
		let baseSegmentWidth = segmentWidth;
		let rowOffset = 0;
		let segmentOffset = 0;
		let rowUserOffset = 0;
		let segmentUserOffset = 0;
		
		if( offsetAxis === u && calculatedOffset !== 0 ) { //If this is the front/back face we need to calculate the offset per row
			baseSegmentWidth = ((width - (calculatedOffset * 2)) / gridY); //we calculate the base segement width that we expand with each row to uniformaly distribute each width across the segments
			rowOffset = ((calculatedOffset * 2)/(gridY)); //Calculate offset per row to translate segments
			segmentOffset = rowOffset/gridX; //Calculate offset that is needed to increase the segment width with each row
			rowUserOffset = ((userOffset * 2)/(gridY)); //Calculate offset per row to translate segments based on user offset
			segmentUserOffset = rowUserOffset/gridX; //Calculate offset that is needed
		}

		//Center of the box should be 0, 0, 0 / so half the dimensions to get the correct starting point
		const widthHalf = (width / 2);
		const heightHalf = (height / 2);
		const depthHalf = (depth / 2);

		const gridX1 = gridX + 1;
		const gridY1 = gridY + 1;

		let vertexCounter = 0;
		let groupCount = 0;

		const vector = new Vector3();

		//Fine out the slope of this plain
		//Check which offset was set to determin in which plane to slope etc....		
		let slope = 0;
		if( calculatedOffset !== 0 ) {
			//Using a first degree function to calculate slope so we can adjust the depth param for the side-faces accurately
			slope = (-(calculatedOffset - userOffset) - 0) / (0-height);
		}

		// generate vertices, normals and uvs // From top to bottom if you are looking straight at it!
		for ( let iy = 0; iy < gridY1; iy ++ ) {
			let y = iy * segmentHeight - heightHalf;

			//Increase segment width with each row
			segmentWidth = baseSegmentWidth + (segmentOffset * iy);

			for ( let ix = 0; ix < gridX1; ix ++ ) {
				const x = ix * segmentWidth - widthHalf;

				// set values to correct vector component
				vector[ u ] = (x * udir);
				vector[ v ] = (y * vdir);
				vector[ w ] = depthHalf;

				if( offsetAxis == w ) { //Using the slope to calculate the depth for the side faces
					let wOffset = (y * slope);
					vector[ w ] = depthHalf + wOffset - ((calculatedOffset - userOffset)/2); 
				}

				if( offsetAxis == u ) {
					if( calculatedOffset !== 0 ) {
						//Calculate offset we need to have 0 offset at the top > max offset so rowoffset * rows at the bottom
						vector[u] += (rowOffset/2) * (gridY-iy) * udir; //Normal offset
						//Adjust with useroffset
						vector[u] += (rowUserOffset/2) * (gridY-iy) * udir; //Add the useroffset
					} else {
						vector[u] += userOffset;
					}
					
				}

				// now apply vector to vertex buffer
				this.vertices.push( vector.x, vector.y, vector.z );

				// set values to correct vector component
				vector[ u ] = 0;
				vector[ v ] = 0;
				vector[ w ] = depth > 0 ? 1 : - 1;

				// now apply vector to normal buffer
				this.normals.push( vector.x, vector.y, vector.z );

				// uvs
				this.uvs.push( ix / gridX );
				this.uvs.push( 1 - ( iy / gridY ) );

				// counters
				vertexCounter += 1;
			}
		}

		// indices

		// 1. you need three indices to draw a single face
		// 2. a single segment consists of two faces
		// 3. so we need to generate six (2*3) indices per segment
		for ( let iy = 0; iy < gridY; iy ++ ) {
			for ( let ix = 0; ix < gridX; ix ++ ) {
				const a = this.numberOfVertices + ix + gridX1 * iy;
				const b = this.numberOfVertices + ix + gridX1 * ( iy + 1 );
				const c = this.numberOfVertices + ( ix + 1 ) + gridX1 * ( iy + 1 );
				const d = this.numberOfVertices + ( ix + 1 ) + gridX1 * iy;

				// faces
				this.indices.push( a, b, d );
				this.indices.push( b, c, d );

				// increase counter
				groupCount += 6;
			}
		}

		// add a group to the geometry. this will ensure multi material support
		this.addGroup( this.groupStart, groupCount, materialIndex );

		// calculate new start value for groups
		this.groupStart += groupCount;

		// update total number of vertices
		this.numberOfVertices += vertexCounter;
	}

	static fromJSON( data:any ) {
		return new TrapezoidGeometry( data.baseWidth, data.topWidth, data.topOffsetToBase, data.height, data.depth, data.widthSegments, data.heightSegments, data.depthSegments );
	}
}

export { TrapezoidGeometry };