<template>
  <header class="c-header d-flex align-items-center justify-content-between">
    <a href="https://www.cabinetto.be/" class="btn btn-link btn-link-icon">{{
      $t("general.backToWebsite")
    }}</a>
    <div v-if="!settings.readOnly">
      <button class="btn btn-icon" @click.prevent="onShare">
        <svg class="icon icon-sm me-2">
          <use xlink:href="#icon-share"></use>
        </svg>
        {{ $t("general.share") }}
      </button>
      <button class="c-save btn btn-primary" @click.prevent="onSave">
        <svg class="icon icon-sm me-2">
          <use xlink:href="#icon-save"></use>
        </svg>
        {{ $t("general.save") }}
      </button>
    </div>
  </header>
</template>

<script setup>
import { defineEmits } from "vue";
import { useSettingsStore } from "../stores/settings";
const emit = defineEmits(["save", "share"]);
const settings = useSettingsStore();

const onSave = () => {
  emit("save");
};

const onShare = () => {
  emit("share");
};
</script>

<style scoped lang="scss">
@import "../styles/components/_components.header.scss";
@import "../styles/components/_components.icons.scss";
@import "../styles/components/_components.save.scss";
</style>
