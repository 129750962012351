import { createApp } from "vue";
import { createPinia } from "pinia";
import { uuid } from "vue-uuid";
import i18n from "./i18n";
import App from "./Configurator.vue";
import { debounce } from "lodash";
import { useSettingsStore } from "./stores/settings";
import { useConfigurationStore } from "./stores/configuration";

window.blockAddingNewParts = false;

const pinia = createPinia();
const app = createApp(App);

const settingsStore = useSettingsStore(pinia);
const configurationStore = useConfigurationStore(pinia);

//Adding a debounce directive so we can debounce input fields to prevent too many event calls
app.directive("debounce", (el, binding) => {
  el.oninput = debounce(function (evt) {
    //Enforce validation
    el.dispatchEvent(new Event("change"));
  }, parseInt(binding.value) || 500);
});

//Pull the materials into the settingstore
settingsStore.$state.finishes = finishes;
settingsStore.$state.accessories = accessories;
settingsStore.$state.paths = paths;
settingsStore.$state.readOnly = ro;

//Check if this is a configuration url
if ("type" in configuration) {
  console.log("--- Loading existing config from code in url!");
  configurationStore.$state = configuration;

  //Give every corpus an id
  configurationStore.$state.corpora.forEach((corpus) => {
    corpus.corpusId = uuid.v4();
  });

  settingsStore.$state.flags.measurementsAdjusted = true;
} /* else if (typeof localStorage.cabinetto_configuration != "undefined") {
  ////If no config found/ check if something is store in the Local storage?
  console.log("--- Trying to load existing config!");
  let configData = null;

  try {
    configData = JSON.parse(localStorage.cabinetto_configuration);

    if (
      confirm(
        "We noticed you have an existing configuration stored in your local starge, do you like to load this earlier configuration?"
      )
    ) {
      configurationStore.$state = configData;
    }
  } catch (e) {
    console.warn(
      "Something went wrong with parsing the json data from local storage, was the data corrupted?!",
      localStorage.cabinetto_configuration
    );
  }
} */

configurationStore.$subscribe(
  debounce((mutation, state) => {
    // console.log("--- Autosaving config to local storage");
    localStorage.cabinetto_configuration = JSON.stringify(state);
  }),
  500
);

app.use(pinia).use(i18n).mount("#configurator");
