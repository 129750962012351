import { defineStore } from "pinia";
import { useConfigurationStore } from "./configuration";

export const useSettingsStore = defineStore("settings", {
  //state
  state: () => ({
    development: true,
    readOnly: false,
    unitScale: 1 / 10, //Expres scale rel to mm! backend calculates in mm but we will show values in this scale
    unit: "cm",
    defaults: {
      dimensions: {
        standing: {
          width: 600,
          height: 1800,
          depth: 300,
        },
        hanging: {
          width: 600,
          height: 400,
          depth: 300,
        },
      },
      toeKick: {
        indent: 0,
        height: 40,
        extFinish: 11,
      },
      corpus: {
        accessories: [],
        corpusId: null,
        moduleType: "MODULE_OPEN",
        typeId: 2,
        width: 0,
        hasDeviatingHeight: false,
        height: 0,
        clothesRail: false,
        backPanel: true,
        customWidthSet: false,
        baseMaterial: 1,
        extFinish: 11,
        intFinish: 11,
        tablets: {
          top: true,
          right: false,
          bottom: false,
          left: false,
        },
        tabletsMaterial: 1,
        tabletsFinish: 11,
        toeKickFinish: 11,
        modules: {},
      },
      clothesRailAccessoryId: 1,
    },
    changesWereMade: false,
    finishes: [],
    accessories: [],
    paths: {},
    collapsibles: {},
    flags: {
      measurementsAdjusted: false,
    },
    constraints: {
      dimensions: {
        standing: {
          minWidth: 250,
          maxWidth: 10000,
          minHeight: 300,
          maxHeight: 2700,
          minDepth: 200,
          maxDepth: 750,
        },
        hanging: {
          minWidth: 250,
          maxWidth: 3000,
          minHeight: 300,
          maxHeight: 2000,
          minDepth: 200,
          maxDepth: 500,
        },
        corpus: {
          minWidth: 250,
          maxWidth: 1200,
          tabletTopBelow: 1760, //1800 - tablet height!
        },
        modules: {
          drawers: {
            minHeight: 100,
            maxHeight: 400,
          },
          shelves: {
            minHeight: 320,
            maxHeight: -1,
            firstMinHeight: 150, //Shelves should be available from 150mm heigt, but when using more then 1 shelve, but the distance between 2 shelves should be at least 320mm!
          },
          doors: {
            minWidth: 250,
            maxWidth: 600,
          },
          clothesRail: {
            minHeight: 1150,
            minDepth: 568,
          },
        },
      },
    },
    threed: {
      scale: 1000,
      baseMaterialThickness: 18,
      tabletThickness: 38,
      fog: {
        color: 0xffffff,
        near: 0.008,
      },
      lighting: [],
    },
  }),
  getters: {
    getMinWidth: (state) => {
      const configurationStore = useConfigurationStore();

      if (
        configurationStore.type &&
        state.constraints.dimensions[configurationStore.type]
      ) {
        return state.constraints.dimensions[configurationStore.type].minWidth;
      } else {
        return 0;
      }
    },
    getMaxWidth: (state) => {
      const configurationStore = useConfigurationStore();

      if (
        configurationStore.type &&
        state.constraints.dimensions[configurationStore.type]
      ) {
        let maxWidth =
          state.constraints.dimensions[configurationStore.type].maxWidth;
        if (configurationStore.type === "hanging") {
          if (configurationStore.measurements.height > 1200) {
            maxWidth = 1000; //For hanging, a closest heigher then 1,2m can only be 1m wide!
          }
        }

        return maxWidth;
      } else {
        return 0;
      }
    },
    getMinHeight: (state) => {
      const configurationStore = useConfigurationStore();

      if (
        configurationStore.type &&
        state.constraints.dimensions[configurationStore.type]
      ) {
        return state.constraints.dimensions[configurationStore.type].minHeight;
      } else {
        return 0;
      }
    },
    getMaxHeight: (state) => {
      const configurationStore = useConfigurationStore();

      if (
        configurationStore.type &&
        state.constraints.dimensions[configurationStore.type]
      ) {
        return state.constraints.dimensions[configurationStore.type].maxHeight;
      } else {
        return 0;
      }
    },
    getMinDepth: (state) => {
      const configurationStore = useConfigurationStore();

      if (
        configurationStore.type &&
        state.constraints.dimensions[configurationStore.type]
      ) {
        return state.constraints.dimensions[configurationStore.type].minDepth;
      } else {
        return 0;
      }
    },
    getMaxDepth: (state) => {
      const configurationStore = useConfigurationStore();

      if (
        configurationStore.type &&
        state.constraints.dimensions[configurationStore.type]
      ) {
        return state.constraints.dimensions[configurationStore.type].maxDepth;
      } else {
        return 0;
      }
    },
    getMinNrOfCorpora: (state) => {
      const configurationStore = useConfigurationStore();

      return Math.ceil(
        configurationStore.measurements.width /
          state.constraints.dimensions.corpus.maxWidth
      );
    },
    getMaxNrOfCorpora: (state) => {
      const configurationStore = useConfigurationStore();

      let max = Math.floor(
        configurationStore.measurements.width /
          state.constraints.dimensions.corpus.minWidth
      );

      if (max < 1) {
        max = 1;
      }

      return max;
    },
    getMinNrOfDrawers: (state) => {
      return (corpusId) => {
        const configurationStore = useConfigurationStore();

        const corpus = configurationStore.corpora.find(
          (item) => item.corpusId === corpusId
        );

        return Math.ceil(
          corpus.height / state.constraints.dimensions.modules.drawers.maxHeight
        );
      };
    },
    getMaxNrOfDrawers: (state) => {
      return (corpusId) => {
        const configurationStore = useConfigurationStore();

        const corpus = configurationStore.corpora.find(
          (item) => item.corpusId === corpusId
        );

        return Math.floor(
          corpus.height / state.constraints.dimensions.modules.drawers.minHeight
        );
      };
    },
    getMinNrOfShelves: (state) => {
      return (corpusId) => {
        const configurationStore = useConfigurationStore();

        const corpus = configurationStore.corpora.find(
          (item) => item.corpusId === corpusId
        );

        return Math.ceil(
          corpus.height / state.constraints.dimensions.modules.shelves.maxHeight
        );
      };
    },
    getMinNrOfDoors: (state) => {
      return (corpusId) => {
        const configurationStore = useConfigurationStore();

        const corpus = configurationStore.corpora.find(
          (item) => item.corpusId === corpusId
        );

        return Math.ceil(
          corpus.width / state.constraints.dimensions.modules.doors.maxWidth
        );
      };
    },
    getMaxNrOfDoors: (state) => {
      return (corpusId) => {
        const configurationStore = useConfigurationStore();
        const corpus = configurationStore.corpora.find(
          (item) => item.corpusId === corpusId
        );

        let maxNrOfDoors = Math.floor(
          corpus.width / state.constraints.dimensions.modules.doors.minWidth
        );

        return maxNrOfDoors > 2 ? 2 : maxNrOfDoors;
      };
    },
    getFinishes: (state) => {
      const returnObj = {};

      //Link to mapping
      const getMaterialsMap = (id) => {
        let mapType = "color";
        switch (id) {
          case 1:
            mapType = "finelineMetalicBrown";
            break;

          case 2:
            mapType = "licolnWalnut";
            break;

          case 3:
            mapType = "vicenzaOak";
            break;

          case 4:
            mapType = "birch";
            break;
        }

        return mapType;
      };

      state.finishes.forEach((finish) => {
        if (Object.keys(returnObj).indexOf(finish.type) < 0) {
          returnObj[finish.type] = [];
        }

        returnObj[finish.type].push({
          id: finish.id,
          label: finish.name,
          thumbnail: finish.finishImages[0] ? finish.finishImages[0] : "",
          color: finish.color,
          materialName: getMaterialsMap(finish.id),
        });
      });

      return returnObj;
    },
    getHandles: (state) => {
      const returnObj = state.accessories.filter(
        (accessory) => accessory.type === "handle"
      );

      return returnObj;
    },
  },
});
