import { Group } from 'three';
import { Shelf, Drawer, Door } from './parts';
import { ModuleInterface, ModuleType, ModuleSettings } from './types';

export default class Module implements ModuleInterface {
  type:ModuleType;
  group: Group;
  objs:[];
  part:any;

  constructor( type:ModuleType, settings:ModuleSettings ) {
    this.type = type;
    this.group = new Group();
    
    switch( type ) {
      case 'shelves':
        this.part = new Shelf( settings );
        this.group.add( this.part.group );
        break;
      case 'drawers':
        this.part = new Drawer( settings );
        this.group.add( this.part.group );
        break;
      case 'doors':
        this.part = new Door(settings);
        this.group.add( this.part.group );

        break;
    }

    return this;
  }

  update(settings:ModuleSettings){
    this.part.update(settings);
  }

}