import { Color, Group, BoxGeometry, ExtrudeGeometry, InstancedMesh, Mesh, Matrix4, Shape, MeshStandardMaterial, MeshPhysicalMaterial } from "three";
import { DoorInterface, DrawerInterface, PartInterface, ShelfInterface, ModuleSettings, partObjs } from "./types";

export class Part implements PartInterface {
  group: Group;
  amount: number;
  width: number;
  height: number;
  depth: number;
  baseScale:number = 1000;
  objs: Array<partObjs>;
  baseTexture: MeshStandardMaterial;
  baseMaterialThickness: number;

  constructor( settings: ModuleSettings ) {
    this.width = settings.width;
    this.height = settings.height;
    this.depth = settings.depth;

    if( settings.objs ) {
    this.amount = settings.objs.length;
    }

    this.baseMaterialThickness = settings.baseMaterialThickness;
    this.group = new Group();
    this.objs = settings.objs;

    this.baseTexture = settings.baseTexture;
  }

  draw():void{
    //Should be filled in by extended classes
  };

  update(settings:ModuleSettings) {
    this.width = settings.width;
    this.height = settings.height;
    this.depth = settings.depth;
    this.amount = (typeof settings.objs === 'number') ? settings.objs : settings.objs.length;
    this.draw();
  }
}

export class Drawer extends Part implements DrawerInterface  {
  type:'drawer' = 'drawer';

  constructor(settings:ModuleSettings) {
    super(settings);

    this.draw();
    return this;
  }

  draw():void {
    //Clear out the main group
    for (let i = this.group.children.length - 1; i >= 0; i--) {
      this.group.remove( this.group.children[i] );
    }

    let posFromBottom = 0.001;

    // reverse trough loop, the last one, is the bottom one!
    for( let index = this.objs.length - 1; index >= 0; index-- ) {
        let drawerGroup = new Group();
        let drawerProps:partObjs = this.objs[index];
        let drawerHeight = (drawerProps.height) ? (drawerProps.height - .2) / this.baseScale: 0; //-5mm for margins between drawers!
        let drawerDepth = this.depth - this.baseMaterialThickness - .01;
        let drawerWidth = this.width - (this.baseMaterialThickness * 2) - .01;
        
        /** 
         * Dimensions: 
         * front: h = height of drawer, w = width of corpus
         * sides: h = height of drawer - 5cm: 1cm of the bottom offset, 4 from the top of the front offset, depth > depth corpus - material-thickness - 1cm (margin)
         * bottom: x = width: width drawer - 1cm (.5cm for slider on each side) - (material-thickness * 2), depth > depth-corpus - (material-thicnkness * 2 (back & front)) - 1cm margin
         * back: same height as sides + width as bottom
         * **/
        let drawerFrontDimensions = {
          width: this.width - .002, //-2mm for margins between corpuses
          height: drawerHeight,
          depth: this.baseMaterialThickness
        };

        let drawerSideDimensions = {
          width: this.baseMaterialThickness,
          height: drawerFrontDimensions.height - .05,//
          depth: drawerDepth
        };

        let drawerBackDimensions = {
          width: drawerWidth - (this.baseMaterialThickness * 2),
          height: drawerSideDimensions.height,
          depth: this.baseMaterialThickness
        };

        let drawerBottomDimensions = {
          width: drawerWidth - (this.baseMaterialThickness * 2),
          height: this.baseMaterialThickness,
          depth: drawerDepth - this.baseMaterialThickness
        };

        let frontShape = new Shape();
        frontShape.moveTo( .001, .001 );
        frontShape.lineTo( .001, drawerFrontDimensions.height - .002 );
        frontShape.lineTo( drawerFrontDimensions.width - .002 , drawerFrontDimensions.height - .002 );
        frontShape.lineTo( drawerFrontDimensions.width - .002, .001 );
        frontShape.lineTo( .001, .001 );
        let frontGeometry = new ExtrudeGeometry( frontShape, {steps: 1, depth: drawerFrontDimensions.depth, bevelEnabled: true, bevelThickness: .001, bevelSize: .001, bevelSegments: 1} );

        let sideGeometry = new BoxGeometry(drawerSideDimensions.width, drawerSideDimensions.height, drawerSideDimensions.depth);
        sideGeometry.translate(drawerSideDimensions.width/2, drawerSideDimensions.height/2, drawerSideDimensions.depth/2);

        let backGeometry = new BoxGeometry(drawerBackDimensions.width, drawerBackDimensions.height, drawerBackDimensions.depth);
        backGeometry.translate(drawerBackDimensions.width/2, drawerBackDimensions.height/2, drawerBackDimensions.depth/2);

        let bottomGeometry = new BoxGeometry(drawerBottomDimensions.width, drawerBottomDimensions.height, drawerBottomDimensions.depth);
        bottomGeometry.translate(drawerBottomDimensions.width/2, drawerBottomDimensions.height/2, drawerBottomDimensions.depth/2);

        let drawerFront = new InstancedMesh( frontGeometry, this.baseTexture, 1 );
        let drawerSide = new InstancedMesh( sideGeometry, this.baseTexture, 2 );
        let drawerBottom = new InstancedMesh( bottomGeometry, this.baseTexture, 1 );
        let drawerBack = new InstancedMesh( backGeometry, this.baseTexture, 1 );
        
        let translationFront = new Matrix4();
        let translationSides = new Matrix4();
        let translationBottom = new Matrix4();
        let translationBack = new Matrix4();

        translationFront.makeTranslation( 0.0025, 0, (this.depth - this.baseMaterialThickness) );//x=0.0025 is 2,5mm to center it in its corpus
        drawerFront.setMatrixAt(0, translationFront);
        drawerGroup.add( drawerFront );

        translationSides.makeTranslation( this.baseMaterialThickness + .005, .024, this.baseMaterialThickness + .01);
        drawerSide.setMatrixAt(0, translationSides);
        translationSides.makeTranslation( drawerWidth + .005, .024, this.baseMaterialThickness + .01);
        drawerSide.setMatrixAt(1, translationSides);
        drawerGroup.add( drawerSide );

        translationBottom.makeTranslation( (this.baseMaterialThickness*2) + .005, .024, this.baseMaterialThickness + .01 );
        drawerBottom.setMatrixAt(0, translationBottom);
        drawerGroup.add( drawerBottom );

        translationBack.makeTranslation( (this.baseMaterialThickness*2)+ .005, .024, this.baseMaterialThickness + .01 );
        drawerBack.setMatrixAt(0, translationBack);
        drawerGroup.add( drawerBack );

        drawerGroup.translateY(posFromBottom);
        posFromBottom += drawerHeight + .0002; //Add the 5mm spacing between drawers

        this.group.add(drawerGroup);
    }
  }
}

export class Shelf extends Part implements ShelfInterface {
  type:'shelf' = 'shelf';
  shelve: InstancedMesh;
  positions: Array<{top: number, clothesRail: boolean}> = [];

  constructor(settings:ModuleSettings) {
    super(settings);

    let availableHeight = this.height - (this.amount * this.baseMaterialThickness);
    
    if( settings.clothesRail ) {
      //We need to take another approach
      let space = (availableHeight - 1.15) / (this.amount - 1);

      this.positions.push({
        top: 0.15,
        clothesRail: true
      })

      if( settings.objs.length > 1 ) {
        for( let i=1; i < settings.objs.length; i++ ) {
          this.positions.push({
            top: Math.ceil(((1.15 + this.baseMaterialThickness) + ((i-1) * space)) * 100 )/100 + this.baseMaterialThickness,
            clothesRail: false
          });
        }
      }

    } else {
      let space = availableHeight / (this.amount + 1);

      for( let i=0; i < settings.objs.length; i++ ) {
        this.positions.push({
          top: (Math.ceil((i + 1) * space * 100) / 100) + (i*this.baseMaterialThickness),
          clothesRail: false
        });
      }
    }

    this.draw();
    return this;
  }

  draw():void {
    if( this.shelve ) {
      this.group.remove( this.shelve );
    }
    
    //Instead of making it the given dimensions immediatly, we want to use scaling for ease of use later on ;) scale 1.8 > 1.8m in "real life", other wise we need to recaculate the scale every time based on original dimesions
    let shelveGeometry = new BoxGeometry(1, this.baseMaterialThickness, 1); 
    //Adjust the origin to left/bottom/front of the box
    shelveGeometry.translate(.5, this.baseMaterialThickness/2, .5);

    //Create the meshes of it
    this.shelve = new InstancedMesh( shelveGeometry, this.baseTexture, this.amount );
    this.shelve.scale.x = this.width - (2 * this.baseMaterialThickness);
    this.shelve.scale.z = this.depth - this.baseMaterialThickness;
    this.shelve.translateX(this.baseMaterialThickness);
    
    //We need to position them properly
    let heightWithoutShelves = this.height - (this.amount * this.baseMaterialThickness);

    let heightPerShelf = heightWithoutShelves / (this.amount + 1);

    for( let i = 0; i < this.amount; i++ ) {
      let transformation = new Matrix4();
      transformation.makeTranslation(0, (this.height - (this.positions[i].top + this.baseMaterialThickness)), 0);
      this.shelve.setMatrixAt(i, transformation);
      // shelfOffset += heightPerShelf + this.baseMaterialThickness;

      if( this.positions[i].clothesRail ) {
        //Draw clothesRail
        this.addClothesRail( (this.height - (this.positions[i].top + (this.baseMaterialThickness))) );
      }
    }

    this.group.add( this.shelve );
  }

  addClothesRail( position:number ) {

    // basic monochromatic energy preservation
    const diffuseColor = new Color().setHSL( 0, 0, 0 );

    const material = new MeshPhysicalMaterial( {
      color: new Color(0xffffff),
      emissive: new Color(0x000000),
      metalness: 1,
      roughness: 0,
    });

    let bracketShape = new Shape();
    bracketShape.moveTo(0,0);
    bracketShape.lineTo(0.058, 0); //width is 58mm
    bracketShape.lineTo(0.058, -0.003); //height of plate is 3mm
    bracketShape.lineTo(0.040, -0.003); //width of part for screw is 18mm each side, middle section is 22mm wide
    bracketShape.lineTo(0.040, -0.089); //Radius starts at 10mm of the end!
    bracketShape.lineTo(0.034, -0.095); //Create a flatspot like a trapezoid!
    bracketShape.lineTo(0.024, -0.095); //Flat spot
    bracketShape.lineTo(0.018, -0.089); //going back up!
    bracketShape.lineTo(0.018, -0.003); //width of part for screw is 18mm each side, middle section is 22mm wide
    bracketShape.lineTo(0, -0.003);//Make the screwing surface again
    bracketShape.lineTo(0, 0);//Close the shape

    let barShape = new Shape();
    barShape.moveTo(0.022, -0.071);
    barShape.lineTo(0.024, -0.065);
    barShape.lineTo(0.034, -0.065);
    barShape.lineTo(0.036, -0.071);
    barShape.lineTo(0.036, -0.085);
    barShape.lineTo(0.034, -0.091);
    barShape.lineTo(0.024, -0.091);
    barShape.lineTo(0.022, -0.085);
    barShape.lineTo(0.022, -0.071);

    let bracketGeometry = new ExtrudeGeometry( bracketShape, {steps: 1, depth: 0.022, bevelEnabled: false} ); //Thickness is 22mm
    let bracketMeshLeft = new Mesh( bracketGeometry, material );
    let bracketMeshRight = new Mesh( bracketGeometry, material );

    let barGeometry = new ExtrudeGeometry(barShape, {steps: 1, depth: (this.width - ((this.baseMaterialThickness * 2) + .01)), bevelEnabled: false});
    let barMesh = new Mesh( barGeometry, material );

    //Rotate brackets
    bracketMeshLeft.rotateY(Math.PI / 2);
    bracketMeshRight.rotateY(Math.PI / 2);

    bracketMeshLeft.updateMatrix();
    bracketMeshRight.updateMatrix();

    //Move left bracket
    bracketMeshLeft.translateZ( this.baseMaterialThickness + .005 ); //local Axis turn with the rotation
    bracketMeshLeft.translateY( position );
    bracketMeshLeft.translateX( (this.depth - (this.baseMaterialThickness * 2))/-2  - 0.058);

    //Move Right Bracket
    bracketMeshRight.translateZ( this.width - ((this.baseMaterialThickness * 2) + .01) );
    bracketMeshRight.translateY( position );
    bracketMeshRight.translateX( (this.depth - (this.baseMaterialThickness * 2))/-2  - 0.058);
    
    barMesh.rotateY(Math.PI / 2);
    barMesh.translateZ( this.baseMaterialThickness + .005 );
    barMesh.translateY( position );
    barMesh.translateX( (this.depth - (this.baseMaterialThickness * 2))/-2  - 0.058 );

    this.group.add(bracketMeshLeft);
    this.group.add(bracketMeshRight);
    this.group.add(barMesh);
  }
}

export class Door extends Part implements DoorInterface {
  type:'door' = 'door';
  direction: 'left' | 'right' = 'left';
  open:boolean;

  constructor(settings:ModuleSettings) {
    super(settings);

    this.open = <boolean>(settings.doorsOpen);

    this.draw();
    return this;
  }

  draw():void {
    for( let index in this.objs ) { 
      let doorGroup = new Group();
      let doorProps = this.objs[index];
      let doorShape = new Shape();

      let doorWidth = (doorProps.width) ? doorProps.width : 0;


      if( doorProps.direction === 'left' ) {
        doorShape.moveTo( .001, 0 );
        doorShape.lineTo( .001, this.height - .001 );
        doorShape.lineTo( (doorWidth/this.baseScale) - .003 , this.height - .001 );
        doorShape.lineTo( (doorWidth/this.baseScale) - .003, 0 );
        doorShape.lineTo( .001, 0 );
      } else {
        doorShape.moveTo( (doorWidth/this.baseScale) - .003, 0 );
        doorShape.lineTo( (doorWidth/this.baseScale) - .003 , this.height - .001 );
        doorShape.lineTo( .001, this.height - .001 );
        doorShape.lineTo( .001, 0 );
        doorShape.lineTo( (doorWidth/this.baseScale) - .003, 0 );
      }

      let doorGeometry = new ExtrudeGeometry( doorShape, {steps: 1, depth: this.baseMaterialThickness, bevelEnabled: true, bevelThickness: .001, bevelSize: .001, bevelSegments: 1} );
      let doorMesh = new Mesh( doorGeometry, [this.baseTexture, this.baseTexture] );
      doorMesh.name = "door-"+doorProps.direction;
      // doorMesh.matrixAutoUpdate = false;

      let meshTranslationMatrix = new Matrix4();
      let translationMatrix = new Matrix4();

      if( doorProps.direction === 'left' ) {
        translationMatrix.makeTranslation( .001, 0, (this.depth - this.baseMaterialThickness) + .0015 );//x=0.0025 is 2,5mm to center it in its corpus

      } else {
        translationMatrix.makeTranslation( this.width - .001, 0, (this.depth - this.baseMaterialThickness) + .0015 );//x=0.0025 is 2,5mm to center it in its corpus
        meshTranslationMatrix.makeScale(-1, 1, 1);
      }

      doorMesh.applyMatrix4(meshTranslationMatrix);
      doorGroup.add( doorMesh );
      doorGroup.applyMatrix4(translationMatrix); //Do the translation on the group, so we can animate the rotation on the mesh locally!

      //Check if door should be open
      if( this.open ) {
        let rotationY = (-80*Math.PI / 180);//110deg in radians
        let translateX = this.baseMaterialThickness;
    
        if( doorProps.direction === 'left' ) {
          // openTranslationMatrix.makeTranslation(translateX * -1, 0, 0);
          doorMesh.rotation.y = rotationY;
          doorMesh.position.x = translateX;
        } else {
          doorMesh.rotation.y = rotationY * -1;
          doorMesh.position.x = translateX * -1;
        }
      }

      this.group.add(doorGroup);
    }
  }
}