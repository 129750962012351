import {
  EventDispatcher,
  Camera,
  MOUSE,
  Quaternion,
  Spherical,
  TOUCH,
  Vector2,
  Vector3,
  PerspectiveCamera,
  OrthographicCamera,
  Matrix4
} from "three";

type pointerPositions = {
  [key: string]: Vector2
}

interface OrbitControlsInterface {
  object: Camera;
  domElement: HTMLElement;

  // API
  enabled: boolean;
  target: Vector3;

  minDistance: number;
  maxDistance: number;

  minZoom: number;
  maxZoom: number;

  minPolarAngle: number;
  maxPolarAngle: number;

  minAzimuthAngle: number;
  maxAzimuthAngle: number;

  enableDamping: boolean;
  dampingFactor: number;

  enableZoom: boolean;
  zoomSpeed: number;

  enableRotate: boolean;
  rotateSpeed: number;

  enablePan: boolean;
  panSpeed: number;
  screenSpacePanning: boolean;
  keyPanSpeed: number;

  autoRotate: boolean;
  autoRotateSpeed: number;

  keys: { LEFT: string; UP: string; RIGHT: string; BOTTOM: string };
  mouseButtons: Partial<{ LEFT: MOUSE; MIDDLE: MOUSE; RIGHT: MOUSE }>;
  touches: Partial<{ ONE: TOUCH; TWO: TOUCH }>;

  target0: Vector3;
  position0: Vector3;
  zoom0: number;

  update(): boolean;
  listenToKeyEvents(domElement: HTMLElement | Window): void;
  saveState(): void;
  reset(): void;
  dispose(): void;
  getPolarAngle(): number;
  getAzimuthalAngle(): number;
  getDistance(): number;

  // EventDispatcher mixins
  addEventListener(type: string, listener: (event: any) => void): void;
  hasEventListener(type: string, listener: (event: any) => void): boolean;
  removeEventListener(type: string, listener: (event: any) => void): void;
  dispatchEvent(event: { type: string; target: any }): void;
}

class OrbitControls extends EventDispatcher implements OrbitControlsInterface{
  object: Camera;
  domElement: HTMLElement;
  enabled: boolean = true; // Set to false to disable this control
  target: Vector3 = new Vector3(); // "target" sets the location of focus, where the object orbits around
  // How far you can dolly in and out ( PerspectiveCamera only )
  minDistance: number = 0;
  maxDistance: number = Infinity;
  // How far you can zoom in and out ( OrthographicCamera only )
  minZoom: number = 0;
  maxZoom: number = Infinity;
  // How far you can orbit vertically, upper and lower limits.
  // Range is 0 to Math.PI radians.
  minPolarAngle: number = 0; // radians
  maxPolarAngle: number = Math.PI; // radians
  // How far you can orbit horizontally, upper and lower limits.
  // If set, the interval [ min, max ] must be a sub-interval of [ - 2 PI, 2 PI ], with ( max - min < 2 PI )
  minAzimuthAngle: number = -Infinity; // radians
  maxAzimuthAngle: number = Infinity; // radians
  // Set to true to enable damping (inertia)
  // If damping is enabled, you must call controls.update() in your animation loop
  enableDamping: boolean = false;
  dampingFactor: number = 0.05;
  // This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
  // Set to false to disable zooming
  enableZoom: boolean = true;
  zoomSpeed: number = 1.0;
  // Set to false to disable rotating
  enableRotate: boolean = true;
  rotateSpeed: number = 1.0;
  // Set to false to disable panning
  enablePan: boolean = true;
  panSpeed: number = 1.0;
  screenSpacePanning: boolean = true; // if false, pan orthogonal to world-space direction camera.up
  keyPanSpeed:number = 7.0; // pixels moved per arrow key push
  // Set to true to automatically rotate around the target
  // If auto-rotate is enabled, you must call controls.update() in your animation loop
  autoRotate: boolean = false;
  autoRotateSpeed: number = 2.0; // 30 seconds per orbit when fps is 60

  // The four arrow keys
  keys = {
    LEFT: "ArrowLeft",
    UP: "ArrowUp",
    RIGHT: "ArrowRight",
    BOTTOM: "ArrowDown",
  };

  // Mouse buttons
  mouseButtons = {
    LEFT: MOUSE.ROTATE,
    MIDDLE: MOUSE.DOLLY,
    RIGHT: MOUSE.PAN,
  };

  target0: Vector3;
  position0: Vector3;
  zoom0: number;

  // Touch fingers
  touches = { ONE: TOUCH.ROTATE, TWO: TOUCH.DOLLY_PAN };

  // the target DOM element for key events
  _domElementKeyEvents: HTMLElement|Window|null = null;


  STATE = {
    NONE: -1,
    ROTATE: 0,
    DOLLY: 1,
    PAN: 2,
    TOUCH_ROTATE: 3,
    TOUCH_PAN: 4,
    TOUCH_DOLLY_PAN: 5,
    TOUCH_DOLLY_ROTATE: 6,
  };

  state:number = this.STATE.NONE;
  EPS:number = 0.000001;
  
  spherical = new Spherical();
  sphericalDelta = new Spherical();
  scale:number = 1;

  panOffset:Vector3 = new Vector3();
  zoomChanged:boolean = false;

  rotateStart: Vector2 = new Vector2();
  rotateEnd: Vector2 = new Vector2();
  rotateDelta: Vector2 = new Vector2();

  panStart: Vector2 = new Vector2();
  panEnd: Vector2 = new Vector2();
  panDelta: Vector2 = new Vector2();

  dollyStart: Vector2 = new Vector2();
  dollyEnd: Vector2 = new Vector2();
  dollyDelta: Vector2 = new Vector2();

  pointers:Array<PointerEvent> = [];
  pointerPositions:pointerPositions = {};

  _changeEvent = { type: "change" };
  _startEvent = { type: "start" };
  _endEvent = { type: "end" };

  offset:Vector3 = new Vector3();
  lastPosition:Vector3 = new Vector3();
  quat:Quaternion;
  quatInverse:Quaternion;
  lastQuaternion:Quaternion = new Quaternion();
  twoPI:number = 2 * Math.PI;

  /** Functions start here */
  constructor(object: Camera, domElement?: HTMLElement) {
    super();

    this.object = object;
    this.domElement = (typeof domElement !== 'undefined') ? domElement : document.body;

    if( this.domElement instanceof HTMLElement ) {
      this.domElement.style.touchAction = "none"; // disable touch scroll
    }

    // for reset
    this.target0 = this.target.clone();
    this.position0 = this.object.position.clone();

    if( object instanceof PerspectiveCamera ) {
      this.zoom0 = (this.object as PerspectiveCamera).zoom;
    }

    this.domElement.addEventListener("contextmenu", (e:Event) => { this.#onContextMenu.call(this, e) });
    this.domElement.addEventListener("pointerdown", (e:Event) => { this.#onPointerDown.call(this, e as PointerEvent) });
    this.domElement.addEventListener("pointercancel", (e:Event) => { this.#onPointerCancel.call(this, e as PointerEvent) });
    this.domElement.addEventListener("wheel", (e:Event) => { this.#onMouseWheel.call(this, e  as WheelEvent) }, {
      passive: false,
    });

    // so camera.up is the orbit axis
    this.quat = new Quaternion().setFromUnitVectors(
      object.up,
      new Vector3(0, 1, 0)
    );
    this.quatInverse = this.quat.clone().invert();

    return this;
  }

  /** 
   * public methods
   */
  update(): boolean {
    //Update function goes here
    const position = this.object.position;

    this.offset.copy(position).sub(this.target);

    // rotate offset to "y-axis-is-up" space
    this.offset.applyQuaternion(this.quat);

    // angle from z-axis around y-axis
    this.spherical.setFromVector3(this.offset);

    if (this.autoRotate && this.state === this.STATE.NONE) {
      this.#rotateLeft(this.#getAutoRotationAngle());
    }

    if (this.enableDamping) {
      this.spherical.theta += this.sphericalDelta.theta * this.dampingFactor;
      this.spherical.phi += this.sphericalDelta.phi * this.dampingFactor;
    } else {
      this.spherical.theta += this.sphericalDelta.theta;
      this.spherical.phi += this.sphericalDelta.phi;
    }

    // restrict theta to be between desired limits

    let min = this.minAzimuthAngle;
    let max = this.maxAzimuthAngle;

    if (isFinite(min) && isFinite(max)) {
      if (min < -Math.PI) min += this.twoPI;
      else if (min > Math.PI) min -= this.twoPI;

      if (max < -Math.PI) max += this.twoPI;
      else if (max > Math.PI) max -= this.twoPI;

      if (min <= max) {
        this.spherical.theta = Math.max(min, Math.min(max, this.spherical.theta));
      } else {
        this.spherical.theta =
        this.spherical.theta > (min + max) / 2
            ? Math.max(min, this.spherical.theta)
            : Math.min(max, this.spherical.theta);
      }
    }

    // restrict phi to be between desired limits
    this.spherical.phi = Math.max(
      this.minPolarAngle,
      Math.min(this.maxPolarAngle, this.spherical.phi)
    );

    this.spherical.makeSafe();

    this.spherical.radius *= this.scale;

    // restrict radius to be between desired limits
    this.spherical.radius = Math.max(
      this.minDistance,
      Math.min(this.maxDistance, this.spherical.radius)
    );

    // move target to panned location

    if (this.enableDamping === true) {
      this.target.addScaledVector(this.panOffset, this.dampingFactor);
    } else {
      this.target.add(this.panOffset);
    }

    this.offset.setFromSpherical(this.spherical);

    // rotate offset back to "camera-up-vector-is-up" space
    this.offset.applyQuaternion(this.quatInverse);

    position.copy(this.target).add(this.offset);

    this.object.lookAt(this.target);

    if (this.enableDamping === true) {
      this.sphericalDelta.theta *= 1 - this.dampingFactor;
      this.sphericalDelta.phi *= 1 - this.dampingFactor;

      this.panOffset.multiplyScalar(1 - this.dampingFactor);
    } else {
      this.sphericalDelta.set(0, 0, 0);

      this.panOffset.set(0, 0, 0);
    }

    this.scale = 1;

    // update condition is:
    // min(camera displacement, camera rotation in radians)^2 > EPS
    // using small-angle approximation cos(x/2) = 1 - x^2 / 8

    if (
      this.zoomChanged ||
      this.lastPosition.distanceToSquared(this.object.position) > this.EPS ||
      8 * (1 - this.lastQuaternion.dot(this.object.quaternion)) > this.EPS
    ) {
      this.dispatchEvent(this._changeEvent);

      this.lastPosition.copy(this.object.position);
      this.lastQuaternion.copy(this.object.quaternion);
      this.zoomChanged = false;

      return true;
    }

    return false;
  }

  getPolarAngle(): number {
    return this.spherical.phi;
  }

  getAzimuthalAngle(): number {
    return this.spherical.theta;
  }

  getDistance(): number {
    return this.object.position.distanceTo(this.target);
  }

  listenToKeyEvents(domElement: HTMLElement|Window|null): void {
    if( domElement ) {
      domElement.addEventListener("keydown", (e:Event) => { this.#onKeyDown.call(this, e) });
      this._domElementKeyEvents = domElement;
    }
  }

  saveState(): void {
    this.target0.copy(this.target);
    this.position0.copy(this.object.position);

    if( this.object instanceof PerspectiveCamera ) {
      this.zoom0 = this.object.zoom;
    }
  };

  reset(): void {
    this.target.copy(this.target0);
    this.object.position.copy(this.position0);

    if( this.object instanceof PerspectiveCamera ) {
      this.object.zoom = this.zoom0;
      this.object.updateProjectionMatrix();
    }

    this.dispatchEvent(this._changeEvent);

    this.update();

    this.state = this.STATE.NONE;
  };

  dispose(): void {
    this.domElement.removeEventListener("contextmenu", (e:Event) => { this.#onContextMenu.call(this, e) });

    this.domElement.removeEventListener("pointerdown", (e:PointerEvent) => { this.#onPointerDown.call(this, e) });
    this.domElement.removeEventListener("pointercancel", (e:PointerEvent) => { this.#onPointerCancel.call(this, e) });
    this.domElement.removeEventListener("wheel", (e:WheelEvent) => { this.#onMouseWheel.call(this, e) });

    this.domElement.removeEventListener("pointermove", (e:PointerEvent) => { this.#onPointerMove.call(this, e) });
    this.domElement.removeEventListener("pointerup", (e:PointerEvent) => { this.#onPointerUp.call(this, e) });

    if (this._domElementKeyEvents !== null) {
      this._domElementKeyEvents.removeEventListener("keydown", (e:Event) => { this.#onKeyDown.call(this, e) });
    }
  };

  /**
   * Private functions
   */
  #getAutoRotationAngle():number {
    return ((2 * Math.PI) / 60 / 60) * this.autoRotateSpeed;
  }

  #getZoomScale() {
    return Math.pow(0.95, this.zoomSpeed);
  }

  #rotateLeft(angle:number) {
    this.sphericalDelta.theta -= angle;
  }

  #rotateUp(angle:number) {
    this.sphericalDelta.phi -= angle;
  }

  #onContextMenu(event:Event):void {
    if (this.enabled === false) return;
    event.preventDefault();
  }

  //Mouse Events
  #onMouseWheel(event:WheelEvent) {
    if (
      this.enabled === false ||
      this.enableZoom === false ||
      this.state !== this.STATE.NONE
    )
      return;

    event.preventDefault();

    this.dispatchEvent(this._startEvent);

    this.#handleMouseWheel(event);

    this.dispatchEvent(this._endEvent);
  }

  #handleMouseWheel(event: WheelEvent) {
    if (event.deltaY < 0) {
      this.#dollyIn(this.#getZoomScale());
    } else if (event.deltaY > 0) {
      this.#dollyOut(this.#getZoomScale());
    }

    this.update();
  }

  #onMouseDown(event:MouseEvent) {
    let mouseAction;

    switch (event.button) {
      case 0:
        mouseAction = this.mouseButtons.LEFT;
        break;

      case 1:
        mouseAction = this.mouseButtons.MIDDLE;
        break;

      case 2:
        mouseAction = this.mouseButtons.RIGHT;
        break;

      default:
        mouseAction = -1;
    }

    switch (mouseAction) {
      case MOUSE.DOLLY:
        if (this.enableZoom === false) return;

        this.#handleMouseDownDolly(event);

        this.state = this.STATE.DOLLY;

        break;

      case MOUSE.ROTATE:
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          if (this.enablePan === false) return;

          this.#handleMouseDownPan(event);

          this.state = this.STATE.PAN;
        } else {
          if (this.enableRotate === false) return;

          this.#handleMouseDownRotate(event);

          this.state = this.STATE.ROTATE;
        }

        break;

      case MOUSE.PAN:
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          if (this.enableRotate === false) return;

          this.#handleMouseDownRotate(event);

          this.state = this.STATE.ROTATE;
        } else {
          if (this.enablePan === false) return;

          this.#handleMouseDownPan(event);

          this.state = this.STATE.PAN;
        }

        break;

      default:
        this.state = this.STATE.NONE;
    }

    if (this.state !== this.STATE.NONE) {
      this.dispatchEvent(this._startEvent);
    }
  }

  #onMouseMove(event:MouseEvent) {
    switch (this.state) {
      case this.STATE.ROTATE:
        if (this.enableRotate === false) return;

        this.#handleMouseMoveRotate(event);

        break;

      case this.STATE.DOLLY:
        if (this.enableZoom === false) return;

        this.#handleMouseMoveDolly(event);

        break;

      case this.STATE.PAN:
        if (this.enablePan === false) return;

        this.#handleMouseMovePan(event);

        break;
    }
  }

  #handleMouseMoveRotate(event:MouseEvent) {
    this.rotateEnd.set(event.clientX, event.clientY);

    this.rotateDelta
      .subVectors(this.rotateEnd, this.rotateStart)
      .multiplyScalar(this.rotateSpeed);

    const element = this.domElement;

    this.#rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight); // yes, height
    this.#rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight);

    this.rotateStart.copy(this.rotateEnd);

    this.update();
  }

  #handleMouseMovePan(event:MouseEvent) {
    this.panEnd.set(event.clientX, event.clientY);

    this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.panSpeed);

    this.#pan(this.panDelta.x, this.panDelta.y);

    this.panStart.copy(this.panEnd);

    this.update();
  }

  // Pointer Events
  #onPointerDown(event:PointerEvent):void {
    if (this.enabled === false) return;

    if (this.pointers.length === 0) {
      this.domElement.setPointerCapture(event.pointerId);

      this.domElement.addEventListener("pointermove", (e:PointerEvent) => { this.#onPointerMove.call(this, e) });
      this.domElement.addEventListener("pointerup", (e:PointerEvent) => { this.#onPointerUp.call(this, e) });
    }

    this.#addPointer(event);

    if (event.pointerType === "touch") {
      this.#onTouchStart(event);
    } else {
      this.#onMouseDown(event);
    }
  }

  #onPointerCancel(event:PointerEvent) {
    this.#removePointer(event);
  }

  #onPointerMove(event:Event) {
    if (this.enabled === false) return;

    if (event instanceof PointerEvent && event.pointerType === "touch") {
      this.#onTouchMove(event as PointerEvent);
    } else {
      this.#onMouseMove(event as MouseEvent);
    }
  }

  #onPointerUp(event: PointerEvent) {
    this.#removePointer(event);

    if (this.pointers.length === 0) {
      this.domElement.releasePointerCapture(event.pointerId);

      this.domElement.removeEventListener("pointermove", (e:PointerEvent) => { this.#onPointerMove.call(this, e) });
      this.domElement.removeEventListener("pointerup", (e:PointerEvent) => { this.#onPointerUp.call(this, e) });
    }

    this.dispatchEvent(this._endEvent);

    this.state = this.STATE.NONE;
  }

  #trackPointer(event: PointerEvent) {
    let position = this.pointerPositions[event.pointerId];

    if (position === undefined) {
      position = new Vector2();
      this.pointerPositions[event.pointerId] = position;
    }

    position.set(event.pageX, event.pageY);
  }

  #addPointer(event: PointerEvent) {
    this.pointers.push(event);
  }

  #removePointer(event:PointerEvent) {
    delete this.pointerPositions[event.pointerId];

    for (let i = 0; i < this.pointers.length; i++) {
      if (this.pointers[i].pointerId == event.pointerId) {
        this.pointers.splice(i, 1);
        return;
      }
    }
  }

  //Touch events
  #onTouchStart(event:Event) {
    this.#trackPointer(event as PointerEvent);

    switch (this.pointers.length) {
      case 1:
        switch (this.touches.ONE) {
          case TOUCH.ROTATE:
            if (this.enableRotate === false) return;

            this.#handleTouchStartRotate();

            this.state = this.STATE.TOUCH_ROTATE;

            break;

          case TOUCH.PAN:
            if (this.enablePan === false) return;

            this.#handleTouchStartPan();

            this.state = this.STATE.TOUCH_PAN;

            break;

          default:
            this.state = this.STATE.NONE;
        }

        break;

      case 2:
        switch (this.touches.TWO) {
          case TOUCH.DOLLY_PAN:
            if (this.enableZoom === false && this.enablePan === false)
              return;

            this.#handleTouchStartDollyPan();

            this.state = this.STATE.TOUCH_DOLLY_PAN;

            break;

          case TOUCH.DOLLY_ROTATE:
            if (this.enableZoom === false && this.enableRotate === false)
              return;

            this.#handleTouchStartDollyRotate();

            this.state = this.STATE.TOUCH_DOLLY_ROTATE;

            break;

          default:
            this.state = this.STATE.NONE;
        }

        break;

      default:
        this.state = this.STATE.NONE;
    }

    if (this.state !== this.STATE.NONE) {
      this.dispatchEvent(this._startEvent);
    }
  }

  #onTouchMove(event:PointerEvent) {
    this.#trackPointer(event as PointerEvent);

    switch (this.state) {
      case this.STATE.TOUCH_ROTATE:
        if (this.enableRotate === false) return;

        this.#handleTouchMoveRotate(event);

        this.update();

        break;

      case this.STATE.TOUCH_PAN:
        if (this.enablePan === false) return;

        this.#handleTouchMovePan(event);

        this.update();

        break;

      case this.STATE.TOUCH_DOLLY_PAN:
        if (this.enableZoom === false && this.enablePan === false) return;

        this.#handleTouchMoveDollyPan(event);

        this.update();

        break;

      case this.STATE.TOUCH_DOLLY_ROTATE:
        if (this.enableZoom === false && this.enableRotate === false)
          return;

          this.#handleTouchMoveDollyRotate(event);

        this.update();

        break;

      default:
        this.state = this.STATE.NONE;
    }
  }

  //Keyboard events
  #onKeyDown(event: Event):void {
    if (this.enabled === false || this.enablePan === false) return;

    this.#handleKeyDown.call(this, event);
  }

  #handleKeyDown(event: Event) {
    let needsUpdate = false;

    if( event instanceof KeyboardEvent ) {

      switch (event.code) {
        case this.keys.UP:
          this.#pan(0, this.keyPanSpeed);
          needsUpdate = true;
          break;

        case this.keys.BOTTOM:
          this.#pan(0, -this.keyPanSpeed);
          needsUpdate = true;
          break;

        case this.keys.LEFT:
          this.#pan(this.keyPanSpeed, 0);
          needsUpdate = true;
          break;

        case this.keys.RIGHT:
          this.#pan(-this.keyPanSpeed, 0);
          needsUpdate = true;
          break;
      }
    }

    if (needsUpdate) {
      // prevent the browser from scrolling on cursor keys
      event.preventDefault();

      this.update();
    }
  }

  //Dolly stuff
  #dollyIn(dollyScale:number): void {
    if (this.object instanceof PerspectiveCamera && this.object.isPerspectiveCamera) {
      this.scale *= dollyScale;
    } else if (this.object instanceof OrthographicCamera && this.object.isOrthographicCamera) {
      this.object.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.object.zoom / dollyScale)
      );
      this.object.updateProjectionMatrix();
      this.zoomChanged = true;
    } else {
      console.warn(
        "WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled."
      );
      this.enableZoom = false;
    }
  }

  #dollyOut(dollyScale:number) {
    if (this.object instanceof PerspectiveCamera && this.object.isPerspectiveCamera) {
      this.scale /= dollyScale;
    } else if (this.object instanceof OrthographicCamera && this.object.isOrthographicCamera) {
      this.object.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.object.zoom * dollyScale)
      );
      this.object.updateProjectionMatrix();
      this.zoomChanged = true;
    } else {
      console.warn(
        "WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled."
      );
      this.enableZoom = false;
    }
  }

  #handleMouseDownDolly(event:MouseEvent) {
    this.dollyStart.set(event.clientX, event.clientY);
  }

  #handleMouseDownPan(event:MouseEvent) {
    this.panStart.set(event.clientX, event.clientY);
  }

  #handleMouseDownRotate(event: MouseEvent) {
    this.rotateStart.set(event.clientX, event.clientY);
  }

  #handleMouseMoveDolly(event: MouseEvent) {
    this.dollyEnd.set(event.clientX, event.clientY);

    this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);

    if (this.dollyDelta.y > 0) {
      this.#dollyOut(this.#getZoomScale());
    } else if (this.dollyDelta.y < 0) {
      this.#dollyIn(this.#getZoomScale());
    }

    this.dollyStart.copy(this.dollyEnd);

    this.update();
  }

  #handleTouchStartRotate() {
    if (this.pointers.length === 1) {
      this.rotateStart.set(this.pointers[0].pageX, this.pointers[0].pageY);
    } else {
      const x = 0.5 * (this.pointers[0].pageX + this.pointers[1].pageX);
      const y = 0.5 * (this.pointers[0].pageY + this.pointers[1].pageY);

      this.rotateStart.set(x, y);
    }
  }

  #handleTouchStartPan() {
    if (this.pointers.length === 1) {
      this.panStart.set(this.pointers[0].pageX, this.pointers[0].pageY);
    } else {
      const x = 0.5 * (this.pointers[0].pageX + this.pointers[1].pageX);
      const y = 0.5 * (this.pointers[0].pageY + this.pointers[1].pageY);

      this.panStart.set(x, y);
    }
  }

  #handleTouchStartDollyPan() {
    if (this.enableZoom) this.#handleTouchStartDolly();
    if (this.enablePan) this.#handleTouchStartPan();
  }

  #handleTouchStartDollyRotate() {
    if (this.enableZoom) this.#handleTouchStartDolly();
    if (this.enableRotate) this.#handleTouchStartRotate();
  }

  #handleTouchMoveDollyPan(event: PointerEvent) {
    if (this.enableZoom) this.#handleTouchMoveDolly(event);
    if (this.enablePan) this.#handleTouchMovePan(event);
  }

  #handleTouchMoveDollyRotate(event: PointerEvent) {
    if (this.enableZoom) this.#handleTouchMoveDolly(event);
    if (this.enableRotate) this.#handleTouchMoveRotate(event);
  }

  #handleTouchStartDolly() {
    const dx = this.pointers[0].pageX - this.pointers[1].pageX;
    const dy = this.pointers[0].pageY - this.pointers[1].pageY;

    const distance = Math.sqrt(dx * dx + dy * dy);

    this.dollyStart.set(0, distance);
  }

  #handleTouchMoveDolly(event: Event) {
    const position = this.#getSecondPointerPosition(event as PointerEvent);

    if( event instanceof PointerEvent ) {
      const dx = event.pageX - position.x;
      const dy = event.pageY - position.y

      const distance = Math.sqrt(dx * dx + dy * dy);

      this.dollyEnd.set(0, distance);

      this.dollyDelta.set(0, Math.pow(this.dollyEnd.y / this.dollyStart.y, this.zoomSpeed));

      this.#dollyOut(this.dollyDelta.y);

      this.dollyStart.copy(this.dollyEnd);
    }
  }

  #handleTouchMoveRotate(event: Event) {
    if (event instanceof MouseEvent && this.pointers.length == 1) {
      this.rotateEnd.set(event.pageX, event.pageY);
    } else if( event instanceof PointerEvent ) {
      const position = this.#getSecondPointerPosition(event);

      const x = 0.5 * (event.pageX + position.x);
      const y = 0.5 * (event.pageY + position.y);

      this.rotateEnd.set(x, y);
    }

    this.rotateDelta
      .subVectors(this.rotateEnd, this.rotateStart)
      .multiplyScalar(this.rotateSpeed);

    const element = this.domElement;

    this.#rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight); // yes, height
    this.#rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight);

    this.rotateStart.copy(this.rotateEnd);
  }

  #handleTouchMovePan(event:Event) {
    if (event instanceof MouseEvent && this.pointers.length === 1) {
      this.panEnd.set(event.pageX, event.pageY);
    } else if(event instanceof PointerEvent) {
      const position = this.#getSecondPointerPosition(event);

      if( event instanceof PointerEvent ) {
        const x = 0.5 * (event.pageX + position.x);
        const y = 0.5 * (event.pageY + position.y);

        this.panEnd.set(x, y);
      }
    }

    this.panDelta.subVectors(this.panEnd, this.panStart).multiplyScalar(this.panSpeed);

    this.#pan(this.panDelta.x, this.panDelta.y);

    this.panStart.copy(this.panEnd);
  }

  #getSecondPointerPosition(event:PointerEvent) {
    const pointer =
      event.pointerId === this.pointers[0].pointerId ? this.pointers[1] : this.pointers[0];

    return this.pointerPositions[pointer.pointerId];
  }

  #panLeft( distance:number, objectMatrix:Matrix4 ) {
    const v = new Vector3();

    v.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
    v.multiplyScalar(-distance);

    this.panOffset.add(v);
  }

  #panUp(distance:number, objectMatrix:Matrix4) {
    const v = new Vector3();

    if (this.screenSpacePanning === true) {
      v.setFromMatrixColumn(objectMatrix, 1);
    } else {
      v.setFromMatrixColumn(objectMatrix, 0);
      v.crossVectors(this.object.up, v);
    }

    v.multiplyScalar(distance);

    this.panOffset.add(v);
  }
 
  #pan(deltaX:number, deltaY:number) {
    const offset = new Vector3();
    const element = this.domElement;

    if (this.object instanceof PerspectiveCamera && this.object.isPerspectiveCamera) {
      // perspective
      const position = this.object.position;
      offset.copy(position).sub(this.target);
      let targetDistance = offset.length();

      // half of the fov is center to top of screen
      targetDistance *= Math.tan(
        ((this.object.fov / 2) * Math.PI) / 180.0
      );

      // we use only clientHeight here so aspect ratio does not distort speed
      this.#panLeft(
        (2 * deltaX * targetDistance) / element.clientHeight,
        this.object.matrix
      );
      this.#panUp(
        (2 * deltaY * targetDistance) / element.clientHeight,
        this.object.matrix
      );
    } else if (this.object instanceof OrthographicCamera && this.object.isOrthographicCamera) {
      // orthographic
      this.#panLeft(
        (deltaX * (this.object.right - this.object.left)) /
          this.object.zoom /
          element.clientWidth,
        this.object.matrix
      );
      this.#panUp(
        (deltaY * (this.object.top - this.object.bottom)) /
          this.object.zoom /
          element.clientHeight,
        this.object.matrix
      );
    } else {
      // camera neither orthographic nor perspective
      console.warn(
        "WARNING: OrbitControls.js encountered an unknown camera type - pan disabled."
      );
      this.enablePan = false;
    }
  }
}

export default OrbitControls;