import { defineStore } from "pinia";
import { uuid } from "vue-uuid";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import { useSettingsStore } from "./settings";
import { last } from "lodash";

export const useConfigurationStore = defineStore("configuration", {
  //state
  state: () => ({
    type: "standing",
    measurements: {
      width: 500,
      height: 1800,
      depth: 300,
    },
    toeKick: {
      active: true,
      indent: 0,
      height: 40,
      extFinish: 11,
      parts: [
        {
          length: 500,
          position: 0,
        },
      ],
    },
    equalCorpusWidth: true,
    corpora: [],
    sameMaterialForAllCorpora: true,
    handle: false,
    tablets: [],
    tabletMiters: false,
    pricing: {
      totalPrice: 0,
    },
  }),
  getters: {
    getCorpora: (state) => {
      return state.corpora;
    },
    getMeasurements: (state) => {
      return state.measurements;
    },
    getNrOfCorpora: (state) => {
      return state.corpora.length;
    },
    getCorpusMinHeight: (state) => {
      return (corpusId) => {
        let corpus = state.corpora.find((item) => item.corpusId === corpusId);

        if (corpus.height < 300) {
          return state.measurements.depth;
        } else {
          return 300;
        }
      };
    },
    getCorpusMinWidth: (state) => {
      const settings = useSettingsStore();
      let maxLengthOtherCorpora =
        (state.corpora.length - 1) *
        settings.constraints.dimensions.corpus.maxWidth;
      let minWidth = state.measurements.width - maxLengthOtherCorpora;

      return minWidth < settings.constraints.dimensions.corpus.minWidth
        ? settings.constraints.dimensions.corpus.minWidth
        : minWidth;
    },
    getCorpusMaxWidth: (state) => {
      const settings = useSettingsStore();
      let minLengthOtherCorpora =
        (state.corpora.length - 1) *
        settings.constraints.dimensions.corpus.minWidth;
      let maxWidth = state.measurements.width - minLengthOtherCorpora;

      return maxWidth > settings.constraints.dimensions.corpus.maxWidth
        ? settings.constraints.dimensions.corpus.maxWidth
        : maxWidth;
    },
    getItemMinSpace: (state) => {
      return (corpusId, type, direction) => {
        const settings = useSettingsStore();
        let corpus = state.corpora.find((item) => item.corpusId === corpusId);

        let itemMin = 0;
        let itemMax = 0;
        let totalSpace = 0;

        if (direction === "vertical") {
          itemMin = settings.constraints.dimensions.modules[type].minHeight;
          itemMax = settings.constraints.dimensions.modules[type].maxHeight;
          totalSpace = corpus.height - state.toeKick.height;
          //totalSpace = corpus.height - state.toeKick.height;
        } else {
          itemMin = settings.constraints.dimensions.modules[type].minWidth;
          itemMax = settings.constraints.dimensions.modules[type].maxWidth;
          totalSpace = corpus.width;
        }

        let maxSpaceOtherItems =
          (corpus.modules[type].objs.length - 1) * itemMax;
        let minSpace = totalSpace - maxSpaceOtherItems;

        return minSpace < itemMin ? itemMin : minSpace;
      };
    },
    getItemMaxSpace: (state) => {
      return (corpusId, type, direction) => {
        const settings = useSettingsStore();
        let corpus = state.corpora.find((item) => item.corpusId === corpusId);

        let itemMin = 0;
        let itemMax = 0;
        let totalSpace = 0;

        if (direction === "vertical") {
          itemMin = settings.constraints.dimensions.modules[type].minHeight;
          itemMax = settings.constraints.dimensions.modules[type].maxHeight;
          totalSpace = state.measurements.height - state.toeKick.height;
          // totalSpace = corpus.height - state.toeKick.height;
        } else {
          itemMin = settings.constraints.dimensions.modules[type].minWidth;
          itemMax = settings.constraints.dimensions.modules[type].maxWidth;
          totalSpace = corpus.width;
        }

        let minSpaceOtherItems =
          (corpus.modules[type].objs.length - 1) * itemMin;
        let maxSpace = totalSpace - minSpaceOtherItems;

        return maxSpace > itemMax ? itemMax : maxSpace;
      };
    },
    getShelvesMax(state) {
      return (corpusId) => {
        const settings = useSettingsStore();
        let corpus = state.corpora.find((item) => item.corpusId === corpusId);

        let height = corpus.hasDeviatingHeight
          ? corpus.height
          : state.measurements.height;

        let offset = 1;

        if (corpus.clothesRail) {
          height -= 1000; //space above + space for clothing rail
          offset = 0;
        }

        let max =
          Math.floor(
            (height - state.toeKick.height) /
              settings.constraints.dimensions.modules.shelves.minHeight
          ) - offset;

        //Exception for first shelve
        if (
          height >=
            settings.constraints.dimensions.modules.shelves.firstMinHeight &&
          max <= 0
        ) {
          max = 1;
        }

        max = max < 0 ? 0 : max;

        return max;
      };
    },
  },
  actions: {
    onTypeAdjusted() {
      const settings = useSettingsStore();
      if (this.type == "hanging") {
        this.toeKick.height = 0;
        this.toeKick.active = false;
      } else {
        this.toeKick.active = true;
        this.toeKick.height = settings.defaults.toeKick.height;
      }

      //Removing bottom tablets on all corpora
      this.corpora.forEach((corpus) => {
        corpus.tablets.bottom = false;
      });

      //Check widht/height/depth constraints and contain
      if (
        this.measurements.width >
          settings.constraints.dimensions[this.type].maxWidth ||
        this.measurements.width <
          settings.constraints.dimensions[this.type].minWidth
      ) {
        this.measurements.width = settings.defaults.dimensions[this.type].width;
      }

      if (
        this.measurements.height >
          settings.constraints.dimensions[this.type].maxHeight ||
        this.measurements.height <
          settings.constraints.dimensions[this.type].minHeight
      ) {
        this.measurements.height =
          settings.defaults.dimensions[this.type].height;
      }

      if (
        this.measurements.depth >
          settings.constraints.dimensions[this.type].maxDepth ||
        this.measurements.height <
          settings.constraints.dimensions[this.type].minDepth
      ) {
        this.measurements.depth = settings.defaults.dimensions[this.type].depth;
      }

      if (!settings.flags.measurementsAdjusted) {
        //Set the defaults for that type, if this is the first time!
        this.measurements.width = settings.defaults.dimensions[this.type].width;
        this.measurements.height =
          settings.defaults.dimensions[this.type].height;
        this.measurements.depth = settings.defaults.dimensions[this.type].depth;
      }

      this.onMeasurementsAdjusted();
    },
    calculateToekick() {
      let toekickProcessed = [];

      if (this.type !== "hanging") {
        let currentPosition = 0;
        let currentObject = false;

        this.corpora.forEach((corpus) => {
          if (corpus.typeId == 1) {
            if (currentObject !== false) {
              toekickProcessed.push(currentObject);
              currentObject = false;
            }
          } else {
            if (
              currentObject !== false &&
              corpus.toeKickFinish !== currentObject.extFinish
            ) {
              toekickProcessed.push(currentObject);
              currentObject = false;
            }

            if (!currentObject) {
              currentObject = {
                length: 0,
                position: currentPosition,
                extFinish: corpus.toeKickFinish,
              };
            }

            currentObject.length += corpus.width;

            if (corpus.tablets.left) {
              currentObject.length += 40;
            }

            if (corpus.tablets.right) {
              currentObject.length += 40;
            }
          }

          currentPosition += corpus.width;
          if (corpus.tablets.left) {
            currentPosition += 40;
          }

          if (corpus.tablets.right) {
            currentPosition += 40;
          }
        });

        if (currentObject) {
          //Dont add false!
          toekickProcessed.push(currentObject);
        }
      }

      this.toeKick.parts = toekickProcessed;
    },
    calculateTablets() {
      let tabletsProcessed = [];

      let topTablet = false;
      let bottomTablet = false;
      let lastCorpusHeight = false;

      //Do stuff to calculate toekick/tablets
      this.corpora.forEach((corpus, index) => {
        //Check for left tablet
        if (corpus.tablets.left) {
          let tablet = {
            length: corpus.height,
            extFinish: corpus.tabletsFinish,
            miters: 0,
            hangingUnderSupplement: false,
          };

          if (this.tabletMiters) {
            if (corpus.tablets.bottom) {
              tablet.length += 40;
              tablet.miters += 1;
            }

            if (corpus.tablets.top) {
              tablet.length += 40;
              tablet.miters += 1;
            }
          }

          tabletsProcessed.push(tablet);
        }

        //Check for right tablet
        if (corpus.tablets.right) {
          let tablet = {
            length: corpus.height,
            extFinish: corpus.tabletsFinish,
            miters: 0,
            hangingUnderSupplement: false,
          };

          if (this.tabletMiters) {
            if (corpus.tablets.bottom) {
              tablet.length += 40;
              tablet.miters += 1;
            }

            if (corpus.tablets.top) {
              tablet.length += 40;
              tablet.miters += 1;
            }
          }

          tabletsProcessed.push(tablet);
        }

        //Check for top/bottoms
        if (corpus.tablets.top) {
          if (
            lastCorpusHeight !== false &&
            lastCorpusHeight !== corpus.height
          ) {
            if (topTablet !== false) {
              tabletsProcessed.push(topTablet);
              topTablet = false;
            }
          }

          if (topTablet === false) {
            topTablet = {
              length: 0,
              extFinish: corpus.tabletsFinish,
              miters: 0,
              hangingUnderSupplement: false,
            };
          }

          //Increase tablet with width of corpus
          topTablet.length += corpus.width;

          //Check if this tablets has miters & needs increased with for miters
          if (corpus.tablets.left) {
            topTablet.length += 40;

            if (this.tabletMiters) {
              topTablet.miters += 1;
            }
          }

          //Check if this tablets has miters & needs increased with for miters
          if (corpus.tablets.right) {
            topTablet.length += 40;

            if (this.tabletMiters) {
              topTablet.miters += 1;
            }
          }
        } else if (topTablet !== false) {
          tabletsProcessed.push(topTablet);
          topTablet = false;
        }

        if (this.type === "hanging") {
          //Check for bottom tablets
          if (corpus.tablets.bottom) {
            //do your thing
            if (bottomTablet === false) {
              bottomTablet = {
                length: 0,
                extFinish: corpus.tabletsFinish,
                miters: 0,
                hangingUnderSupplement: true,
              };
            }

            //Increase tablet with width of corpus
            bottomTablet.length += corpus.width;

            //Check if this tablets has miters & needs increased with for miters
            if (corpus.tablets.left) {
              bottomTablet.length += 40;

              if (this.tabletMiters) {
                bottomTablet.miters += 1;
              }
            }

            //Check if this tablets has miters & needs increased with for miters
            if (corpus.tablets.right) {
              bottomTablet.length += 40;

              if (this.tabletMiters) {
                bottomTablet.miters += 1;
              }
            }
          } else if (bottomTablet !== false) {
            tabletsProcessed.push(bottomTablet);
            bottomTablet = false;
          }
        }

        lastCorpusHeight = corpus.height;
      });

      if (topTablet !== false) {
        tabletsProcessed.push(topTablet);
      }

      if (bottomTablet !== false) {
        tabletsProcessed.push(bottomTablet);
      }

      //Set to the state!
      this.tablets = tabletsProcessed;
    },
    onCorpusHeightAdjusted(selectedCorpusId) {
      const settings = useSettingsStore();
      selectedCorpusId =
        typeof selectedCorpusId != "undefined" ? selectedCorpusId : false;

      if (selectedCorpusId) {
        let corpus = this.corpora.find(
          (item) => item.corpusId === selectedCorpusId
        );

        if (
          corpus.height < settings.constraints.dimensions.corpus.tabletTopBelow
        ) {
          //Set tablet top to true!
          corpus.tablets.top = true;
        }
      } else {
        //Do for all corpora
        this.corpora.forEach((corpus) => {
          if (
            corpus.height <
            settings.constraints.dimensions.corpus.tabletTopBelow
          ) {
            //Set tablet top to true!
            corpus.tablets.top = true;
          }
        });
      }
    },
    onMeasurementsAdjusted(selectedCorpusId) {
      const settings = useSettingsStore();
      const currentNrOfCorpora = this.corpora.length;
      selectedCorpusId =
        typeof selectedCorpusId != "undefined" ? selectedCorpusId : false;

      //Adjust the number of corpora
      let addACorpus = currentNrOfCorpora < settings.getMinNrOfCorpora;
      let removeACorpus = currentNrOfCorpora > settings.getMaxNrOfCorpora;

      if (addACorpus) {
        let iterations = settings.getMinNrOfCorpora - currentNrOfCorpora;

        for (let i = 0; i < Math.abs(iterations); i++) {
          this.addCorpus();
        }
      } else if (removeACorpus) {
        let iterations = currentNrOfCorpora - settings.getMaxNrOfCorpora;

        for (let i = 0; i < Math.abs(iterations); i++) {
          this.removeCorpus();
        }
      }

      this.adjustCorpusWidths(selectedCorpusId);

      //Check if any of the corpora has a tablet top or bottom, because this impacts the total measurements!
      let closetHasTopTablets = false;
      let closetHasBottomTablets = false;

      this.corpora.forEach((corpus) => {
        if (corpus.tablets.bottom && !corpus.hasDeviatingHeight) {
          closetHasBottomTablets = true;
        }

        if (corpus.tablets.top && !corpus.hasDeviatingHeight) {
          closetHasTopTablets = true;
        }
      });

      let maxCorpusHeight = this.measurements.height;

      if (closetHasBottomTablets) {
        maxCorpusHeight -= 40;
      }

      if (closetHasTopTablets) {
        maxCorpusHeight -= 40;
      }

      //Check if corpora has modules & adjust
      this.corpora.forEach((corpus) => {
        //Check corpora deviatingHeights & see to it that the min is respected!
        if (!corpus.hasDeviatingHeight) {
          corpus.height = maxCorpusHeight;
        } else if (corpus.height < this.measurements.depth) {
          corpus.height = this.measurements.depth;
        } else if (corpus.height > maxCorpusHeight) {
          corpus.hasDeviatingHeight = false;
          corpus.height = maxCorpusHeight;
        }

        if (!isEmpty(corpus.modules)) {
          this.adjustModuleSpaces(corpus.corpusId);
        }
      });

      this.adjustHandles();
      this.calculateTablets();
      this.calculateToekick();
    },
    adjustCorpusWidths(selectedCorpusId) {
      const settings = useSettingsStore();
      let newWidth = 0;

      selectedCorpusId =
        typeof selectedCorpusId != "undefined" ? selectedCorpusId : false;
      this.measurements.width = Math.round(this.measurements.width);
      let totalWidth = this.measurements.width;

      //Check for tablets on sides of corpora / tablet is 40mm wide
      if (this.corpora[0].tablets.left) {
        totalWidth -= 40;
      }

      if (this.corpora[this.corpora.length - 1].tablets.right) {
        totalWidth -= 40;
      }

      //Calculate widths
      if (this.equalCorpusWidth) {
        let remainder = totalWidth % this.corpora.length;
        totalWidth = totalWidth - remainder;

        //Update all corpus widths!
        let corpusWidth = totalWidth / this.corpora.length;

        //Set new width to others!
        this.corpora.forEach((corpus, index) => {
          if (index == this.corpora.length - 1) {
            corpus.width = corpusWidth + remainder;
          } else {
            corpus.width = corpusWidth;
          }
        });

        newWidth = Math.round(corpusWidth);
      } else {
        //Update only new corpus widths
        let otherCorporaWidthSum = 0;
        let corporaWithoutSetWidth = 0;
        let nrOfCorpora = this.corpora.length;

        //Try to gather the total sum of set widths
        otherCorporaWidthSum = this.corpora.reduce((prev, current) => {
          if (current.customWidthSet) {
            if (current.width) {
              return parseFloat(prev) + parseFloat(current.width);
            } else {
              console.error(
                "Has a custom width set, but width is null or undefined!"
              );
            }
          } else {
            corporaWithoutSetWidth++;
            return parseFloat(prev);
          }
        }, otherCorporaWidthSum);

        //Calculate remaining width to distribute evenly
        let remainingWidth = totalWidth - Math.round(otherCorporaWidthSum);

        let newCorpusWidth = 0;
        let tooMuchSpace = false;
        let tooLittleSpace = false;

        if (corporaWithoutSetWidth > 0) {
          let remainderOfRemainingWidth =
            remainingWidth % corporaWithoutSetWidth;
          newCorpusWidth =
            (remainingWidth - remainderOfRemainingWidth) /
            corporaWithoutSetWidth;

          //If the newCorpusWidth is more or less then the constraints set the width to these constraints &
          // try to distribute accross columsn with customWidthSet
          if (
            newCorpusWidth > settings.constraints.dimensions.corpus.maxWidth
          ) {
            newCorpusWidth = settings.constraints.dimensions.corpus.maxWidth;

            //We have too much space!
            tooMuchSpace = true;
          } else if (
            newCorpusWidth < settings.constraints.dimensions.corpus.minWidth
          ) {
            newCorpusWidth = settings.constraints.dimensions.corpus.minWidth;

            //We have too little space!
            tooLittleSpace = true;
          }

          let remainderWasAdded = false;
          this.corpora.forEach((corpus, index) => {
            if (!corpus.customWidthSet) {
              if (!remainderWasAdded) {
                corpus.width = Math.round(
                  newCorpusWidth + remainderOfRemainingWidth
                );
                remainderWasAdded = true;
              } else {
                corpus.width = Math.round(newCorpusWidth);
              }
            }
          });
        } else {
          if (remainingWidth < 0) {
            tooLittleSpace = true;
          } else {
            tooMuchSpace = true;
          }
        }

        if (tooLittleSpace || tooMuchSpace) {
          let remainingWidthAfterDistributionOne =
            totalWidth -
            (corporaWithoutSetWidth * newCorpusWidth + otherCorporaWidthSum);
          let corporaThatCanStillAdjust = this.corpora.filter((corpus) => {
            return corpus.corpusId !== selectedCorpusId;
          });

          let i = corporaThatCanStillAdjust.length - 1;

          //Loop over the corpora that can still adjust in reverse (we want to first the most right corpora before moving up to the front!)
          while (remainingWidthAfterDistributionOne !== 0 && i >= 0) {
            let newWidth =
              corporaThatCanStillAdjust[i].width +
              remainingWidthAfterDistributionOne;
            if (
              newWidth >= settings.constraints.dimensions.corpus.minWidth &&
              newWidth <= settings.constraints.dimensions.corpus.maxWidth
            ) {
              corporaThatCanStillAdjust[i].width = newWidth;
              remainingWidthAfterDistributionOne = 0; //This should end the loop!
            } else if (
              newWidth < settings.constraints.dimensions.corpus.minWidth
            ) {
              //This should mean the remainder is negative!
              let diff =
                corporaThatCanStillAdjust[i].width -
                settings.constraints.dimensions.corpus.minWidth;
              corporaThatCanStillAdjust[i].width =
                settings.constraints.dimensions.corpus.minWidth;
              remainingWidthAfterDistributionOne =
                remainingWidthAfterDistributionOne + diff;
            } else if (
              newWidth > settings.constraints.dimensions.corpus.maxWidth
            ) {
              //This should mean the remainder is positive!
              let diff =
                settings.constraints.dimensions.corpus.maxWidth -
                corporaThatCanStillAdjust[i].width;
              corporaThatCanStillAdjust[i].width =
                settings.constraints.dimensions.corpus.maxWidth;
              remainingWidthAfterDistributionOne =
                remainingWidthAfterDistributionOne - diff;
            }

            i--; //We need to switch columns after each loop!
          }
        }
      }
    },
    adjustCorpusType(corpusId, typeId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      corpus.typeId = typeId;

      if (typeId === 1) {
        this.removeShelvesModule(corpusId);
        this.removeDrawersModule(corpusId);
        this.removeDoorsModule(corpusId);
        corpus.moduleType = "";
        corpus.clothesRail = false;
      }

      if (typeId === 2) {
        this.addShelvesModule(corpusId);
        this.removeDoorsModule(corpusId);
        this.removeDrawersModule(corpusId);

        corpus.moduleType = "MODULE_OPEN";
      }

      if (typeId === 3) {
        this.addDrawersModule(corpusId);
        this.removeShelvesModule(corpusId);
        this.removeDoorsModule(corpusId);

        corpus.moduleType = "MODULE_OPEN";
      }

      if (typeId === 4) {
        this.addShelvesModule(corpusId);
        this.addDoorsModule(corpusId);
        this.removeDrawersModule(corpusId);

        corpus.moduleType = "MODULE_1_DOOR";
      }

      this.onMeasurementsAdjusted();
    },
    addShelvesModule(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      if (
        !("shelves" in corpus.modules) ||
        typeof corpus.modules.shelves == "undefined"
      ) {
        corpus.modules["shelves"] = this.getShelvesMax(corpusId);
      }
    },
    removeShelvesModule(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      corpus.clothesRail = false;
      delete corpus.modules.shelves;
    },
    setShelves(corpusId, value) {
      let min = 0;
      let max = this.getShelvesMax(corpusId);
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      if (corpus.clothesRail) {
        min = 1;
      }

      if (value < min) {
        value = min;
      }

      if (value > max) {
        value = max;
      }

      corpus.modules.shelves = value;
    },
    addShelve(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      corpus.modules.shelves++;
    },
    removeShelve(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      corpus.modules.shelves--;
    },
    addDrawersModule(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      if (!("drawers" in corpus.modules)) {
        corpus.modules["drawers"] = {
          equal: true,
          softclose: false,
          objs: [
            {
              height: 10,
              customSpaceSet: false,
            },
          ],
        };
      }

      this.adjustModuleSpaces(corpus.corpusId);
    },
    removeDrawersModule(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      delete corpus.modules.drawers;
    },
    addDrawer(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      corpus.modules.drawers.objs.push({
        height: 10,
        customSpaceSet: false,
      });

      this.adjustModuleSpaces(corpusId);
    },
    removeDrawer(corpusId, position) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      if (typeof position === "undefined") {
        corpus.modules.drawers.objs.pop();
      } else {
        corpus.modules.drawers.objs = corpus.modules.drawers.objs.splice(
          position,
          1
        );
      }

      if (corpus.modules.drawers.length === 1) {
        corpus.modules.drawers.equal = true;
      }

      this.adjustModuleSpaces(corpusId);
    },
    addDoorsModule(corpusId) {
      const settings = useSettingsStore();
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      if (!("doors" in corpus.modules)) {
        corpus.modules["doors"] = {
          equal: true,
          softclose: false,
          objs: [
            {
              width: 30,
              customSpaceSet: false,
              direction: "left",
            },
          ],
        };

        this.adjustModuleSpaces(corpus.corpusId);
      }
    },
    removeDoorsModule(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      delete corpus.modules.doors;
    },
    addDoor(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);
      corpus.modules.doors.objs.push({
        width: 30,
        customSpaceSet: false,
        direction: "left",
      });

      if (corpus.modules.doors.objs.length === 1) {
        corpus.modules.doors.equal = true;
      }

      if (corpus.modules.doors.objs.length > 1) {
        corpus.modules.doors.objs[0].direction = "left";
        corpus.modules.doors.objs[1].direction = "right";
      }

      this.adjustModuleSpaces(corpusId);
    },
    removeDoor(corpusId) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      if (typeof position === "undefined") {
        corpus.modules.doors.objs.pop();
      } else {
        corpus.modules.doors.objs = corpus.modules.doors.objs.splice(
          position,
          1
        );
      }

      if (corpus.modules.doors.length === 1) {
        corpus.modules.doors.equal = true;
      }

      this.adjustModuleSpaces(corpusId);
    },
    addCorpus() {
      const settings = useSettingsStore();

      let defaultCorpus = cloneDeep(settings.defaults.corpus);

      defaultCorpus.corpusId = uuid.v4();
      defaultCorpus.width = settings.constraints.dimensions.corpus.minWidth;

      //Adjust/add in the devaitingHeight
      defaultCorpus.height = this.measurements.height;

      let lastCorpus = this.corpora[this.corpora.length - 1];

      //Check the last added corpus for a backpanel & copy that setting
      if (lastCorpus) {
        //Check if last corpora had a right-tablet > we need to remove it!
        if (lastCorpus.tablets.right) {
          lastCorpus.tablets.right = false;
        }

        defaultCorpus.intFinish = lastCorpus.intFinish;
        defaultCorpus.extFinish = lastCorpus.extFinish;
        defaultCorpus.tabletsFinish = lastCorpus.tabletsFinish;
        defaultCorpus.tabletsMaterial = lastCorpus.tabletsMaterial;
        defaultCorpus.toeKickFinish = lastCorpus.toeKickFinish;

        defaultCorpus.backPanel =
          this.corpora[this.corpora.length - 1].backPanel;
      }

      this.corpora.push(defaultCorpus);
      this.adjustCorpusWidths();

      //trigger next functions on adding corpus of certain type!
      this.adjustCorpusType(defaultCorpus.corpusId, defaultCorpus.typeId);
    },
    removeCorpus(position) {
      if (typeof position === "undefined") {
        this.corpora.pop();
      } else {
        this.corpora = this.corpora.splice(position, 1);
      }

      this.adjustCorpusWidths();
    },
    addAccessory(corpusId, accessoryId, count, type) {
      let corpus = this.corpora.find((item) => {
        return item.corpusId === corpusId;
      });

      //If we can find the accessory, just update
      let accObjIndex = corpus.accessories.findIndex(
        (accessory) => accessory.id === accessoryId
      );

      if (accObjIndex < 0) {
        let accObj = {
          id: accessoryId,
          count: count,
          type: type,
        };

        corpus.accessories.push(accObj);
      } else {
        corpus.accessories[accObjIndex].count = count;
      }
    },
    //Each accessory type for now should only be added once!
    updateAccessory(corpusId, accessoryId, type, count) {
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      accessoryId = accessoryId ? accessoryId : false;
      type = type ? type : false;

      if (!accessoryId && !type) {
        console.error(
          "We cant remove a accessory if we dont know the type or id!"
        );
        return;
      }

      if (count === 0) {
        this.removeAccessory(corpusId, accessoryId, count);
      }

      let accObj = undefined;

      if (type) {
        accObj = corpus.accessories.find(
          (accessory) => accessory.type === type
        );
      } else if (accessoryId) {
        accObj = corpus.accessories.find(
          (accessory) => accessory.id === accessoryId
        );
      }

      if (!accObj) {
        if (accessoryId && type) {
          this.addAccessory(corpusId, accessoryId, count, type);
        } else {
          console.error("We need both id & type to be able to add it!");
        }
      } else {
        if (accessoryId && accObj.id !== accessoryId) {
          accObj.id = accessoryId;
        }

        if (accObj.count !== count) {
          accObj.count = count;
        }
      }
    },
    removeAccessory(corpusId, accessoryId, type) {
      //accessoryId & type are optional, but one of the 2 need to be provided! if type is provided, alle accessories of that type will be removed! in case of id, only the accessory with that id will be removed
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      accessoryId = accessoryId ? accessoryId : false;
      type = type ? type : false;

      if (!accessoryId && !type) {
        console.error(
          "We cant remove a accessory if we dont know the type or id!"
        );
        return;
      }

      if (accessoryId) {
        let accObjIndex = corpus.accessories.findIndex(
          (accessory) => accessory.id === accessoryId
        );

        if (accObjIndex > -1) {
          corpus.accessories.splice(accObjIndex, 1);
        }
      } else {
        corpus.accessories = corpus.accessories.filter(
          (acc) => acc.type !== type
        );
      }
    },
    adjustHandles() {
      this.corpora.forEach((corpus) => {
        if (corpus.typeId > 2) {
          let count = false;

          //Update the handle
          if (
            "drawers" in corpus.modules &&
            typeof corpus.modules.drawers !== "undefined"
          ) {
            //We have drawers
            count = corpus.modules.drawers.objs.length;
          } else if (
            "doors" in corpus.modules &&
            typeof corpus.modules.doors !== "undefined"
          ) {
            //We have doors
            count = corpus.modules.doors.objs.length;
          }

          if (count !== false) {
            this.updateAccessory(corpus.corpusId, this.handle, "handle", count);
          }
        }
      });
    },
    adjustModuleSpaces(corpusId, selectedIndex) {
      const settings = useSettingsStore();
      let corpus = this.corpora.find((item) => item.corpusId === corpusId);

      if (corpus.typeId === 4) {
        if (corpus.modules.doors.objs.length === 1) {
          corpus.moduleType = "MODULE_1_DOOR";
        } else if (corpus.modules.doors.objs.length > 1) {
          corpus.moduleType = "MODULE_2_DOORS";
        }
      } else if (corpus.typeId > 0) {
        corpus.moduleType = "MODULE_OPEN";
      }

      //Check drawers
      if ("drawers" in corpus.modules) {
        while (
          corpus.modules.drawers.objs.length <
          settings.getMinNrOfDrawers(corpus.corpusId)
        ) {
          this.addDrawer(corpus.corpusId);
        }

        while (
          corpus.modules.drawers.objs.length >
          settings.getMaxNrOfDrawers(corpus.corpusId)
        ) {
          this.removeDrawer(corpus.corpusId);
        }
      }

      //Check doors
      if ("doors" in corpus.modules) {
        while (
          corpus.modules.doors.objs.length <
          settings.getMinNrOfDoors(corpus.corpusId)
        ) {
          this.addDoor(corpus.corpusId);
        }

        while (
          corpus.modules.doors.objs.length >
          settings.getMaxNrOfDoors(corpus.corpusId)
        ) {
          this.removeDoor(corpus.corpusId);
        }
      }

      for (let type in corpus.modules) {
        let module = corpus.modules[type];
        let moduleSpaceRemainder = 0;

        if (type === "shelves") continue;

        if (module.equal) {
          //Update all module-objs width
          let moduleSpace = null;

          if (type === "drawers" || type === "shelves") {
            moduleSpaceRemainder =
              (corpus.height - this.toeKick.height) % module.objs.length;

            moduleSpace =
              (corpus.height - this.toeKick.height - moduleSpaceRemainder) /
              module.objs.length;
          } else {
            moduleSpaceRemainder = corpus.width % module.objs.length;
            moduleSpace =
              (corpus.width - moduleSpaceRemainder) / module.objs.length;
          }

          //Set new width to others!
          module.objs.forEach((obj, index) => {
            if (type === "drawers" || type === "shelves") {
              if (index === module.objs.length - 1) {
                obj.height = Math.round(moduleSpace + moduleSpaceRemainder);
              } else {
                obj.height = Math.round(moduleSpace);
              }
            } else {
              if (index === module.objs.length - 1) {
                obj.width = Math.round(moduleSpace + moduleSpaceRemainder);
              } else {
                obj.width = Math.round(moduleSpace);
              }
            }
          });
        } else {
          let otherItemsSpaceSum = 0;
          let itemsWithoutSetSpace = 0;
          let totalSpace =
            type === "drawers" || type === "shelves"
              ? corpus.height - this.toeKick.height
              : corpus.width;

          let minSpace = 0;
          let maxSpace = 0;

          switch (type) {
            case "drawers":
              minSpace =
                settings.constraints.dimensions.modules.drawers.minHeight;
              maxSpace =
                settings.constraints.dimensions.modules.drawers.maxHeight;
              break;
            case "shelves":
              minSpace =
                settings.constraints.dimensions.modules.shelves.minHeight;
              maxSpace =
                settings.constraints.dimensions.modules.shelves.maxHeight;
              break;
            case "doors":
              minSpace = settings.constraints.dimensions.modules.doors.minWidth;
              maxSpace = settings.constraints.dimensions.modules.doors.maxWidth;
              break;
          }

          otherItemsSpaceSum = module.objs.reduce((prev, current) => {
            if (current.customSpaceSet) {
              if (type === "drawers" || type === "shelves") {
                if (current.height) {
                  return parseFloat(prev) + parseFloat(current.height);
                } else {
                  console.error(
                    "Has a custom height set, but height is null or undefined!"
                  );
                }
              } else {
                if (current.width) {
                  return parseFloat(prev) + parseFloat(current.width);
                } else {
                  console.error(
                    "Has a custom width set, but width is null or undefined!"
                  );
                }
              }
            } else {
              itemsWithoutSetSpace++;
              return parseFloat(prev);
            }
          }, otherItemsSpaceSum);

          //Calculate remaining width to distribute evenly
          let remainingSpace = totalSpace - otherItemsSpaceSum;

          let newModuleSpaceRemainder = remainingSpace % itemsWithoutSetSpace;
          let newModuleSpace =
            (remainingSpace - newModuleSpaceRemainder) / itemsWithoutSetSpace;
          let tooMuchSpace = false;
          let tooLittleSpace = false;

          // If the newModuleSpace is more or less then the constraints set the width to these constraints &
          // try to distribute accross columsn with customWidthSet
          if (newModuleSpace > maxSpace) {
            newModuleSpace = maxSpace;

            //We have too much space!
            tooMuchSpace = true;
          } else if (newModuleSpace < minSpace) {
            newModuleSpace = minSpace;

            //We have too little space!
            tooLittleSpace = true;
          }

          //Distribute the space
          let remainderWasSet = false;
          module.objs.forEach((item) => {
            if (!item.customSpaceSet) {
              if (type === "drawers" || type === "shelves") {
                if (!remainderWasSet) {
                  item.height = Math.round(
                    newModuleSpace + newModuleSpaceRemainder
                  );
                  remainderWasSet = true;
                } else {
                  item.height = Math.round(newModuleSpace);
                }
              } else {
                if (!remainderWasSet) {
                  item.width = Math.round(
                    newModuleSpace + newModuleSpaceRemainder
                  );
                  remainderWasSet = true;
                } else {
                  item.width = Math.round(newModuleSpace);
                }
              }
            }
          });

          //And check what we can do with the rest!
          if (tooLittleSpace || tooMuchSpace) {
            let remainingSpaceAfterDistributionOne =
              totalSpace -
              (itemsWithoutSetSpace * newModuleSpace + otherItemsSpaceSum);
            let itemsThatCanStillAdjust = module.objs.filter((obj, index) => {
              return index !== selectedIndex;
            });

            let i = 0;

            //Loop over the corpora that can still adjust in reverse (we want to first the most right corpora before moving up to the front!)
            while (
              remainingSpaceAfterDistributionOne !== 0 &&
              i < itemsThatCanStillAdjust.length
            ) {
              let newSpace = 0;

              if (type === "drawers" || type === "shelves") {
                newSpace =
                  itemsThatCanStillAdjust[i].height +
                  remainingSpaceAfterDistributionOne;
              } else {
                newSpace =
                  itemsThatCanStillAdjust[i].width +
                  remainingSpaceAfterDistributionOne;
              }

              if (newSpace >= minSpace && newSpace <= maxSpace) {
                if (type === "drawers" || type === "shelves") {
                  itemsThatCanStillAdjust[i].height = newSpace;
                } else {
                  itemsThatCanStillAdjust[i].width = newSpace;
                }
                remainingSpaceAfterDistributionOne = 0; //This should end the loop!
              } else if (newSpace < minSpace) {
                //This should mean the remainder is negative!
                let diff = 0;
                if (type === "drawers" || type === "shelves") {
                  diff = itemsThatCanStillAdjust[i].height - minSpace;
                  itemsThatCanStillAdjust[i].height = minSpace;
                } else {
                  diff = itemsThatCanStillAdjust[i].width - minSpace;
                  itemsThatCanStillAdjust[i].width = minSpace;
                }
                remainingSpaceAfterDistributionOne =
                  remainingSpaceAfterDistributionOne + diff;
              } else if (newSpace > maxSpace) {
                //This should mean the remainder is positive!
                let diff = 0;

                if (type === "drawers" || type === "shelves") {
                  diff = maxSpace - itemsThatCanStillAdjust[i].height;
                  itemsThatCanStillAdjust[i].height = maxSpace;
                } else {
                  diff = maxSpace - itemsThatCanStillAdjust[i].width;
                  itemsThatCanStillAdjust[i].width = maxSpace;
                }

                remainingSpaceAfterDistributionOne =
                  remainingSpaceAfterDistributionOne - diff;
              }

              i++; //We need to switch columns after each loop!
            }
          }
        }
      }
    },
  },
});
