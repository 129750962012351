<template>
  <div ref="card" class="card coporaMaterial-card">
    <div
      class="card-header collapsed"
      ref="collapseHeaderElModule"
      @click.prevent="toggleCard('MaterialModules')"
    >
      {{ $t("materials.label") }}
    </div>
    <div ref="collapseElModule" id="corporaMaterials" class="collapse">
      <div class="card-body">
        <label class="form-label form-label--slider">
          {{ $t("materials.sameForAllCorpora") }}
          <input
            type="checkbox"
            v-model="sameMaterialForAllCorpora"
            name="same-material-closet"
            :disabled="settingsStore.readOnly"
          />
          <span class="slider"></span>
        </label>
        <div v-if="!sameMaterialForAllCorpora">
          <div class="d-flex p-3">
            <div
              class="corpusWidthBox text-center d-flex align-items-center"
              :class="{ selected: selectedCorpus == item }"
              @click.prevent="setSelectedCorpus(item.corpusId)"
              v-for="(item, index) in corpora"
              :key="item.corpusId"
              :id="item.corpusId"
            >
              <span
                >{{ (parseFloat(item.width) * unitScale).toFixed(0) }}
                {{ unit }}</span
              >
            </div>
          </div>
        </div>

        <fieldset class="c-materials">
          <legend>{{ $t("materials.labelVeneer") }}</legend>
          <div class="c-materials__wrapper">
            <label v-for="color in getFinishes.wood" :key="color.id">
              <div
                class="sample-checkbox-wrapper"
                :class="{
                  'sample-checkbox-wrapper--checked':
                    color.id === selectedColor,
                  'sample-checkbox-wrapper--disabled': settingsStore.readOnly,
                }"
              >
                <input
                  type="radio"
                  :value="color.id"
                  name="closetColor"
                  @change="
                    onAdjustClosetMaterial(
                      color.id,
                      color.materialName,
                      color.color
                    )
                  "
                  :checked="color.id === selectedColor"
                  :disabled="settingsStore.readOnly"
                />
                <div class="sample-checkbox-btn">
                  <img
                    :src="getImgUrl(color.thumbnail, 96, 96)"
                    :alt="color.label"
                  />
                  <span class="sample-text">
                    {{ color.label }}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </fieldset>
        <fieldset class="c-materials">
          <legend>{{ $t("materials.labelColors") }}</legend>
          <div class="c-materials__wrapper">
            <label v-for="color in getFinishes.ral" :key="color.id">
              <div
                class="sample-checkbox-wrapper"
                :class="{
                  'sample-checkbox-wrapper--checked':
                    color.id === selectedColor,
                  'sample-checkbox-wrapper--disabled': settingsStore.readOnly,
                }"
              >
                <input
                  type="radio"
                  :value="color.id"
                  name="closetColor"
                  @change="
                    onAdjustClosetMaterial(
                      color.id,
                      color.materialName,
                      color.color
                    )
                  "
                  :checked="color.id === selectedColor"
                  :disabled="settingsStore.readOnly"
                />
                <div class="sample-checkbox-btn">
                  <img
                    :src="getImgUrl(color.thumbnail, 96, 96)"
                    :alt="color.label"
                  />
                  <span class="sample-text">
                    {{ color.label }}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </fieldset>
      </div>
      <div class="card-bottom d-flex justify-content-between w-100">
        <button class="btn btn-link" type="#" @click="gotoStep('corpora', -1)">
          {{ $t("general.previous") }} {{ $t("general.step") }}
        </button>
        <button
          class="btn btn-outline"
          type="#"
          @click="open('MaterialTablets')"
        >
          {{ $t("general.next") }}: {{ $t("materials.label") }}
        </button>
      </div>
    </div>
  </div>

  <div v-if="closetHasTablets" ref="card" class="card tabletMaterial-card">
    <div
      class="card-header collapsed"
      ref="collapseHeaderElTablet"
      @click.prevent="toggleCard('MaterialTablets')"
    >
      {{ $t("tablets.label") }}
    </div>
    <div ref="collapseElTablet" id="tabletMaterials" class="collapse">
      <div class="card-body">
        <div v-if="tabletMitersAllowed">
          <label class="form-label form-label--slider">
            {{ $t("materials.includeMiters") }}
            <input
              type="checkbox"
              v-model="tabletMiters"
              name="includeTabletMiters"
              :disabled="settingsStore.readOnly"
            />
            <span class="slider"></span>
          </label>
        </div>
        <label class="form-label form-label--slider">
          {{ $t("materials.sameForAllCorpora") }}
          <input
            type="checkbox"
            v-model="sameTabletMaterialForAllCorpora"
            name="same-material-closet"
            :disabled="settingsStore.readOnly"
          />
          <span class="slider"></span>
        </label>
        <div v-if="!sameTabletMaterialForAllCorpora">
          <div class="d-flex p-3">
            <div
              class="corpusWidthBox text-center d-flex align-items-center"
              :class="{ selected: selectedTabletCorpus == item }"
              @click.prevent="setSelectedTabletCorpus(item.corpusId)"
              v-for="(item, index) in corpora"
              :key="item.corpusId"
              :id="item.corpusId"
            >
              <span
                >{{ (parseFloat(item.width) * unitScale).toFixed(0) }}
                {{ unit }}</span
              >
            </div>
          </div>
        </div>

        <fieldset class="c-materials">
          <legend>{{ $t("materials.labelVeneer") }}</legend>
          <div class="c-materials__wrapper">
            <label v-for="color in getFinishes.wood" :key="color.id">
              <div
                class="sample-checkbox-wrapper"
                :class="{
                  'sample-checkbox-wrapper--checked':
                    color.id === selectedTabletColor,
                  'sample-checkbox-wrapper--disabled': settingsStore.readOnly,
                }"
              >
                <input
                  type="radio"
                  :value="color.id"
                  name="tabletColor"
                  @change="
                    onAdjustTabletMaterial(
                      color.id,
                      color.materialName,
                      color.color
                    )
                  "
                  :disabled="settingsStore.readOnly"
                  :checked="color.id === selectedTabletColor"
                />
                <div class="sample-checkbox-btn">
                  <img
                    :src="getImgUrl(color.thumbnail, 96, 96)"
                    :alt="color.label"
                  />
                  <span class="sample-text">
                    {{ color.label }}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </fieldset>
        <fieldset class="c-materials">
          <legend>{{ $t("materials.labelColors") }}</legend>
          <div class="c-materials__wrapper">
            <label v-for="color in getFinishes.ral" :key="color.id">
              <div
                class="sample-checkbox-wrapper"
                :class="{
                  'sample-checkbox-wrapper--checked':
                    color.id === selectedTabletColor,
                  'sample-checkbox-wrapper--disabled': settingsStore.readOnly,
                }"
              >
                <input
                  type="radio"
                  :value="color.id"
                  name="tabletColor"
                  @change="
                    onAdjustTabletMaterial(
                      color.id,
                      color.materialName,
                      color.color
                    )
                  "
                  :checked="color.id === selectedTabletColor"
                  :disabled="settingsStore.readOnly"
                />
                <div class="sample-checkbox-btn">
                  <img
                    :src="getImgUrl(color.thumbnail, 96, 96)"
                    :alt="color.label"
                  />
                  <span class="sample-text">
                    {{ color.label }}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </fieldset>
      </div>
      <div class="card-bottom d-flex justify-content-between w-100">
        <button
          class="btn btn-link"
          type="#"
          @click="
            () => {
              if (closetHasTablets) {
                open('MaterialModules');
              } else {
                gotoStep('corpora', -1);
              }
            }
          "
        >
          {{ $t("general.previous") }} {{ $t("general.step") }}
        </button>
        <button
          v-if="showHandles"
          class="btn btn-outline"
          type="#"
          @click="gotoStep('accessories')"
        >
          {{ $t("general.next") }}: {{ $t("accessories.label") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onUpdated, onMounted } from "vue";
import { storeToRefs } from "pinia";
import BootstrapCardWrapper from "../helpers/BootstrapCardWrapper";
import { getImgUrl } from "../helpers/ImagePath";
import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";

const configurationStore = useConfigurationStore();
const settingsStore = useSettingsStore();

const collapseElModule = ref(null);
const collapseHeaderElModule = ref(null);
const collapseElTablet = ref(null);
const collapseHeaderElTablet = ref(null);

const emit = defineEmits(["gotoStep"]);
const gotoStep = (panel, dir) => {
  emit("gotoStep", panel, dir);
};

const { getFinishes, unit, unitScale, collapsibles } =
  storeToRefs(settingsStore);
const { sameMaterialForAllCorpora, corpora, tablets, toeKick, tabletMiters } =
  storeToRefs(configurationStore);

const sameTabletMaterialForAllCorpora = ref(true);

const selectedCorpusId = ref(false);
const selectedCorpus = ref(false);
const selectedTabletCorpusId = ref(false);
const selectedTabletCorpus = ref(false);
const closetHasTablets = computed(() => {
  return tablets.value.length > 0;
});

const selectedTabletColor = computed(() => {
  if (
    selectedTabletCorpusId.value !== false &&
    !sameTabletMaterialForAllCorpora
  ) {
    return selectedTabletCorpus.value.tabletsFinish;
  } else {
    return configurationStore.corpora[0].tabletsFinish;
  }
});

const selectedColor = computed(() => {
  if (selectedCorpusId.value !== false) {
    return selectedCorpus.value.extFinish;
  } else {
    return configurationStore.corpora[0].extFinish;
  }
});

const showHandles = computed(() => {
  let show = false;

  configurationStore.corpora.forEach((corpus) => {
    if (corpus.typeId > 2) {
      show = true;
    }
  });

  return show;
});

const tabletMitersAllowed = computed(() => {
  let allowMiters = true;
  configurationStore.corpora.forEach((corpus, index) => {
    if (index == 0 || index == configurationStore.corpora.length - 1) {
      if (index == 0) {
        //First corpus
        if (!corpus.tablets.top || !corpus.tablets.left) {
          allowMiters = false;
        } else {
          if (configurationStore.type === "hanging" && !corpus.tablets.bottom) {
            allowMiters = false;
          }
        }
      }

      if (index == configurationStore.corpora.length - 1) {
        //last corpus
        if (!corpus.tablets.top || !corpus.tablets.right) {
          allowMiters = false;
        } else {
          if (configurationStore.type === "hanging" && !corpus.tablets.bottom) {
            allowMiters = false;
          }
        }
      }
    } else {
      if (!corpus.tablets.top) {
        allowMiters = false;
      } else {
        if (configurationStore.type === "hanging" && !corpus.tablets.bottom) {
          allowMiters = false;
        }
      }
    }
  });

  return allowMiters;
});

watch(tabletMitersAllowed, (newValue) => {
  if (newValue == false) {
    tabletMiters.value = false;
  }

  configurationStore.calculateTablets();
  configurationStore.calculateToekick();
});

watch(tabletMiters, (newValue) => {
  configurationStore.calculateTablets();
  configurationStore.calculateToekick();
});

watch(closetHasTablets, function (newValue) {
  if (newValue === false) {
    configurationStore.corpora.forEach((corpus) => {
      //Reset tablets/toekick to corpus materials as the tablets panel will be hidden!
      corpus.toeKickFinish = corpus.extFinish;
      corpus.tabletsFinish = corpus.extFinish;
    });

    configurationStore.calculateTablets();
    configurationStore.calculateToekick();
  }
});

watch(sameTabletMaterialForAllCorpora, function (newValue) {
  if (newValue === true) {
    selectedTabletCorpusId.value = false;
    selectedTabletCorpus.value = false;
    onAdjustTabletMaterial(configurationStore.corpora[0].tabletsFinish);
  } else {
    selectedTabletCorpusId.value = configurationStore.corpora[0].corpusId;
    selectedTabletCorpus.value = configurationStore.corpora[0];
  }
});

watch(sameMaterialForAllCorpora, function (newValue) {
  if (newValue === true) {
    selectedCorpusId.value = false;
    selectedCorpus.value = false;
    onAdjustClosetMaterial(configurationStore.corpora[0].extFinish);
  } else {
    selectedCorpusId.value = configurationStore.corpora[0].corpusId;
    selectedCorpus.value = configurationStore.corpora[0];
  }
});

const setSelectedCorpus = (corpusId) => {
  selectedCorpusId.value = corpusId;
  selectedCorpus.value = corpora.value.find(
    (corpus) => corpus.corpusId == corpusId
  );
};

const setSelectedTabletCorpus = (corpusId) => {
  selectedTabletCorpusId.value = corpusId;
  selectedTabletCorpus.value = corpora.value.find(
    (corpus) => corpus.corpusId == corpusId
  );
};

const onAdjustTabletMaterial = (id, materialName, color) => {
  configurationStore.corpora.forEach((corpus) => {
    if (
      selectedTabletCorpusId.value === false ||
      corpus.corpusId === selectedTabletCorpusId.value
    ) {
      corpus.tabletsFinish = id;
      corpus.toeKickFinish = id;
    }
  });

  configurationStore.calculateTablets();
  configurationStore.calculateToekick();
};

const onAdjustClosetMaterial = (id, materialName, color) => {
  configurationStore.corpora.forEach((corpus) => {
    if (
      selectedCorpusId.value === false ||
      corpus.corpusId === selectedCorpusId.value
    ) {
      corpus.extFinish = id;
      corpus.intFinish = id;

      if (closetHasTablets.value === false) {
        //Set toekick material equal to ext-finish!
        corpus.toeKickFinish = id;
        corpus.tabletsFinish = id;
      }
    }
  });

  configurationStore.calculateTablets();
  configurationStore.calculateToekick();
};

const setupCollapse = () => {
  collapsibles.MaterialModules = new BootstrapCardWrapper(
    collapseHeaderElModule.value,
    collapseElModule.value
  );

  if (collapseElTablet.value !== null) {
    collapsibles.MaterialTablets = new BootstrapCardWrapper(
      collapseHeaderElTablet.value,
      collapseElTablet.value
    );
  }
};

onMounted(setupCollapse);
onUpdated(setupCollapse);

const open = (card) => {
  if (!card) {
    card = "MaterialModules";

    // if (closetHasTablets.value) {
    //   card = "MaterialTablets";
    // }
  }

  if (collapsibles[card]) {
    collapsibles[card].bootstrapObj.show();
  }
};

const close = (card) => {
  if (!card) {
    card = "MaterialModules";
  }

  if (collapsibles[card]) {
    collapsibles[card].bootstrapObj.hide();
  }
};

const toggleCard = (card) => {
  if (!card) {
    if (closetHasTablets.value) {
      card = "MaterialTablets";
    } else {
      card = "MaterialModules";
    }
  }

  if (collapsibles[card]) {
    if (collapsibles[card].bootstrapObj._isShown()) {
      close(card);
    } else {
      open(card);
    }
  }
};

defineExpose({
  open,
  close,
  toggleCard,
});
</script>
