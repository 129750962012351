<template>
  <div v-if="showHandles" ref="card" class="card accessories-card">
    <div
      class="card-header collapsed"
      ref="collapseHeaderEl"
      @click.prevent="toggleCard"
    >
      {{ $t("accessories.label") }}
    </div>
    <div ref="collapseEl" id="accessories" class="collapse">
      <div class="card-body card-body mb-4">
        <div class="accessory-checkbox__wrapper">
          <label
            v-for="handle in getHandles"
            :key="handle.id"
            class="accessory-checkbox-btn size"
            :class="{
              'accessory-checkbox-btn--disabled': settingsStore.readOnly,
              'accessory-checkbox-btn--checked': handle.id === activeHandleId,
            }"
          >
            <input
              type="radio"
              :value="handle.id"
              name="handle"
              @change="onHandleChange(handle.id)"
              :checked="handle.id === activeHandleId"
              :disabled="settingsStore.readOnly"
            />
            <div v-if="handle.accessoryImages[0]" class="img-container">
              <img
                :src="getImgUrl(handle.accessoryImages[0], 250, 200, 'crop')"
                :alt="handle.name"
              />
            </div>
            <span class="sample-text">
              {{ handle.name }}
            </span>
          </label>
        </div>
      </div>
      <div class="card-bottom d-flex justify-content-between w-100">
        <button
          class="btn btn-link"
          type="#"
          @click="onGotoClicked('materials', -1)"
        >
          {{ $t("general.previous") }} {{ $t("general.step") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUpdated } from "vue";
import BootstrapCardWrapper from "../helpers/BootstrapCardWrapper";
import { storeToRefs } from "pinia";
import { getImgUrl } from "../helpers/ImagePath";
import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";

const configurationStore = useConfigurationStore();
const settingsStore = useSettingsStore();
const emit = defineEmits(["gotoStep"]);

const onGotoClicked = (panel, dir) => {
  emit("gotoStep", panel, dir);
};

const { getHandles, collapsibles } = storeToRefs(settingsStore);
const collapseEl = ref(null);
const collapseHeaderEl = ref(null);

configurationStore.handle = getHandles.value[0].id;

const activeHandleId = computed(() => {
  let id = false;

  configurationStore.corpora.forEach((corpus) => {
    if (corpus.typeId > 2) {
      let handle = corpus.accessories.filter(
        (accessory) => accessory.type === "handle"
      );

      id = handle[0].id;
    }
  });

  return id;
});

const showHandles = computed(() => {
  let show = false;

  configurationStore.corpora.forEach((corpus) => {
    if (corpus.typeId > 2) {
      show = true;
    }
  });

  return show;
});

const onHandleChange = (id) => {
  configurationStore.handle = id;
  configurationStore.adjustHandles();
};

const setupCollapse = () => {
  if (collapseEl.value !== null) {
    collapsibles.accessories = new BootstrapCardWrapper(
      collapseHeaderEl.value,
      collapseEl.value
    );
  }
};

onMounted(setupCollapse);
onUpdated(setupCollapse);

const open = () => {
  if (collapsibles.accessories.bootstrapObj) {
    collapsibles.accessories.bootstrapObj.show();
  }
};

const close = () => {
  if (collapsibles.accessories.bootstrapObj) {
    collapsibles.accessories.bootstrapObj.hide();
  }
};

const toggleCard = () => {
  if (collapsibles.accessories.bootstrapObj) {
    if (collapsibles.accessories.bootstrapObj._isShown()) {
      close();
    } else {
      open();
    }
  }
};

defineExpose({
  open,
  close,
  toggleCard,
});
</script>
