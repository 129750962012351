<template>
  <aside id="sidebarControls">
    <ConfigurationType ref="type" @gotoStep="onGotoStep"></ConfigurationType>

    <Measurements ref="measurements" @gotoStep="onGotoStep"></Measurements>

    <Layout ref="layouts" @gotoStep="onGotoStep"></Layout>

    <Corpora ref="corpora" @gotoStep="onGotoStep"></Corpora>

    <Materials ref="materials" @gotoStep="onGotoStep"></Materials>

    <Accessories ref="accessories" @gotoStep="onGotoStep"></Accessories>

    <Summary @order="onOrder"></Summary>
  </aside>
</template>

<script setup>
import { ref, defineEmits, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import ConfigurationType from "./ConfigurationType.vue";
import Measurements from "./Measurements.vue";
import Layout from "./Layout.vue";
import Corpora from "./Corpora.vue";
import Materials from "./Materials.vue";
import Accessories from "./Accessories.vue";
import Summary from "./Summary.vue";
import { Collapse } from "bootstrap";

import { useConfigurationStore } from "../stores/configuration";

const configurationStore = useConfigurationStore();

const type = ref(null);
const measurements = ref(null);
const layouts = ref(null);
const corpora = ref(null);
const materials = ref(null);
const accessories = ref(null);

const { tablets } = storeToRefs(configurationStore);

const closetHasTablets = computed(() => {
  return tablets.value.length > 0;
});

const emit = defineEmits(["order"]);
const onOrder = () => {
  emit("order");
};

const onGotoStep = (step, dir = 1) => {
  switch (step) {
    case "type":
      type.value.open();
      break;

    case "measurements":
      measurements.value.open();
      break;

    case "layout":
      layouts.value.open();
      break;

    case "corpora":
      if (dir <= -1) {
        corpora.value.corporaRefs[corpora.value.corporaRefs.length - 1].open();
      } else {
        corpora.value.corporaRefs[0].open();
      }
      break;

    case "materials":
      let card = null;
      if (dir == -1) {
        card = "MaterialTablets";
      }

      materials.value.open(card);
      break;

    case "accessories":
      accessories.value.open();
      break;

    default:
      break;
  }
};
</script>

<style lang="scss">
@import "../styles/components/components.sidebar";
</style>
