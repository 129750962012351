import {
  AxesHelper,
  Box3,
  Color,
  FogExp2,
  Group,
  PCFSoftShadowMap,
  PerspectiveCamera,
  PMREMGenerator,
  SpotLight,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";
import { RoomEnvironment } from './roomEnvironment';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js';
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { ColorCorrectionShader } from 'three/examples/jsm/shaders/ColorCorrectionShader.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';

import Stats from 'three/examples/jsm/libs/stats.module.js';

import OrbitControls from "./orbitControls";
import { debounce } from "lodash";
import Lighting from "./lighting";
import { Preview3D, State, Finish, controlResetSettings, screenShotOptions } from "./types";
import Lightroom from "./lightroom";
import Closet from "./closet";
import TextureLibrary from "./textureLibrary";

/**
 * Defining Types/Interfaces
 */
type ThreeSettings = {
  [key: string]: any
}

/**
 * Main Class
 */
export default class Main implements Preview3D {
  dev:boolean = false;
  imgReplacement: HTMLImageElement;
  height:number;
  width:number;
  baseMaterialThickness: number
  animating: boolean = false;
  floor;
  scene:Scene;
  renderer:WebGLRenderer;
  camera:PerspectiveCamera;
  cameraConstraint: Group;
  orbitControls: any;//External lib has no TS definition so we're using any here
  lighting: Lighting;
  closet: Closet;
  scale: number;
  doorsOpen: boolean = false;
  textureLibrary: TextureLibrary;
  state: State;
  composer: EffectComposer;
  renderPass: RenderPass;
  saoPass: SAOPass;
  fxaaPass: ShaderPass;
  effectColor;
  gammaCorrection;
  stats: Stats;
  pmremGenerator: PMREMGenerator;
  screenShotOptionsDefault: screenShotOptions;
  startCameraOrbitControlsSettings: controlResetSettings;
  animationTimeout: ReturnType<typeof setTimeout>;

  constructor(canvas:HTMLElement, imgReplacement:HTMLImageElement ,settings:ThreeSettings, finishes: Array<Finish>, state:State, development: boolean) {
    const self = this;

    this.dev = development;
    this.imgReplacement = imgReplacement;
    this.screenShotOptionsDefault = {
      fixedCameraPoint: true,
      width: 1920,
      height: 1080
    }

    this.scale = settings.scale;
    this.baseMaterialThickness = settings.baseMaterialThickness / settings.scale;

    let sidebarWidth = ((window.innerWidth * .35) > 720 ) ? 720 : Math.ceil(window.innerWidth * .35);
    this.width = window.innerWidth - sidebarWidth;
    this.height = window.innerHeight;

    this.state = state;

    //Declare the scene
    this.scene = new Scene();
    this.scene.fog = new FogExp2(settings.fog.color, settings.fog.near);
    this.scene.background = new Color('#dedede');

    //Let's create the "floor"
    this.floor = new Lightroom(this);

    //Declare/setup camera
    this.cameraConstraint = new Group();
    this.camera = new PerspectiveCamera(75, this.width / this.height);
    this.cameraConstraint.add( this.camera );
    this.scene.add(this.cameraConstraint);

    //Updating camera position:
    this.camera.position.z = 2.5;
    this.camera.position.y = 0;
    this.camera.position.x = -1;

    // Renderer
    this.renderer = new WebGLRenderer({
      canvas: canvas,
      precision: 'lowp',
      antialias: true
    });
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize(this.width, this.height);
    // this.renderer.shadowMap.enabled = true;
    // this.renderer.shadowMap.type = PCFSoftShadowMap;

    this.pmremGenerator = new PMREMGenerator( this.renderer );
    this.scene.environment = this.pmremGenerator.fromScene( new RoomEnvironment(), 0.04 ).texture;

    //Post processing
    this.composer = new EffectComposer( this.renderer );

    this.renderPass = new RenderPass( this.scene, this.camera ); 
    this.composer.addPass( this.renderPass );

    this.effectColor = new ShaderPass( ColorCorrectionShader );
    this.gammaCorrection = new ShaderPass( GammaCorrectionShader );

    this.effectColor.uniforms[ 'powRGB' ].value.set( 1.4, 1.45, 1.45 );
    this.effectColor.uniforms[ 'mulRGB' ].value.set( 1.1, 1.1, 1.1 );

    this.fxaaPass = new ShaderPass( FXAAShader );
    this.fxaaPass.needsSwap = true;

    this.fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( this.width * window.devicePixelRatio );
    this.fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( this.height * window.devicePixelRatio );
    this.composer.addPass(this.fxaaPass);

    this.composer.addPass( this.effectColor );
    this.composer.addPass( this.gammaCorrection );

    this.saoPass = new SAOPass(this.scene, this.camera, false, true);
    this.saoPass.setSize(this.width, this.height);
    this.saoPass.renderToScreen = true;
    this.saoPass.params.saoBias = 0.5;
    this.saoPass.params.saoIntensity = 0.005;
    this.saoPass.params.saoScale = 72;
    this.saoPass.params.saoKernelRadius = 65;
    this.saoPass.params.saoBlurRadius = 1;
    this.saoPass.params.saoBlurStdDev = 6.7;
    this.saoPass.params.saoBlurDepthCutoff = 0.01;
    this.composer.addPass(this.saoPass);
  

    // Adding lighting
    // this.lighting = new Lighting( this, settings.lighting);

    //Setup orbit controls
    this.orbitControls = new OrbitControls( this.camera, canvas);
    this.orbitControls.target.set( 0, .8, .2 );
    // this.orbitControls.enableRotate = false;
    this.orbitControls.maxPolarAngle = (Math.PI / 2) - .05;
    this.orbitControls.addEventListener( 'change', function(){ self.render.call(self)});
    this.orbitControls.update();

    this.startCameraOrbitControlsSettings = {
      orbitControls: {
        maxDistance: this.orbitControls.maxDistance,
        target: this.orbitControls.target.clone()
      },
      camera: {
        near: this.camera.near,
        far: this.camera.far,
        position: this.camera.position.clone(),
        rotation: this.camera.rotation.clone()
      }
    }

    //Add axis helpers
    const axesHelper = new AxesHelper( 5 );
    // this.scene.add( axesHelper );

    window.addEventListener("resize", debounce(() => this.onWindowResize.call(this), 250));

    //Load all textures
    this.textureLibrary = new TextureLibrary(finishes, () => {
      this.onAllTexturesLoaded.call(this);
    });
  }

  onAllTexturesLoaded() {
    this.closet = new Closet(this, this.state, this.doorsOpen);

    this.animate();
  }

  toggleDoors() {
    clearTimeout( this.animationTimeout );
    this.doorsOpen = !this.doorsOpen;

    this.animating = true;
    this.animate();

    this.closet.toggleDoors(this.doorsOpen);

    this.animationTimeout = setTimeout(() => {
      this.animating = false;
    }, 2000);
  }

  //onWindowResize
  onWindowResize() {
    let sidebarWidth = ((window.innerWidth * .35) > 720 ) ? 720 : Math.ceil(window.innerWidth * .35);
    this.width = window.innerWidth - sidebarWidth;
    this.height = window.innerHeight;

    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    
    this.render();
  }

  //Only trigger this when things have changed .... 
  animate() {
    // requestAnimationFrame(  );
    if( this.animating ) {
      requestAnimationFrame( () => {
        this.animate();
      });
    }
    
    // this.stats.begin();
    this.render();
    // this.stats.end();
  }

  render() {
    this.composer.render();
  }

  update(state:State) {
    this.state = state;

    if( this.closet ) {
      this.closet.update(this.state, this.doorsOpen);
    }
  }

  //This funciton will reset camera & orbit controls like they were before taking screenshot!
  resetCameraToOriginalPosition(settings:controlResetSettings) {
    settings = (settings) ? settings : this.startCameraOrbitControlsSettings;

    this.camera.position.set(settings.camera.position.x, settings.camera.position.y, settings.camera.position.z);
    this.camera.rotation.set(settings.camera.rotation.x, settings.camera.rotation.y, settings.camera.rotation.z);

    this.orbitControls.target.set(settings.orbitControls.target.x, settings.orbitControls.target.y, settings.orbitControls.target.z);
    this.orbitControls.update();
  }

  //This function will move the camera to an optimal position to have the object at the center of screen: ideal for screenshots!
  zoomCameraToSelection(fitRatio = 1.2) {
    const originalSettings:controlResetSettings = {
      orbitControls: {
        maxDistance: this.orbitControls.maxDistance,
        target: this.orbitControls.target.clone()
      },
      camera: {
        near: this.camera.near,
        far: this.camera.far,
        position: this.camera.position.clone(),
        rotation: this.camera.rotation.clone()
      }
    }

    //Set the base positions back!
    this.camera.position.z = 2.6;
    this.camera.position.y = 1.15;
    this.camera.position.x = -1.2;
    this.orbitControls.target.set( .75, .8, .2 );

    //Create a bounding box to center to!
    const box = new Box3();
    //Add the closet
    box.expandByObject(this.closet.group);
  
    //Get dimensions/center
    const size = box.getSize(new Vector3());
    const center = box.getCenter(new Vector3());
  
    //Calculate position,zoom,settings for orbit controls & camera
    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * this.camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / this.camera.aspect;
    const distance = fitRatio * Math.max(fitHeightDistance, fitWidthDistance);
  
    const direction = this.orbitControls.target
      .clone()
      .sub(this.camera.position)
      .normalize()
      .multiplyScalar(distance);
  
    //Apply new settings!
    this.orbitControls.maxDistance = distance * 10;
    this.orbitControls.target.copy(center);
  
    this.camera.near = distance / 100;
    this.camera.far = distance * 100;
    this.camera.updateProjectionMatrix();
  
    this.camera.position.copy(this.orbitControls.target).sub(direction);
  
    this.orbitControls.update();

    return originalSettings;
  }

  takeScreenShot(callBack:Function, options:screenShotOptions) {
    //todo: Show loader?
    const _self = this;
    options = {...this.screenShotOptionsDefault, ...options};

    //Replace the canvas with an image to hide activity!
    this.replaceViewWithStaticImg(() => {

      setTimeout(() => {

        //Create a fixed size for the screenshot!
        this.renderer.setSize(options.width, options.height);
        this.camera.aspect = options.width / options.height;
        this.camera.updateProjectionMatrix();
        this.render();
        let originalSettings:controlResetSettings;
  
        if( options.fixedCameraPoint ) {
          //Take the real screenshot!
          originalSettings = _self.zoomCameraToSelection();
        }

        //Grab screenshot!
        const screenShotPromise = new Promise((resolve, reject) => {
          //Because we dont have a render loop, we need to render just before taking a screenshot!
          _self.composer.render();

          // ctx.drawImage(base_image, 0, 0, 239, 25, 1920-250, 1080-45);
          
          //Grab a blob!
          /*
          _self.renderer.domElement.toBlob((data:any) => {
            if( data ) {
              resolve(data);
            } else {
              reject('Was not able to');
            }
          }, 'image/png', 1.0);
          */

          //base64
          resolve(_self.renderer.domElement.toDataURL('image/jpeg', 1.0));
        });

        //Reset everything as it was!
        screenShotPromise.then((data) => {
          setTimeout(() => {
            _self.onWindowResize.call(_self);
            if( options.fixedCameraPoint && originalSettings) {
              _self.resetCameraToOriginalPosition(originalSettings);
            }
            _self.imgReplacement.classList.remove('active');
          
            callBack(data);
          }, 100);
        }).catch((msg) => {
          console.error(msg);
        });
      }, 100);
    });
  }

  replaceViewWithStaticImg(callback:Function) {
    const scenePicPromise = new Promise((resolve, reject) => {
      //Because we dont have a render loop, we need to render just before taking a screenshot!
      this.composer.render();
      //Grab a blob!
      this.renderer.domElement.toBlob((data:any) => {
        if( data ) {
          resolve(data);
        } else {
          reject('Was not able to');
        }
      }, 'image/png', 1.0);
    });

    scenePicPromise.then((data:any) => {
      let imageUrl = URL.createObjectURL(data);
      this.imgReplacement.src = imageUrl;
      this.imgReplacement.classList.add('active');

      callback();
    }).catch((msg) => {
      console.error(msg);
    })
  }
}