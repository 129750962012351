<template>
  <div
    :class="{ show: show }"
    class="c-alert alert alert-secondary fade"
    role="alert"
    ref="alertEl"
  >
    <svg class="icon me-2">
      <use xlink:href="#icon-copy"></use>
    </svg>
    {{ $t(msg) }}
  </div>
</template>
<script setup>
import { defineEmits, ref } from "vue";
import { Alert } from "bootstrap";
let alertTimeout = null;

const show = ref(false);
const msg = ref("alert.share");

const showAlert = (newMsg) => {
  clearTimeout(alertTimeout);
  msg.value = newMsg;
  show.value = true;

  alertTimeout = setTimeout(hideAlert, 5000);
};

const hideAlert = () => {
  clearTimeout(alertTimeout);
  show.value = false;
};

defineExpose({
  showAlert,
  hideAlert,
});
</script>

<style scoped lang="scss">
@import "../styles/components/_components.alert.scss";
</style>
