import { textureMapFiles } from './types';
import { MirroredRepeatWrapping, RepeatWrapping, sRGBEncoding, TextureLoader, Texture } from 'three';

interface TextureInterface {
  diffuseMap: Texture
  normalMap: Texture
  specularMap: Texture
  onLoaded: Function
  mapsLoaded: { [key:string]: boolean }
}

export default class TextureMaps implements TextureInterface {
  diffuseMap: Texture;
  normalMap: Texture;
  specularMap: Texture;
  onLoaded: Function;
  mapsLoaded: {
    diffuse: boolean,
    normal: boolean,
    specular: boolean,
  };

  constructor( files: textureMapFiles, onLoaded:Function ) {
    let textureLoader = new TextureLoader();
    this.onLoaded = onLoaded;
    let _self = this;

    this.mapsLoaded = {
      diffuse: false,
      normal: false,
      specular: false
    }

    this.diffuseMap = textureLoader.load(files.diffuseMap, () => {
      this.diffuseMap.wrapS = RepeatWrapping;
      this.diffuseMap.wrapT = MirroredRepeatWrapping;
      this.diffuseMap.repeat.set( 4, 4 );
      // this.diffuseMap.rotation = Math.PI/2;
      this.diffuseMap.encoding = sRGBEncoding;

      _self.mapsLoaded.diffuse = true;
      _self.texturesLoaded();
    });

    this.normalMap = textureLoader.load(files.normalMap, () => {
      this.normalMap.wrapS = MirroredRepeatWrapping;
      this.normalMap.wrapT = MirroredRepeatWrapping;
      this.normalMap.repeat.set( 4, 4 );

      _self.mapsLoaded.normal = true;
      _self.texturesLoaded();
    });

    this.specularMap = textureLoader.load(files.specularMap, () => {
      this.specularMap.encoding = sRGBEncoding;
      this.specularMap.repeat.set( 4, 4 );
      this.specularMap.wrapS = MirroredRepeatWrapping;
      this.specularMap.wrapT = MirroredRepeatWrapping;

      _self.mapsLoaded.specular = true;
      _self.texturesLoaded();
    });
  }

  texturesLoaded() {
    if (Object.values(this.mapsLoaded).indexOf(false) <= -1) {
      this.onLoaded( this as TextureInterface );
    }
  }
}