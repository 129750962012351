interface AjaxRequestsInterface {
  pricePath:String
  savePath:String
  orderPath:String
};

type requestType = 'POST' | 'GET' | 'PUT' | 'DELETE';

type ajaxPaths = {
  price: string
  save: string
  order: string
} 

export default class AjaxRequests implements AjaxRequestsInterface {
  pricePath:string = '';
  savePath:string = '';
  orderPath:string = '';

  constructor(paths: ajaxPaths) {
    this.pricePath = paths.price;
    this.savePath = paths.save;
    this.orderPath = paths.order;
  }

  save(data: any, callBack: Function, callBackError?: Function) {
    let _self = this;

    callBackError = callBackError ? callBackError : (e:any) => { console.error('promise was rejected', e); };

    let promise = new Promise((resolve, reject) => {
      this.request('POST', _self.savePath, data, resolve, reject);
    });

    promise.then((data) => {
      callBack(data);
    }).catch((data) => {
      if( callBackError ) {
        callBackError(data);
      }
    });
  }

  getPrice(data: any, callBack: Function, callBackError?: Function) {
    let _self = this;

    callBackError = callBackError ? callBackError : (e:any) => { console.error('promise was rejected', e); };

    let promise = new Promise((resolve, reject) => {
      this.request('POST', _self.pricePath, data, resolve, reject);
    });

    promise.then((data) => {
      callBack(data);
    }).catch((data) => {
      if( callBackError ) {
        callBackError(data);
      }
    });
  }

  gotoCheckout(data: any, callBack: Function, callBackError?: Function){
    let _self = this;

    callBackError = callBackError ? callBackError : (e:any) => { console.error('promise was rejected', e); };

    let promise = new Promise((resolve, reject) => {
      this.request('POST', _self.orderPath, data, resolve, reject);
    });

    promise.then((data) => {
      callBack(data);
    }).catch((data) => {
      if( callBackError ) {
        callBackError(data);
      }
    });
  }

  request(type:requestType, url:string, data:any, resolve: Function, reject: Function ) {
    let xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.readyState > 3 && xhr.status === 200 || xhr.status === 202) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject();
      }
    };

    xhr.open(type, url);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(JSON.stringify(data));
  }
}