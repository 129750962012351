<template>
  <div class="card measurment-card">
    <div
      class="card-header collapsed"
      ref="collapseHeaderEl"
      @click.prevent="toggleCard"
    >
      {{ $t("measurements.label") }}
    </div>
    <div ref="collapseEl" id="measurementsContent" class="collapse">
      <div class="card-body">
        <div class="c-slider">
          <label for="width" class="form-label">{{
            $t("measurements.width")
          }}</label>
          <Slider
            :min="getMinWidth"
            :max="getMaxWidth"
            :sliderStep="10"
            :inputStep="100"
            v-model="measurements.width"
            :readOnly="settingsStore.readOnly"
          />
        </div>
        <div class="c-slider">
          <label for="height" class="form-label">
            {{ $t("measurements.height") }}</label
          >
          <Slider
            :min="getMinHeight"
            :max="getMaxHeight"
            :sliderStep="10"
            :inputStep="100"
            v-model="measurements.height"
            :readOnly="settingsStore.readOnly"
          />
        </div>
        <div class="c-slider">
          <label for="depth" class="form-label">
            {{ $t("measurements.depth") }}
          </label>
          <Slider
            :min="getMinDepth"
            :max="getMaxDepth"
            :sliderStep="10"
            :inputStep="100"
            :showNetto="true"
            v-model="measurements.depth"
            :readOnly="settingsStore.readOnly"
          />
        </div>
      </div>
      <div
        class="card-bottom d-flex justify-content-between align-items-center"
      >
        <button class="btn btn-link" type="#" @click="onGotoClicked('type')">
          {{ $t("general.previous") }} {{ $t("general.step") }}
        </button>
        <button
          class="btn btn-outline"
          type="#"
          @click="onGotoClicked('layout')"
        >
          {{ $t("general.next") }}: {{ $t("layout.label") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import BootstrapCardWrapper from "../helpers/BootstrapCardWrapper";

import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";

import Slider from "./Slider.vue";

const collapseEl = ref(null);
const collapseHeaderEl = ref(null);
const configurationStore = useConfigurationStore();
const settingsStore = useSettingsStore();

const { type, measurements } = storeToRefs(configurationStore);
const {
  getMinWidth,
  getMaxWidth,
  getMinHeight,
  getMaxHeight,
  getMinDepth,
  getMaxDepth,
  collapsibles,
} = storeToRefs(settingsStore);

onMounted(() => {
  collapsibles.measurements = new BootstrapCardWrapper(
    collapseHeaderEl.value,
    collapseEl.value
  );
});

const open = () => {
  if (collapsibles.measurements) {
    collapsibles.measurements.bootstrapObj.show();
  }
};

const close = () => {
  if (collapsibles.measurements) {
    collapsibles.measurements.bootstrapObj.hide();
  }
};

const toggleCard = () => {
  if (collapsibles.measurements) {
    if (collapsibles.measurements.bootstrapObj._isShown()) {
      close();
    } else {
      open();
    }
  }
};

defineExpose({
  open,
  close,
  toggleCard,
});

const emit = defineEmits(["gotoStep"]);

const onGotoClicked = (dir) => {
  emit("gotoStep", dir);
};

watch(configurationStore.measurements, function (newValue, oldValue) {
  if (
    configurationStore.type === "hanging" &&
    configurationStore.measurements.height > 1200
  ) {
    //Adjust width to max 1000
    if (configurationStore.measurements.width > 1000) {
      configurationStore.measurements.width = 1000;
    }
  }

  configurationStore.onMeasurementsAdjusted();
});

watch(
  () => [{ ...configurationStore.measurements }],
  (newValue, oldValue) => {
    if (
      configurationStore.type === "hanging" &&
      configurationStore.measurements.height > 1200
    ) {
      //Adjust width to max 1000
      if (configurationStore.measurements.width > 1000) {
        configurationStore.measurements.width = 1000;
      }
    }

    configurationStore.onMeasurementsAdjusted();

    if (newValue[0].height !== oldValue[0].height) {
      //Height has changed, check if the old value was over the treshold & the other was under, then & only then should we trigger this!
      if (
        newValue[0].height <
          settingsStore.constraints.dimensions.corpus.tabletTopBelow &&
        oldValue[0].height >=
          settingsStore.constraints.dimensions.corpus.tabletTopBelow
      ) {
        configurationStore.onCorpusHeightAdjusted();
      }
    }
  }
);
</script>
