import { BoxGeometry, Color, Group, Mesh } from "three";
import Corpus from "./corpus";
import { CorpusOptions, Preview3D, State } from "./types";

export default class Closet {
  main: Preview3D;
  group: Group;
  corpora: Array<Corpus>;
  closetDimensions: {
    width: number,
    height: number,
    depth: number
  }
  toeKickDimensions: {
    height: number,
    indent: number
  }

  constructor(main:Preview3D, state:State, doorsOpen: boolean) {
    this.main = main;
    
    //Setup group for the closet to live in!
    this.group = new Group();
    this.group.name = 'closet';
    this.main.scene.add(this.group);
    this.corpora = [];

    this.closetDimensions = {
      width: state.measurements.width/this.main.scale,
      height: state.measurements.height/this.main.scale,
      depth: state.measurements.depth/this.main.scale,
    }

    this.toeKickDimensions = {
      height: state.toeKick.height/this.main.scale,
      indent: state.toeKick.indent/this.main.scale
    }

    this.update(state, doorsOpen);
  }

  update( state:State, doorsOpen:boolean ) {
    this.closetDimensions = {
      width: state.measurements.width/this.main.scale,
      height: state.measurements.height/this.main.scale,
      depth: state.measurements.depth/this.main.scale,
    }

    this.toeKickDimensions = {
      height: state.toeKick.height/this.main.scale,
      indent: state.toeKick.indent/this.main.scale
    }

    //Instead of really updating, we're just redrawing everything > easier to maintain!!!
    //Remove all from main group!
    for (let i = this.group.children.length - 1; i >= 0; i--) {
      this.corpora = [];
      this.group.remove( this.group.children[i] );
    }

    let positionY = 0;

    if( state.type != 'hanging'  ) {
      positionY = this.toeKickDimensions.height;

      state.toeKick.parts.forEach((part) => {
        if( !part ) {
          return;
        }

        let baseTexture = this.main.textureLibrary.getMaterial( part.extFinish );

        let CalculatedToeKickDimensions = {
          width: part.length / this.main.scale,
          height: this.toeKickDimensions.height,
          depth: this.closetDimensions.depth - this.toeKickDimensions.indent
        }

        let toeKickGeometry = new BoxGeometry(CalculatedToeKickDimensions.width, CalculatedToeKickDimensions.height, CalculatedToeKickDimensions.depth);
        toeKickGeometry.translate(CalculatedToeKickDimensions.width/2, CalculatedToeKickDimensions.height/2, CalculatedToeKickDimensions.depth/2);
        let toeKickMesh = new Mesh( toeKickGeometry, baseTexture );

        if( part.position > 0 ) {
          toeKickMesh.translateX(part.position/this.main.scale);
        }

        this.group.add( toeKickMesh );
      });

      positionY = .04;
    } else { 
      positionY = .5;
    }

    let positionX = 0;

    if( state.corpora[0].tablets.left ) {
      positionX = .04;
    }

    state.corpora.forEach((corpus:any) => {
      let options = {...corpus, 
        width: corpus.width/this.main.scale, 
        height: (corpus.height/this.main.scale) - this.toeKickDimensions.height, 
        depth: this.closetDimensions.depth, 
        baseMaterialThickness: this.main.baseMaterialThickness,
        positionX: positionX,
        positionY: positionY
      };

      this.addCorpus(options, doorsOpen);

      positionX += (corpus.width/this.main.scale);
    })

    this.group.position.x = -(state.measurements.width / 2)/this.main.scale;
    this.main.render();
  }

  addCorpus(corpus:CorpusOptions, doorsOpen: boolean) {
    let corpusObj = new Corpus( corpus, doorsOpen, this.main.textureLibrary );
    this.corpora.push( corpusObj );
    this.group.add( corpusObj.group );
  }

  toggleDoors(open: boolean) {
    for( let x=this.corpora.length-1; x >= 0; x-- ) {
      this.corpora[x].toggleDoors(open);
    }
  }
}