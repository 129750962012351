<template>
  <span class="c-rangeSlider__inputContainer">
    <input
      class="c-rangeSlider__input"
      type="number"
      :min="min * unitScale"
      :max="max * unitScale"
      :step="inputStep * unitScale"
      :value="(valueToShow * unitScale).toFixed(2)"
      @change="scaledInputChanged($event.target)"
      :disabled="props.readOnly"
    />
    <span>{{ unit }}</span>
    <div v-if="showNetto" class="flex justify-content-end netto">
      <small>binnenmaat: {{ nettoMeasurement.toFixed(2) }}{{ unit }}</small>
    </div>
  </span>

  <div class="c-rangeSlider">
    <input
      type="range"
      class="form-range"
      :min="min"
      :max="max"
      :step="sliderStep"
      :value="valueToShow"
      @input="inputChanged($event.target)"
      :disabled="props.readOnly"
    />
  </div>
  <span v-if="!valid" class="text-danger">{{
    $t("rangeInput.error", {
      min: min * unitScale + unit,
      max: max * unitScale + unit,
    })
  }}</span>
</template>

<script setup>
import { computed, ref, toRefs } from "vue";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "../stores/settings";
import { useConfigurationStore } from "../stores/configuration";
const settingsStore = useSettingsStore();
const configurationStore = useConfigurationStore();

const props = defineProps({
  min: Number,
  max: Number,
  sliderStep: Number,
  inputStep: Number,
  modelValue: Number,
  inputValue: Number,
  showNetto: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const valueToShow = computed(() => {
  return modelValue.value ? modelValue.value : inputValue.value;
});

const { modelValue, min, max, step, inputValue, showNetto } = toRefs(props);
const valid = ref(true);
const emit = defineEmits(["update:modelValue", "input"]);
const { unit, unitScale, threed } = storeToRefs(settingsStore);

const nettoMeasurement = computed(() => {
  let returnValue = 0;

  // let subtractValue = 2 * threed.value.baseMaterialThickness + 2;
  // if (configurationStore.type === "hanging") {
  let subtractValue = 3 * threed.value.baseMaterialThickness + 8;
  // }

  if (modelValue.value) {
    returnValue = (modelValue.value - subtractValue) * unitScale.value;
  } else {
    returnValue = (inputValue.value - subtractValue) * unitScale.value;
  }

  return returnValue;
});

const valueChanged = function (value) {
  let validFlag = true;
  if (isNaN(value)) {
    validFlag = false;
  } else {
    validFlag = value <= max.value && value >= min.value;
  }

  valid.value = validFlag;

  if (validFlag) {
    if (modelValue.value) {
      emit("update:modelValue", value);
    } else {
      emit("input", value);
    }
  }
};

const inputChanged = function (el) {
  let value = parseFloat(el.value);
  valueChanged(value);
};

const scaledInputChanged = function (el) {
  let value = parseFloat(el.value);
  value = value / unitScale.value;

  valueChanged(value);
};
</script>

<style scoped lang="scss"></style>
