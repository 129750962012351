import { Group, BoxGeometry, BufferGeometry, ExtrudeGeometry, Mesh, MeshStandardMaterial, MeshPhongMaterial, Matrix4, InstancedMesh, Object3D, Shape, Vector4, Vector3, Vector2, EventDispatcher } from "three";
import gsap from "gsap";
import { ModuleSettings, ModuleType, ModuleInterface, partObjs } from './types';
import Module from './module';
import textureLibrary from "./textureLibrary";
import { TrapezoidGeometry } from "./trapezoidGeometry";

interface Corpus {
  id: string
  type: number
  group: Group
  status: '' | 'toRemove'
  baseMaterialThickness: number
  baseTexture: MeshStandardMaterial
  tabletMaterial: MeshStandardMaterial
  plate: InstancedMesh
  wall: InstancedMesh
  backPanel:Mesh
  hasBackPanel: boolean
  leftWallMatrix: Matrix4
  rightWallMatrix: Matrix4
  topPlateMatrix: Matrix4
  bottomPlateMatrix: Matrix4
  width: number
  height: number
  depth: number
  positionX: number
  positionY: number
  modules: Array<ModuleInterface>
  tablets: { [key:string] : boolean }
  materials: textureLibrary;
}

type tabletPositions = 'top' | 'left' | 'right' | 'bottom';

class Corpus extends EventDispatcher implements Corpus {
  id: string;
  type: number;
  group: Group;
  status: '' | 'toRemove'
  baseMaterialThickness: number;
  baseTexture: MeshStandardMaterial;
  tabletTexture: MeshStandardMaterial;
  plate: InstancedMesh;
  wall: InstancedMesh;
  backPanel: Mesh;
  hasBackPanel: boolean;
  leftWallMatrix: Matrix4;
  rightWallMatrix: Matrix4;
  topPlateMatrix: Matrix4;
  bottomPlateMatrix: Matrix4;
  width: number;
  height: number;
  depth: number;
  positionX:number;
  positionY:number;
  modules:ModuleInterface[];
  staticTabletsOrder: Array<string> = ['top' , 'right' , 'bottom' , 'left'];
  materials: textureLibrary;
  clothesRail: boolean;

  constructor(options:any, doorsOpen:boolean, materialLibrary:textureLibrary ) {
    super();

    // console.log( options )
    this.group = new Group();
    this.id = options.corpusId;
    this.group.name = this.id;
    this.status = '';
    this.baseMaterialThickness = options.baseMaterialThickness;

    //Base texture
    this.materials = materialLibrary;
    this.baseTexture = options.extFinish;
    this.tabletTexture = options.tabletsFinish;
    this.width = options.width;
    this.height = options.height;
    this.depth = options.depth;
    this.positionX = options.positionX;
    this.positionY = options.positionY;
    this.hasBackPanel = options.backPanel;
    this.modules = [];
    this.tablets = options.tablets;
    this.type = options.typeId;
    this.clothesRail = options.clothesRail;

    //Grab materials
    this.baseTexture = this.materials.getMaterial(options.extFinish);
    this.tabletTexture = this.materials.getMaterial(options.tabletsFinish);

    this.draw();
  
    if( Object.keys(options.modules).length > 0 ) {
      this.setModules( options.modules, doorsOpen );
    }

    return this;
  }

  draw() {
    if( this.backPanel ) { this.group.remove(this.backPanel) }
    if( this.plate ) { this.group.remove(this.plate) }
    if( this.wall ) { this.group.remove(this.wall) }

    if( this.type > 1 ) {
      let depth = this.depth;

      if( this.type > 2 ) {
        depth -= this.baseMaterialThickness;
      }

      let plateDimensions = {
        width: this.width  - (2 * this.baseMaterialThickness), //Bottom/top plates should fit between the walls
        height: this.baseMaterialThickness,
        depth: depth
      };

      let wallDimensions = {
        width: this.baseMaterialThickness,
        height: this.height,
        depth: depth
      }

      let backPanelDimensions = {
        width: this.width - ( 2 * this.baseMaterialThickness ),
        height: this.height - ( 2 * this.baseMaterialThickness ),
        depth: this.baseMaterialThickness
      }

      //Create the base board
      //Instead of making it the given dimensnions immediatly, we want to use scaling for ease of use later on ;) scale 1.8 > 1.8m in "real life", other wise we need to recaculate the scale every time based on original dimesions
      let horGeometry = new BoxGeometry(plateDimensions.width, plateDimensions.height, plateDimensions.depth); 
      //Adjust the origin to left/bottom/front of the box
      horGeometry.translate(plateDimensions.width/2, plateDimensions.height/2, plateDimensions.depth/2);
      //Create the 2 meshes from it!
      this.plate = new InstancedMesh( horGeometry, this.baseTexture, 2 );

      // //Create the vertical plates
      let vertGeometry = new BoxGeometry( wallDimensions.width, wallDimensions.height, wallDimensions.depth );
      // //Adjust the origin to left/bottom/front of the box
      vertGeometry.translate(wallDimensions.width/2, wallDimensions.height/2, wallDimensions.depth/2);
      // //Create the 2 meshes from it!
      this.wall = new InstancedMesh( vertGeometry, this.baseTexture, 2 );

      let backPanelGeometry = new BoxGeometry( backPanelDimensions.width, backPanelDimensions.height, backPanelDimensions.depth);
      backPanelGeometry.translate(backPanelDimensions.width/2, backPanelDimensions.height/2, backPanelDimensions.depth/2);
      this.backPanel = new Mesh( backPanelGeometry, this.baseTexture );
      this.backPanel.translateX(this.baseMaterialThickness);
      this.backPanel.translateY(this.baseMaterialThickness);
      this.backPanel.visible = this.hasBackPanel;

      //Instanced meshes won't show up unless you transform them thus matrices!
      this.leftWallMatrix = new Matrix4();
      this.rightWallMatrix = new Matrix4();
      this.topPlateMatrix = new Matrix4();
      this.bottomPlateMatrix = new Matrix4();

      //We will only really adjust the secondary mesh > this bakes the offset compared to it's parent/1st instance 
      this.rightWallMatrix.makeTranslation(this.width - this.baseMaterialThickness, 0, 0);
      this.topPlateMatrix.makeTranslation(0, this.height - this.baseMaterialThickness, 0);

      //Apply matrices
      this.plate.setMatrixAt(0, this.bottomPlateMatrix);
      this.wall.setMatrixAt(0, this.leftWallMatrix);
      this.plate.setMatrixAt(1, this.topPlateMatrix );
      this.wall.setMatrixAt(1, this.rightWallMatrix);

      //Move the plates to the left, to fit between walls
      this.plate.translateX(this.baseMaterialThickness);

      //Add them to the group
      this.group.add(this.plate);
      this.group.add(this.wall);
      this.group.add(this.backPanel);
    }

    let tabletThickness = .04;

    //Check if we need to add tablets 
    for (const [key, value] of Object.entries(this.tablets)) {
      if( value ) {
        let tablet = this.createTablet(key as tabletPositions);
        this.group.add ( tablet );
      }
    }

    //Move to the correct position 
    //TODO need to rethink this?! perhaps we should be doing this inside the closet class instead of the corpus?
    this.group.position.x = this.positionX;
    this.group.position.y = this.positionY;
  }

  createTablet( position: tabletPositions ) {
    let tabletThickness = .04;
    let getPositionIndex = this.staticTabletsOrder.indexOf(position);
    let prevTablet = false;
    let nextTablet = false;
    let isVertical = ( getPositionIndex % 2 !== 0);

    if( getPositionIndex === 0 ) {
      prevTablet = this.tablets[ this.staticTabletsOrder[ this.staticTabletsOrder.length - 1 ] ];
      nextTablet = this.tablets[ this.staticTabletsOrder[ getPositionIndex + 1 ] ];
    } else if( getPositionIndex === this.staticTabletsOrder.length - 1 ) {
      prevTablet = this.tablets[ this.staticTabletsOrder[ getPositionIndex - 1 ] ];
      nextTablet = this.tablets[ this.staticTabletsOrder[ 0 ] ];
    } else {
      prevTablet = this.tablets[ this.staticTabletsOrder[ getPositionIndex - 1 ] ];
      nextTablet = this.tablets[ this.staticTabletsOrder[ getPositionIndex + 1 ] ];
    }

    // Implementing my newly made trapezoid shape!
    let baseWidth = ( isVertical ) ? this.height : this.width;
    let topWidth = (isVertical) ? this.height : this.width;
    let topOffsetToBase = 0;

    //We need upside down trapezoids so topwidth will always increase when a prev or next tablet is detected
    if( prevTablet ) {
      topWidth += tabletThickness;

      if( !nextTablet ) {
        //The offset is calculated on the centers of the base/top width so we need to divide the offset by 2 as this is calculated on the centers
        topOffsetToBase -= tabletThickness/2;
      }
    }

    if( nextTablet ) {
      topWidth += tabletThickness;

      if( !prevTablet ) {
        //The offset is calculated on the centers of the base/top width so we need to divide the offset by 2 as this is calculated on the centers
        topOffsetToBase += tabletThickness/2;
      }
    }

    let tabletGeometry = new TrapezoidGeometry(baseWidth, topWidth, topOffsetToBase, tabletThickness, this.depth);
    tabletGeometry.translate( baseWidth/2, tabletThickness/2, this.depth/2 );
    
    //Vertical should be rotated!
    if( isVertical ) {
      tabletGeometry.rotateZ(Math.PI/2);
    }

    let tabletTexture = this.tabletTexture.clone();
    let tabletMesh = new Mesh( tabletGeometry, tabletTexture );

    if( position === 'right' ) {
      //Translate & flip
      tabletMesh.translateX(this.width);
      tabletMesh.translateY(this.height);
      tabletMesh.scale.x = -1;
      tabletMesh.scale.y = -1;
    } else if( position === 'bottom' ) {
      //Flip
      tabletMesh.translateX(this.width);
      tabletMesh.scale.y = -1;
      tabletMesh.scale.x = -1;
    } else if( position === 'top' ) {
      //translate
      tabletMesh.translateY(this.height);
    }

    return tabletMesh;
  }

  setWidth( width:number ) {
    if( width !== this.width ) {
      this.width = width;
      this.draw();
    }
  }

  setPosition( x:number ) {
    if( x !== this.group.position.x ) {
      this.positionX = x;
      this.draw();
    }
  }

  setHeight( height:number ) {
    if( height !== this.height ) {
      this.height = height;
      this.draw();
    }
  }

  setDepth( depth:number ) {
    if( depth !== this.depth ) {
      this.depth = depth;
      this.draw();
    }
  }

  getModuleOfType( type: ModuleType ) {
    return this.modules.find( (module) => ( type === module.type ) );
  }

  setModules( modules:any, doorsOpen: boolean ) {
    let activeModuleTypes = Object.keys(modules);

    for( let key in modules ) {
      let settings:ModuleSettings = {
        objs: [],
        width: 0,
        height: 0,
        depth: 0,
        baseMaterialThickness: 0,
        baseTexture: this.baseTexture,
        clothesRail: this.clothesRail
      };

      if( typeof modules[key] === 'undefined') {
        continue;
      }
      

      if( typeof modules[key] === 'number' ) {
        for( let i=0; i < modules[key]; i++ ) {
          let tmpObject:partObjs = {customSpaceSet: false};
          settings.objs.push(tmpObject);
        }
      } else {
        settings = {...modules[key]}
      }

      settings = { ...settings,
        width: this.width,
        height: this.height,
        depth: this.depth,
        baseMaterialThickness: this.baseMaterialThickness,
        baseTexture: this.baseTexture
      }

      if( key === 'doors' ) {
        settings['doorsOpen'] = doorsOpen;
      }

      let tmpModule = new Module( key as ModuleType, settings ); 
        
      //push to the array
      this.modules.push( tmpModule );
      this.group.add( tmpModule.group );
    }
  }

  toggleDoors(open:boolean) {
    let doorsModuleIndex = this.modules.findIndex((module) => module.type === 'doors');

    if( doorsModuleIndex >= 0 ) {
      //Lets do the animation here!
      let doorLeft = this.modules[doorsModuleIndex].group.getObjectByName('door-left');
      let doorRight = this.modules[doorsModuleIndex].group.getObjectByName('door-right');

      let rotationY = (-80*Math.PI / 180);//110deg in radians
      let translateX = this.baseMaterialThickness;

      if( !open ) {
        rotationY = 0;
        translateX = 0;
      }

      if( doorLeft ) {
        gsap.to(doorLeft.rotation, { duration: 2, y: rotationY });
        gsap.to(doorLeft.position, { duration: 2, x: translateX });
      }

      if( doorRight ) { //Opposite of doorleft
        gsap.to(doorRight.rotation, { duration: 2, y: (rotationY * -1) });
        gsap.to(doorRight.position, { duration: 2, x: (translateX * -1) });
      }
    }
  }
}

export default Corpus;