import { Collapse } from "bootstrap";

interface BootstrapCardWrapperInterface {
  collapseHeaderEl:HTMLElement;
  collapseEl:HTMLElement;
  headEl:HTMLElement; 
  htmlEl:HTMLElement;
  sidebarEl:HTMLElement;
  bootstrapObj: Collapse;
}

export default class BootstrapCardWrapper implements BootstrapCardWrapperInterface  {
  collapseHeaderEl:HTMLElement;
  collapseEl:HTMLElement;
  headEl:HTMLElement; 
  htmlEl:HTMLElement;
  sidebarEl:HTMLElement;
  public bootstrapObj: Collapse;

  constructor( collapseHeaderEl:HTMLElement, collapseEl:HTMLElement ) {
    this.collapseEl = collapseEl;
    this.collapseHeaderEl = collapseHeaderEl;
    this.headEl = (document.querySelector("header") !== null) ? document.querySelector("header")! : document.createElement('<header></header>')!;
    this.htmlEl = document.documentElement;
    this.sidebarEl = collapseEl.closest("aside")!;

    this.bootstrapObj = new Collapse(collapseEl, {
      parent: this.sidebarEl,
      toggle: false,
    });

    this.addListeners();
  }

  addListeners() {
    this.collapseEl.addEventListener("hide.bs.collapse", (event) => {
      this.collapseHeaderEl.classList.add("collapsed");
    });
  
    this.collapseEl.addEventListener("show.bs.collapse", (event) => {
      this.collapseHeaderEl.classList.remove("collapsed");

      //We need to calculate the amount to scrollTo, we need to keep in to account that the other card (that is still open, will collapse, so we need to find it & adjust our calculations to it)
      let thisCard:HTMLElement = this.collapseHeaderEl.closest<HTMLElement>(".card")!;
      let openCard:HTMLElement = this.sidebarEl.querySelector(".collapse.show")?.closest('.card')!;
      let closedCard:HTMLElement = this.sidebarEl.querySelector(".collapse:not(.show)")?.closest('.card')!;

      if( openCard && closedCard) {
        let openCardHeightToSubtract:number = 0;
        let childNodesArray:HTMLElement[] = [].slice.call(this.sidebarEl.childNodes);
        if( childNodesArray.indexOf( openCard ) < childNodesArray.indexOf( thisCard ) ) {
          openCardHeightToSubtract = openCard.offsetHeight - closedCard.offsetHeight; //We need to do total card-height - closed card height to find the difference!
        }

        let scrollToValue = 
          this.collapseHeaderEl.closest<HTMLElement>(".card")!.offsetTop -
          this.headEl.offsetHeight -
          openCardHeightToSubtract -
          10; //-10px for spacing

        this.htmlEl.scrollTo({
          top: scrollToValue,
          behavior: "smooth",
        });
      }
    });
  
  }
}