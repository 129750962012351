import { Preview3D } from "./types";
import { Mesh, PlaneGeometry, Sphere } from "three";
import Material from "./material";

export default class Lightroom {
  floor: PlaneGeometry;

  constructor( preview3D: Preview3D ) {
    //Setup floor
    this.floor = new PlaneGeometry(150, 150, 10, 10);

    const material = new Material(0x808080).standard;
    const mesh = new Mesh(this.floor, material);

    // Use ES6 spread to set position and rotation from passed in array
    mesh.position.set(0, 0, 0);
    mesh.rotation.set(Math.PI / 2, 0, 0);
    mesh.receiveShadow = true;

    preview3D.scene.add(mesh);
  }
}