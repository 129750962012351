<template>
  <Corpus
    v-for="(item, index) in corpora"
    :key="item.corpusId"
    :number="index"
    :id="item.corpusId"
    @gotoStep="gotoStep"
    @gotoCorpus="gotoCorpus"
    :ref="(el) => corporaRefs.push(el)"
  >
  </Corpus>
</template>

<script setup>
import { onBeforeUpdate, onUpdated, ref } from "vue";
import { useConfigurationStore } from "../stores/configuration";
import { storeToRefs } from "pinia";
import Corpus from "./Corpus";

const configuration = useConfigurationStore();
const { corpora } = storeToRefs(configuration);
const emit = defineEmits(["gotoStep"]);

const corporaRefs = ref([]);

onBeforeUpdate(() => {
  corporaRefs.value = [];
});

defineExpose({
  corporaRefs,
});

const gotoStep = (panel, dir) => {
  emit("gotoStep", panel, dir);
};

const gotoCorpus = (dir, origin) => {
  corporaRefs.value[origin - 1 + dir].open();
};
</script>

<style scoped></style>
