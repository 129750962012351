<template>
  <div class="card layout-card">
    <div
      class="card-header collapsed"
      ref="collapseHeaderEl"
      @click.prevent="toggleCard"
    >
      {{ $t("layout.label") }}
    </div>
    <div ref="collapseEl" id="layoutContent" class="collapse">
      <div class="card-body">
        <div class="mb-3">
          <label for="corpora" class="form-label">
            {{ $t("layout.corpora") }}
          </label>
          <div class="c-number">
            <button
              :disabled="
                getNrOfCorpora <= getMinNrOfCorpora || settings.readOnly
              "
              @click.prevent="adjustNrOfCorpora(-1)"
            >
              -
            </button>
            <input
              @input.prevent="setNrOfCorpora($event.target.value)"
              :value="getNrOfCorpora"
              id="corpora"
              type="number"
              :min="getMinNrOfCorpora"
              :max="getMaxNrOfCorpora"
              :disabled="settings.readOnly"
            />
            <button
              :disabled="
                getNrOfCorpora >= getMaxNrOfCorpora || settings.readOnly
              "
              @click.prevent="adjustNrOfCorpora(1)"
            >
              +
            </button>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label form-label--slider">
            {{ $t("layout.equalCorpusWidth") }}
            <input
              type="checkbox"
              v-model="equalCorpusWidth"
              @change="toggleEqualWidth($event)"
              :disabled="settings.readOnly"
            />
            <span class="slider"></span>
          </label>
        </div>

        <div v-show="!equalCorpusWidth" class="mb-3 p-3 bg-light">
          {{ $t("layout.widthPerCorpus") }} <br />
          <small>{{ $t("layout.selectACorpus") }}</small>

          <div class="d-flex p-3">
            <div
              class="corpusWidthBox text-center d-flex align-items-center"
              :class="{ selected: selectedCorpus == item }"
              @click.prevent="setSelectedCorpus(item.corpusId)"
              v-for="(item, index) in corpora"
              :key="item.corpusId"
              :id="item.corpusId"
            >
              <span
                >{{ (parseFloat(item.width) * unitScale).toFixed(0) }}
                {{ unit }}</span
              >
            </div>
          </div>

          <div v-if="selectedCorpus">
            <small>{{ $t("layout.adjustCorpusWidth") }}</small>
            <Slider
              :inputValue="selectedCorpus.width"
              :min="getCorpusMinWidth"
              :max="getCorpusMaxWidth"
              :sliderStep="10"
              :inputStep="10"
              @input="setCorpusWidth($event)"
              :readOnly="settings.readOnly"
            />
          </div>
        </div>
      </div>
      <div class="card-bottom d-flex justify-content-between w-100">
        <button
          class="btn btn-link"
          type="#"
          @click="onGotoClicked('measurements')"
        >
          {{ $t("general.previous") }} {{ $t("general.step") }}
        </button>
        <button
          class="btn btn-outline"
          type="#"
          @click="onGotoClicked('corpora')"
        >
          {{ $t("general.next") }}: {{ $t("corpora.label") }} 1
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, getCurrentInstance, onMounted } from "vue";
import { storeToRefs } from "pinia";
import BootstrapCardWrapper from "../helpers/BootstrapCardWrapper";
import Slider from "./Slider.vue";

const collapseEl = ref(null);
const collapseHeaderEl = ref(null);

import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";

const instance = getCurrentInstance();
const configuration = useConfigurationStore();
const settings = useSettingsStore();
const emit = defineEmits(["gotoStep"]);

const onGotoClicked = (dir) => {
  emit("gotoStep", dir);
};

const {
  equalCorpusWidth,
  corpora,
  getNrOfCorpora,
  getCorpusMinWidth,
  getCorpusMaxWidth,
} = storeToRefs(configuration);
const { getMinNrOfCorpora, getMaxNrOfCorpora, collapsibles, unit, unitScale } =
  storeToRefs(settings);
const selectedCorpusId = ref(false);
const selectedCorpus = ref(false);

onMounted(() => {
  collapsibles.layout = new BootstrapCardWrapper(
    collapseHeaderEl.value,
    collapseEl.value
  );
});

const open = () => {
  if (collapsibles.layout) {
    collapsibles.layout.bootstrapObj.show();
  }
};

const close = () => {
  if (collapsibles.layout) {
    collapsibles.layout.bootstrapObj.hide();
  }
};

const toggleCard = () => {
  if (collapsibles.layout) {
    if (collapsibles.layout.bootstrapObj._isShown()) {
      close();
    } else {
      open();
    }
  }
};

defineExpose({
  open,
  close,
  toggleCard,
});

const toggleEqualWidth = function ($event) {
  if (equalCorpusWidth.value) {
    //Reset the customWidthSet flag
    corpora.value.forEach((corpus) => {
      corpus.customWidthSet = false;
    });
  }

  configuration.onMeasurementsAdjusted();
};

const adjustNrOfCorpora = function (increment) {
  if (
    getNrOfCorpora.value + increment >= getMinNrOfCorpora.value &&
    getNrOfCorpora.value + increment <= getMaxNrOfCorpora.value
  ) {
    if (increment > 0) {
      configuration.addCorpus();
    } else {
      configuration.removeCorpus();
    }

    configuration.onMeasurementsAdjusted();
  }
};

const setNrOfCorpora = function (value) {
  if (value.length > 0) {
    //if empty just ignore unless onblur
    const newValue = parseInt(value);

    if (
      newValue >= getMinNrOfCorpora.value &&
      newValue <= getMaxNrOfCorpora.value
    ) {
      let iterations = newValue - getNrOfCorpora.value;

      for (let i = 0; i < Math.abs(iterations); i++) {
        if (iterations > 0) {
          configuration.addCorpus();
        } else {
          configuration.removeCorpus();
        }
      }
    } else {
      instance?.proxy?.$forceUpdate();
    }

    configuration.onMeasurementsAdjusted();
  }
};

const setSelectedCorpus = function (corpusId) {
  selectedCorpusId.value = corpusId;
  selectedCorpus.value = corpora.value.find(
    (corpus) => corpus.corpusId == corpusId
  );
};

const setCorpusWidth = function (value) {
  const _self = this;
  let newValue = parseInt(value);

  if (newValue > getCorpusMaxWidth.value) {
    newValue = getCorpusMaxWidth.value;
  } else if (newValue < getCorpusMinWidth.value) {
    newValue = getCorpusMinWidth.value;
  }

  //Get active corpus
  selectedCorpus.value.width = newValue;
  selectedCorpus.value.customWidthSet = true;

  // configuration.adjustCorpusWidths(selectedCorpus.value.corpusId);
  configuration.onMeasurementsAdjusted(selectedCorpus.value.corpusId);
};
</script>
<style scoped lang="scss"></style>
