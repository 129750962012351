<template>
  <div class="divideSpace my-4">
    <label class="form-label"
      >{{ $t("corpora.nrOf") }} {{ $t(props.label) }}:</label
    >
    <div class="c-number">
      <button
        :disabled="nrValue <= min || props.readOnly"
        @click.prevent="adjustNr(-1)"
      >
        -
      </button>
      <input
        :value="nrValue"
        type="number"
        :min="min"
        :max="max"
        @change="setNr($event.target.value)"
        :disabled="props.readOnly"
      />
      <button
        :disabled="nrValue >= max || props.readOnly"
        @click.prevent="adjustNr(1)"
      >
        +
      </button>
    </div>
  </div>
  <div v-if="nrValue > 1" class="equalSpace my-4">
    <label class="form-label form-label--slider">
      {{ $t("corpora.equalWidths") }}:
      <input
        type="checkbox"
        :checked="equal"
        @input="setEqual($event.target.checked)"
        :disabled="props.readOnly"
      />
      <span class="slider"></span>
    </label>
  </div>

  <div v-if="!equal" class="hasDrawers my-4 d-flex flex-column">
    <label class="form-label"
      >{{ $t("corpora.spacePer") }} {{ $t(props.labelSingle) }}</label
    >
    <small>{{ $t("corpora.selectA") }} {{ $t(props.labelSingle) }}</small>

    <div
      class="d-flex mt-3"
      :class="{
        'flex-column': props.direction === 'vertical',
        'flex-row': props.direction !== 'vertical',
      }"
    >
      <div
        class="itemBox text-center d-flex align-items-center justify-content-center"
        :class="{ selected: selectedItem == item }"
        @click.prevent="setSelectedItem(index)"
        v-for="(item, index) in module.objs"
        :key="item.index"
      >
        <span>
          {{
            parseFloat(
              props.direction === "vertical"
                ? item.height * unitScale
                : item.width * unitScale
            ).toFixed(0)
          }}
          {{ unit }}
        </span>
      </div>
    </div>

    <div v-if="selectedItem" class="differentHeight mt-4">
      <div v-if="props.direction === 'vertical'">
        <label class="form-label">{{ $t("corpora.adjustHeight") }}</label>
        <Slider
          :min="minSpace"
          :max="maxSpace"
          :inputValue="selectedItem.height"
          :sliderStep="10"
          :inputStep="10"
          @input="setItemSpace($event)"
          :readOnly="props.readOnly"
        />
      </div>
      <div v-else>
        <label class="form-label">{{ $t("corpora.adjustWidth") }}</label>
        <Slider
          :min="minSpace"
          :max="maxSpace"
          :sliderStep="10"
          :inputStep="10"
          :inputValue="selectedItem.width"
          @input="setItemSpace($event)"
          :readOnly="props.readOnly"
        />
      </div>
    </div>
  </div>

  <div
    v-if="props.moduleType === 'doors' && nrValue === 1"
    class="doorDirection d-flex align-items-center my-4"
  >
    <label class="form-label mb-0">{{
      $t("corpora.doorRotationDirection")
    }}</label>
    <div class="checkbox-bar">
      <label class="checkbox-bar__item">
        <input
          @change.prevent="adjustDoorDirection('left')"
          type="radio"
          value="left"
          id="left"
          name="direction"
          :checked="module.objs[0].direction === 'left'"
          :disabled="props.readOnly"
        />
        <span> {{ $t("divideSpace.left") }}</span></label
      >
      <label class="checkbox-bar__item">
        <input
          @change.prevents="adjustDoorDirection('right')"
          type="radio"
          value="right"
          id="right"
          name="direction"
          :checked="module.objs[0].direction === 'right'"
          :disabled="props.readOnly"
        />
        <span> {{ $t("divideSpace.right") }} </span>
      </label>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import Slider from "./Slider.vue";
import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";

const configuration = useConfigurationStore();
const settings = useSettingsStore();

const { unit, unitScale } = storeToRefs(settings);

const props = defineProps({
  direction: String,
  corpusId: String,
  moduleType: String,
  maxSpace: Number,
  label: String,
  labelSingle: String,
  onChange: Function,
  readOnly: {
    type: Boolean,
    default: false,
  },
});
const { corpora, getItemMinSpace, getItemMaxSpace } =
  storeToRefs(configuration);

const corpus = corpora.value.find(
  (corpus) => props.corpusId == corpus.corpusId
);
const module = ref(corpus.modules[props.moduleType]);

const equal = ref(module.value.equal);
const nrValue = computed(() => module.value.objs.length);
const selectedItemIndex = ref(0);
const selectedItem = ref(null);

const min = computed(() => {
  switch (props.moduleType) {
    case "drawers":
      return settings.getMinNrOfDrawers(props.corpusId);
      break;

    case "shelves":
      return settings.getMinNrOfShelves(props.corpusId);
      break;

    case "doors":
      return settings.getMinNrOfDoors(props.corpusId);
      break;
  }
});
const max = computed(() => {
  switch (props.moduleType) {
    case "drawers":
      return settings.getMaxNrOfDrawers(props.corpusId);
      break;

    case "shelves":
      return settings.getMaxNrOfShelves(props.corpusId);
      break;

    case "doors":
      return settings.getMaxNrOfDoors(props.corpusId);
      break;
  }
});

const minSpace = ref(
  configuration.getItemMinSpace(
    props.corpusId,
    props.moduleType,
    props.direction
  )
);
const maxSpace = ref(
  configuration.getItemMaxSpace(
    props.corpusId,
    props.moduleType,
    props.direction
  )
);

const adjustNr = function (increment) {
  setNr(nrValue.value + increment);
};

const setNr = function (value) {
  let newValue = value;

  if (newValue < min.value) {
    newValue = min.value;
  }

  if (newValue > max.value) {
    newValue = max.value;
  }

  let addFunc = null;
  let removeFunc = null;

  switch (props.moduleType) {
    case "drawers":
      addFunc = configuration.addDrawer;
      removeFunc = configuration.removeDrawer;
      break;

    case "shelves":
      addFunc = configuration.addShelve;
      removeFunc = configuration.removeShelve;
      break;

    case "doors":
      addFunc = configuration.addDoor;
      removeFunc = configuration.removeDoor;
      break;
  }

  let iterations = newValue - nrValue.value;
  for (let i = 0; i < Math.abs(iterations); i++) {
    if (iterations > 0) {
      //If iterations is negative > we need to remove otherwise we need to add!
      addFunc(props.corpusId);
    } else {
      removeFunc(props.corpusId);
    }
  }

  if (typeof props.onChange == "function") {
    props.onChange();
  }
};

const setEqual = function (value) {
  equal.value = value;
  corpus.modules[props.moduleType].equal = value;

  if (typeof props.onChange == "function") {
    props.onChange();
  }
};

const setSelectedItem = function (index) {
  selectedItemIndex.value = index;
  selectedItem.value = module.value.objs[index];

  minSpace.value = configuration.getItemMinSpace(
    props.corpusId,
    props.moduleType,
    props.direction
  );
  maxSpace.value = configuration.getItemMaxSpace(
    props.corpusId,
    props.moduleType,
    props.direction
  );
};

const setItemSpace = function (value) {
  if (props.direction === "vertical") {
    selectedItem.value.height = parseFloat(value);
  } else {
    selectedItem.value.width = parseFloat(value);
  }

  selectedItem.value.customSpaceSet = true;
  configuration.adjustModuleSpaces(props.corpusId, selectedItemIndex.value);

  if (typeof props.onChange == "function") {
    props.onChange();
  }
};

const adjustDoorDirection = function (value) {
  console.log("in herE?!");

  module.value.objs[0].direction = value;
};
</script>

<style scoped lang="scss"></style>
