type imgObj = {
  directory: string,
  imageDimensions: [number, number],
  mimeType: string,
  name: string,
  originalName: string,
  sequence: number
}

type fitType = 'crop' | 'contain' | 'fill' | 'max';

const getImgUrl = (object:imgObj, width:number, height:number, fit:fitType = 'contain') => {
  //Url to make: /api/image/finish/100/100/contain/jpg/veneer-0-fineline-metalic-bruin-6391abd093efc.jpg
  //url => /api/image/{directory}/{width}/{height}/{fit}/{format}/{image}
  let baseUrl = "/api/image/";

  baseUrl += object.directory + '/';
  baseUrl += width + '/';
  baseUrl += height + '/';
  baseUrl += fit + '/';
  baseUrl += 'jpg/';
  baseUrl += object.name;

  return baseUrl;
};

export { getImgUrl };