import { DoubleSide, MeshPhysicalMaterial, MeshStandardMaterial } from 'three';

// Use this class as a helper to set up some default materials
export default class Material {
  basic: MeshPhysicalMaterial
  standard: MeshPhysicalMaterial
  wire: MeshPhysicalMaterial

  constructor(color:number) {
    this.basic = new MeshPhysicalMaterial({
      color,
      side: DoubleSide
    });

    this.standard = new MeshPhysicalMaterial({
      color,
      flatShading: true,
      roughness: 1,
      metalness: 0,
      side: DoubleSide
    });

    this.wire = new MeshPhysicalMaterial({wireframe: true});
  }
}

