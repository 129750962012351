<template>
  <div class="c-summary card">
    <div class="card-body">
      <div class="d-md-flex justify-content-between">
        <div class="price">
          <div
            class="d-flex d-md-block justify-content-between align-items-center mb-2 mb-md-0"
          >
            <p class="pe-2 pe-md-0 m-0">
              {{ $t("general.totalPrice") }} {{ $t("general.vatIncluded") }} ({{ $t("general.excludingShippingCosts") }})
            </p>
            <p class="c-price d-flex flex-wrap align-items-center mb-0 mb-md-2">
              <span class="me-2">&euro; {{ totalPrice.toFixed(2) }}</span>
              <small v-if="discount" class="c-price__discount badge bg-info ms-"
                >-{{ discountPercentage }}% {{ $t("general.discount") }}</small
              >
              <a
                href="#"
                class="c-price-breakdown-toggle"
                v-if="userLoggedIn"
                @click.prevent="onToggleBreakdown"
                >i</a
              >
            </p>
          </div>
        </div>
        <div>
          <button
            v-if="!settings.readOnly"
            @click.prevent="onOrder"
            class="btn btn-primary"
          >
            <svg class="icon icon-sm me-2">
              <use xlink:href="#icon-cart"></use>
            </svg>
            <span class="d-md-inline-block">{{ $t("general.order") }}</span>
          </button>
        </div>
      </div>
      <div v-if="showBreakdown && userLoggedIn">
        <h4>Price breakdown:</h4>
        <dl>
          <div
            class="c-price-breakdown d-flex"
            v-for="(price, key) in breakDown"
            :key="key"
          >
            <dt>{{ key }}</dt>
            <dd>{{ price }}</dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="c-summary__usp">
      <p class="py-3">
        Extra lades in je kast, aanpassingen voor je schuin plafond of specifieke wensen?
        Mail gerust naar <a href="mailto:hello@cabinetto.be">hello@cabinetto.be</a> en we helpen je graag verder!
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { usePricingStore } from "../stores/pricing";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "../stores/settings";

const showBreakdown = ref(false);
const pricing = usePricingStore();
const settings = useSettingsStore();
const { userLoggedIn, totalPrice, breakDown, discount, discountPercentage } =
  storeToRefs(pricing);

const emit = defineEmits(["order"]);

const onOrder = () => {
  emit("order");
};

const onToggleBreakdown = () => {
  showBreakdown.value = !showBreakdown.value;
};
</script>

<style scoped lang="scss">
@import "../styles/components/_components.summary.scss";
</style>
