import { Color, EventDispatcher, MeshPhysicalMaterial, Vector2 } from "three";
import { Finish } from "./types";
import TextureMaps from "./textureMaps";

type textureFilesType = {
  type: 'ral' | 'wood'
  diffuseMap?: string,
  normalMap?: string,
  specularMap?: string
}

const textureLibraryJson:Record<string, textureFilesType> = {
  ral: {
    type: 'ral'
  },
  birch: {
    type: 'wood',
    diffuseMap: '/3d-assets/textures/birch/diffuse.jpg',
    normalMap: '/3d-assets/textures/birch/normal.jpg',
    specularMap: '/3d-assets/textures/birch/specular.jpg'
  },
  finelineMetalicBrown: {
    type: 'wood',
    diffuseMap: '/3d-assets/textures/fineline-metalic-brown/diffuse.jpg',
    normalMap: '/3d-assets/textures/fineline-metalic-brown/normal.jpg',
    specularMap: '/3d-assets/textures/fineline-metalic-brown/specular.jpg'
  },
  licolnWalnut: {
    type: 'wood',
    diffuseMap: '/3d-assets/textures/licoln-wallnut/diffuse.jpg',
    normalMap: '/3d-assets/textures/licoln-wallnut/normal.jpg',
    specularMap: '/3d-assets/textures/licoln-wallnut/specular.jpg'
  },
  atlasOak: {
    type: 'wood',
    diffuseMap: '/3d-assets/textures/vicenza-oak/diffuse.jpg',
    normalMap: '/3d-assets/textures/vicenza-oak/normal.jpg',
    specularMap: '/3d-assets/textures/vicenza-oak/specular.jpg'
  }
}

type texturesLoaded = {
  ral: boolean,
  birch: boolean,
  finelineMetalicBrown: boolean,
  licolnWalnut: boolean, 
  atlasOak: boolean 
}

interface textureLibraryInterface {
  texturesLoaded: texturesLoaded
  textures: Array<MeshPhysicalMaterial>
  finishes: Array<Finish>
  defaultTexture: string
  onLoaded: Function
}

export default class textureLibrary implements textureLibraryInterface {
  texturesLoaded:texturesLoaded = { 
    ral: false,
    birch: false,
    finelineMetalicBrown: false,
    licolnWalnut: false, 
    atlasOak: false 
  };
  textures:Array<MeshPhysicalMaterial> = [];
  finishes:Array<Finish> = [];
  materialsMapping:Array<{name:string, intKey:string}> = [
    { name: 'Nevis oak', intKey: 'finelineMetalicBrown' },
    { name: 'Lorenzo walnut', intKey: 'licolnWalnut' },
    { name: 'Atlas oak', intKey: 'atlasOak' },
    { name: 'White birch', intKey: 'birch' }
  ];
  defaultTexture = 'ral';
  onLoaded: Function;

  constructor(finishes:Array<Finish>, onLoaded: Function) {
    let _self = this;
    this.onLoaded = onLoaded;
    this.finishes = finishes;

    //Loop over the texture library json & add to the library
    for( let key in textureLibraryJson ) {
      this.texturesLoaded[key as keyof texturesLoaded] = false;

      if( key === 'ral' ) {
        let material = new MeshPhysicalMaterial( {
          color: 0xffffff,
        });

        this.textures.push(material);
        this.texturesLoaded[key as keyof texturesLoaded] = true;

        this.allTexturesLoaded();
      } else {
        let text = new TextureMaps( textureLibraryJson[key], (texture:any) => {
          let material:MeshPhysicalMaterial|null = null;

          material = new MeshPhysicalMaterial( {
            color: 0xffffff,
            map: texture.diffuseMap,
            normalMap: texture.normalMap,
            normalScale: new Vector2( .5, .5 ),
            // wireframe: true 
            name: key
          });

          this.textures.push(material);
          this.texturesLoaded[key as keyof texturesLoaded] = true;

          this.allTexturesLoaded();
        })
      }
    }
  }

  getMaterial(id:number): MeshPhysicalMaterial {
    //look in the globally defined finishes variable for the right info
    id = ( id === null || id === undefined ) ? 11 : id;

    //Find the finish
    let finish = this.finishes.find((finish) => (finish.id == id) );

    if( finish ) {
      let foundMaterial:MeshPhysicalMaterial[] = [];

      let texture:MeshPhysicalMaterial = this.textures[0].clone();

      if( finish?.type === 'ral' ) {
        //Adjust color to match!
        texture.color = new Color(finish.color);
      } else {
        //Find the mapped name first
        let mappedName = this.materialsMapping.filter((map) => map.name === finish?.name);

        //return [name]
        foundMaterial = this.textures.filter((material) => material.name == mappedName[0].intKey );
        
        if( foundMaterial.length > 0 ) {
          texture = foundMaterial[0].clone();
        }
      }

      return texture;
    } else {
      let foundMaterial:MeshPhysicalMaterial[] = [];

      //return [name]
      foundMaterial = this.textures.filter((material) => material.name == this.defaultTexture );

      return foundMaterial[0].clone();
    }
  }

  allTexturesLoaded() {
    if (Object.values(this.texturesLoaded).indexOf(false) <= -1) {
      this.onLoaded();
    }
  }
}