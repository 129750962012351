<template>
  <div class="card corpus-card">
    <div
      class="card-header collapsed"
      ref="collapseHeaderEl"
      @click.prevent="toggleCard"
      data-bs-toggle="collapse"
      :data-bs-target="`#corpusContent-${number}`"
      data-bs-parent="#sidebarControls"
      aria-expanded="false"
      :aria-controls="`corpusContent-${number}`"
    >
      {{ $t("corpora.label") }} {{ number }}
    </div>
    <div ref="collapseEl" class="collapse" :id="`corpusContent-${number}`">
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label">{{ $t("corpora.module_label") }}</label>
          <div class="d-flex flex-wrap">
            <label
              v-for="(type, index) in corpusTypes"
              :key="type.id"
              :for="'corpus-' + number + '-checkbox-' + type.id"
              class="img-checkbox-btn"
              :class="{
                'img-checkbox-btn--disabled': settings.readOnly,
                'img-checkbox-btn--checked': type.id == corpus.typeId,
              }"
            >
              <input
                type="radio"
                :id="'corpus-' + number + '-checkbox-' + type.id"
                :name="'corpus-' + number + '-type'"
                :value="type.id"
                v-model="corpus.typeId"
                @input.prevent="adjustCorpusType(type.id)"
                :disabled="settings.readOnly"
              />
              <img src="/build/img/svg/hangend-meubel-icon.svg" alt="" />
              {{ type.name }}
            </label>
          </div>
        </div>

        <div v-if="hasModule && corpora.length > 1">
          <label class="form-label form-label--slider">
            {{ $t("corpora.deviatingHeight") }}
            <input
              type="checkbox"
              v-model="corpus.hasDeviatingHeight"
              name="has-deviating-height"
              :disabled="settings.readOnly"
            />
            <span class="slider"></span>
          </label>

          <div v-if="corpus.hasDeviatingHeight">
            <label for="width" class="form-label">{{
              $t("measurements.height")
            }}</label>
            <Slider
              :min="getCorpusMinHeight(corpus.corpusId)"
              :max="maxCorpusHeight"
              :sliderStep="10"
              :inputStep="10"
              v-model="corpus.height"
              :readOnly="settings.readOnly"
            />
          </div>
        </div>

        <div v-if="hasShelfs" class="corpus-option hasShelves">
          <label class="form-label">
            {{ $t("corpora.nrOf") }} {{ $t("corpora.shelves") }}
          </label>
          <div class="c-number">
            <button
              :disabled="nrOfShelves <= minShelfs || settings.readOnly"
              @click.prevent="adjustNrOfShelves(-1)"
            >
              -
            </button>
            <input
              :value="nrOfShelves"
              type="number"
              :min="minShelfs"
              :max="getShelvesMax(corpus.corpusId)"
              @change="setNrOfShelves($event.target.value)"
              :disabled="settings.readOnly"
            />
            <button
              :disabled="
                nrOfShelves >= getShelvesMax(corpus.corpusId) ||
                settings.readOnly
              "
              @click.prevent="adjustNrOfShelves(1)"
            >
              +
            </button>
          </div>
        </div>

        <div v-if="hasShelfs && clothesRailAvailable" class="corpus-option">
          <label class="form-label form-label--slider">
            {{ $t("corpora.clothesRail") }}
            <input
              type="checkbox"
              name="addClothesRail"
              v-model="corpus.clothesRail"
              :disabled="settings.readOnly"
            />
            <span class="slider"></span>
          </label>
        </div>

        <div v-if="hasDrawers" class="corpus-option hasDrawers">
          <DivideSpace
            :direction="'vertical'"
            :moduleType="'drawers'"
            :corpusId="corpus.corpusId"
            :maxSpace="parseFloat(corpus.height)"
            :label="'corpora.drawers'"
            :labelSingle="'corpora.drawer'"
            :readOnly="settings.readOnly"
          >
          </DivideSpace>
        </div>
        <div v-if="hasDoors" class="corpus-option hasDoors">
          <DivideSpace
            :direction="'horizontal'"
            :moduleType="'doors'"
            :corpusId="corpus.corpusId"
            :maxSpace="parseFloat(corpus.width)"
            :onChange="onDoorsUpdated"
            :label="'corpora.doors'"
            :labelSingle="'corpora.door'"
            :readOnly="settings.readOnly"
          >
          </DivideSpace>
        </div>
        <div class="c-card-extra bg-light p-3">
          <div class="title mb-3">
            <label>{{ $t("corpora.tablet_label") }}</label
            ><br />
            <small>{{ $t("corpora.tablet_helpText") }}</small>
          </div>
          <div class="corpusTablets">
            <div
              class="tablet tablet--top"
              :class="{ active: corpus.tablets.top }"
              @click.prevent="settings.readOnly ? false : toggleTablet('top')"
              :disabled="settings.readOnly"
            ></div>
            <div
              class="tablet tablet--left"
              :class="{ active: corpus.tablets.left, disabled: !isFirstCorpus }"
              @click.prevent="settings.readOnly ? false : toggleTablet('left')"
              :disabled="settings.readOnly"
            ></div>
            <div
              class="tablet tablet--right"
              :class="{ active: corpus.tablets.right, disabled: !isLastCorpus }"
              @click.prevent="settings.readOnly ? false : toggleTablet('right')"
              :disabled="settings.readOnly"
            ></div>
            <div
              class="tablet tablet--bottom"
              :class="{
                active: corpus.tablets.bottom,
                disabled: type !== 'hanging',
              }"
              @click.prevent="
                settings.readOnly ? false : toggleTablet('bottom')
              "
              :disabled="type !== 'hanging' || settings.readOnly"
            ></div>
          </div>
        </div>
      </div>
      <div class="card-bottom d-flex justify-content-between w-100">
        <button
          v-if="number <= 1"
          class="btn btn-link"
          type="#"
          @click="gotoStep('layout')"
        >
          {{ $t("general.previous") }} {{ $t("general.step") }}
        </button>
        <button
          v-else
          class="btn btn-link"
          type="#"
          @click="gotoCorpus(-1, number)"
        >
          {{ $t("general.previous") }} {{ $t("general.step") }}
        </button>

        <button
          v-if="number >= corpora.length"
          class="btn btn-outline"
          type="#"
          @click="gotoStep('materials')"
        >
          {{ $t("general.next") }}: {{ $t("tablets.label") }}
        </button>
        <button
          v-else
          class="btn btn-outline"
          type="#"
          @click="gotoCorpus(1, number)"
        >
          {{ $t("general.next") }}: {{ $t("corpora.label") }} {{ number + 1 }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import DivideSpace from "./DivideSpace.vue";
import Slider from "./Slider.vue";
import BootstrapCardWrapper from "../helpers/BootstrapCardWrapper";
import { defineProps, ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";

import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";

const configuration = useConfigurationStore();
const settings = useSettingsStore();
const collapseEl = ref(null);
const collapseHeaderEl = ref(null);

const emit = defineEmits(["gotoStep", "gotoCorpus"]);

const gotoStep = (panel) => {
  emit("gotoStep", panel);
};

const gotoCorpus = (dir, origin) => {
  emit("gotoCorpus", dir, origin);
};

const props = defineProps({
  number: Number,
  id: String,
});

const number = ref(props.number + 1);
const { type, measurements, corpora, getShelvesMax, getCorpusMinHeight } =
  storeToRefs(configuration);
const { constraints, collapsibles } = storeToRefs(settings);
const corpus = ref(corpora.value.find((item) => item.corpusId === props.id));
const corpusHeight = computed(() => corpus.value.height);
const isFirstCorpus = computed(() => props.number == 0);
const isLastCorpus = computed(() => props.number == corpora.value.length - 1);
const nrOfShelves = computed(() => corpus.value.modules.shelves);
const hasClothesBar = computed(() => corpus.value.clothesRail);
const clothesRailAvailable = computed(() => {
  let flag = true;

  if (
    corpus.value.height - configuration.toeKick.height >=
    settings.constraints.dimensions.modules.clothesRail.minHeight
  ) {
    flag = true;
  } else {
    flag = false;
  }

  if (
    flag == true &&
    configuration.measurements.depth >=
      settings.constraints.dimensions.modules.clothesRail.minDepth
  ) {
    flag = true;
  } else {
    flag = false;
  }

  return flag;
});

const maxCorpusHeight = computed(() => {
  if (corpus.value.tablets.top) {
    return measurements.value.height - 40;
  } else {
    return measurements.value.height;
  }
});

watch(clothesRailAvailable, function (newValue) {
  if (!newValue) {
    corpus.value.clothesRail = false;
  }
});

//computed prop to check for modules/posibilities - makes it easier to plugin into this later for other logic ;)
const hasModule = computed(() => corpus.value.typeId !== 1);
const minShelfs = computed(() => {
  return hasClothesBar.value ? 1 : 0;
});
const hasShelfs = computed(
  () => corpus.value.typeId == 2 || corpus.value.typeId == 4
);
const hasDoors = computed(() => corpus.value.typeId == 4);
const hasDrawers = computed(() => corpus.value.typeId == 3);

const corpusTypes = ref(preDefinedCorpora);

const adjustCorpusType = function (typeId) {
  configuration.adjustCorpusType(corpus.value.corpusId, typeId);
};

const toggleTablet = function (tabletPosition) {
  corpus.value.tablets[tabletPosition] = !corpus.value.tablets[tabletPosition];

  configuration.onMeasurementsAdjusted();
};

const setNrOfShelves = function (value) {
  configuration.setShelves(corpus.value.corpusId, value);
};

const adjustNrOfShelves = function (increment) {
  setNrOfShelves(corpus.value.modules.shelves + increment);
};

const onDoorsUpdated = function () {
  configuration.onMeasurementsAdjusted();
};

onMounted(() => {
  collapsibles["corpus-" + number.value] = new BootstrapCardWrapper(
    collapseHeaderEl.value,
    collapseEl.value
  );
});

const open = () => {
  if (collapsibles["corpus-" + number.value]) {
    collapsibles["corpus-" + number.value].bootstrapObj.show();
  }
};

const close = () => {
  if (collapsibles["corpus-" + number.value]) {
    collapsibles["corpus-" + number.value].bootstrapObj.hide();
  }
};

const toggleCard = () => {
  if (collapsibles["corpus-" + number.value]) {
    if (collapsibles["corpus-" + number.value].bootstrapObj._isShown()) {
      close();
    } else {
      open();
    }
  }
};

defineExpose({
  open,
  close,
  toggleCard,
});

//Watch for change in clothingsRail
watch(configuration.corpora, function (newValue) {
  newValue.forEach((corpus) => {
    //Trigger validation of setShelves
    configuration.setShelves(corpus.corpusId, corpus.modules.shelves);

    if (corpus.clothesRail) {
      //check if we need to add this accessory to the corpus array or not
      configuration.addAccessory(
        corpus.corpusId,
        settings.defaults.clothesRailAccessoryId,
        1,
        "bar"
      );
    } else {
      //Check if we need to remove this accessory
      configuration.removeAccessory(
        corpus.corpusId,
        settings.defaults.clothesRailAccessoryId
      );
    }
  });
});

watch(corpusHeight, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    //Height has changed, check if the old value was over the treshold & the other was under, then & only then should we trigger this!
    if (
      newValue < settings.constraints.dimensions.corpus.tabletTopBelow &&
      oldValue >= settings.constraints.dimensions.corpus.tabletTopBelow
    ) {
      configuration.onCorpusHeightAdjusted(corpus.value.corpusId);
    }

    configuration.adjustModuleSpaces(corpus.value.corpusId);
  }
});
</script>
