<template>
  <canvas class="preview3D" ref="canvas"></canvas>
  <img class="imgReplacement" src="#" ref="imgreplacement" />

  <div class="btn-wrapper">
    <button
      :disabled="!hasDoors"
      class="btn btn-icon-round d-block"
      @click.prevent="toggleDoors"
      :title="$t('preview.toggleDoors')"
    >
      <svg class="icon icon-md">
        <use xlink:href="#icon-closet"></use>
      </svg>
    </button>
    <button
      class="btn btn-icon-round d-block mt-3"
      @click.prevent="downloadScreenshot"
      :title="$t('preview.downloadScreenshot')"
    >
      <svg class="icon icon-md">
        <use xlink:href="#icon-camera"></use>
      </svg>
    </button>
    <button
      class="btn btn-icon-round d-block mt-3"
      @click.prevent="refreshPreview"
      :title="$t('preview.refresh')"
    >
      <svg class="icon icon-md">
        <use xlink:href="#icon-refresh"></use>
      </svg>
    </button>
  </div>
</template>
<script setup>
import preview3D from "../preview3D/main";
import { ref, computed, onMounted } from "vue";
import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";
import { storeToRefs } from "pinia";

const canvas = ref(null);
const imgreplacement = ref(null);
const configuration = useConfigurationStore();
const settings = useSettingsStore();
const { corpora } = storeToRefs(configuration);
let preview = null;

const hasDoors = computed(() => {
  let hasDoors = false;

  corpora.value.forEach((corpus) => {
    if (corpus.typeId == 4) {
      hasDoors = true;
    }
  });

  return hasDoors;
});

const toggleDoors = () => {
  preview.toggleDoors();
};

const takeScreenShot = (callback) => {
  preview.takeScreenShot((data) => {
    callback(data);
  });
};

const downloadScreenshot = () => {
  preview.takeScreenShot(
    (data) => {
      let a = document.createElement("a");
      let url = data;

      let secondCanvas = document.createElement("canvas");
      secondCanvas.width = 1920;
      secondCanvas.height = 1080;
      let ctx = secondCanvas.getContext("2d");
      console.log(ctx);

      const loadImage = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.addEventListener("load", () => resolve(img));
          img.addEventListener("error", (err) => reject(err));
          img.src = url;
        });

      let image = new Image();
      image.onload = () => {
        ctx.drawImage(image, 0, 0);

        let logo = new Image();
        logo.onload = () => {
          ctx.drawImage(logo, 1920 - 250, 1080 - 55);

          url = secondCanvas.toDataURL("image/jpeg", 1.0);

          //Grab date/time string
          const now = new Date();
          const offsetMs = now.getTimezoneOffset() * 60 * 1000;
          const dateLocal = new Date(now.getTime() - offsetMs);

          const dateTimeString = dateLocal
            .toISOString()
            .slice(0, 19)
            .replace(/:/g, ".")
            .replace("T", "-");

          a.href = url;
          a.download = "cabinetto-configuratie-" + dateTimeString + ".jpg";
          a.click();
        };
        logo.src = "/build/img/cabinetto-logo.svg";
      };
      image.src = data;
    },
    { fixedCameraPoint: false }
  );
};

const refreshPreview = () => {
  preview.update(configuration.$state);
  preview.resetCameraToOriginalPosition();
};

defineExpose({
  takeScreenShot,
});

onMounted(() => {
  preview = new preview3D(
    canvas.value,
    imgreplacement.value,
    settings.threed,
    settings.finishes,
    configuration,
    settings.development
  );

  configuration.$subscribe((mutation, state) => {
    preview.update(state);
  });
});
</script>
<style scoped lang="scss"></style>
