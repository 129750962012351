import { defineStore } from "pinia";

export const usePricingStore = defineStore("pricing", {
  //state
  state: () => ({
    breakDown: {},
    userLoggedIn: false,
    discount: false,
    discountPercentage: 0,
    totalPrice: 0,
  }),
  getters: {},
  actions: {},
});
