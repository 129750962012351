<template>
  <div ref="card" class="card configType-card">
    <div class="card-header" ref="collapseHeaderEl" @click.prevent="toggleCard">
      {{ $t("furnitureType.label") }}
    </div>
    <div ref="collapseEl" id="furnitureTypeContent" class="collapse show">
      <div class="card-body d-flex flex-wrap">
        <label
          for="standing"
          class="img-checkbox-btn size"
          :class="{
            'img-checkbox-btn--disabled': settingsStore.readOnly,
            'img-checkbox-btn--checked': type == 'standing',
          }"
        >
          <input
            v-model="type"
            type="radio"
            value="standing"
            id="standing"
            name="module_type"
            :disabled="settingsStore.readOnly"
          />
          <img src="/build/img/svg/staand-meubel-icon.svg" alt="" />
          {{ $t("furnitureType.standing") }}
        </label>
        <label
          for="hanging"
          class="img-checkbox-btn size"
          :class="{
            'img-checkbox-btn--disabled': settingsStore.readOnly,
            'img-checkbox-btn--checked': type == 'hanging',
          }"
        >
          <input
            v-model="type"
            type="radio"
            value="hanging"
            id="hanging"
            name="module_type"
            :disabled="settingsStore.readOnly"
          />
          <img src="/build/img/svg/hangend-meubel-icon.svg" alt="" />
          {{ $t("furnitureType.hanging") }}
        </label>
      </div>

      <div class="card-bottom d-flex justify-content-end">
        <button class="btn btn-outline" type="#" @click="onGotoNextClicked">
          {{ $t("general.next") }}: {{ $t("measurements.label") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, toRefs, watch } from "vue";
import BootstrapCardWrapper from "../helpers/BootstrapCardWrapper";
import { storeToRefs } from "pinia";
import { useConfigurationStore } from "../stores/configuration";
import { useSettingsStore } from "../stores/settings";

const emit = defineEmits(["gotoStep"]);

const furnitureType = ref();
const collapseEl = ref(null);
const collapseHeaderEl = ref(null);
const configurationStore = useConfigurationStore();
const settingsStore = useSettingsStore();
const { collapsibles } = storeToRefs(settingsStore);
const { type } = storeToRefs(configurationStore);

onMounted(() => {
  collapsibles.type = new BootstrapCardWrapper(
    collapseHeaderEl.value,
    collapseEl.value
  );
});

const onGotoNextClicked = () => {
  emit("gotoStep", "measurements");
};

const open = () => {
  if (collapsibles.type) {
    collapsibles.type.bootstrapObj.show();
  }
};

const close = () => {
  if (collapsibles.type) {
    collapsibles.type.bootstrapObj.hide();
  }
};

const toggleCard = () => {
  if (collapsibles.type) {
    if (collapsibles.type.bootstrapObj._isShown()) {
      close();
    } else {
      open();
    }
  }
};

defineExpose({
  open,
  close,
  toggleCard,
});

watch(type, function (newValue) {
  configurationStore.onTypeAdjusted();
});
</script>
